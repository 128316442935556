import { MatrixType } from '../../serverapi/api';
import { apiBundle } from '../api/api-bundle';

export class MatrixDaoService {
    public static async createMatrixType(serverId: string, requestBody: { body: MatrixType }) {
        await apiBundle(serverId).matrixType.create({ body: requestBody.body });
    }

    public static async saveMatrixType(serverId: string, requestBody: { body: MatrixType }) {
        await apiBundle(serverId).matrixType.save({ body: requestBody.body });
    }

    public static async deleteMatrixType(serverId: string, presetId: string, id: string) {
        await apiBundle(serverId).matrixType._delete({ presetId, id });
    }
}
