import type { TEraseDeletedNodeDialogSubmitAction } from '@/actions/eraseDeletedNodeDialog.actions.types';
import type { TTreeItemEraseRequest } from '@/actions/tree.actions.types';
import type { TreeNode } from '@/models/tree.types';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { ERASE_DELETED_NODE_DIALOG_SUBMIT } from '@/actionsTypes/eraseDeletedNodeDialog.actionTypes';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { treeItemRefresh } from '@/actions/tree.actions';
import { showNotificationByType } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { DeleteNodeRequest } from '@/services/bll/DeleteNodeBllService';
import { TREE_ITEM_ERASE_REQUEST } from '@/actionsTypes/tree.actionTypes';
import { getDeleteNodeRequestData } from './deleteNode.saga';
import { workspaceRemoveTabByNodeId } from '@/actions/tabs.actions';
import { recentRefresh } from '@/actions/recent.actions';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';
import ProcessIndicatorMessages from '@/modules/StatusBar/components/ProcessIndicator/ProcessIndicator.messages';
import { ProcessType, TExecutingProcess } from '@/reducers/statusBar.reducer.types';
import { v4 as uuid } from 'uuid';
import { setProcessIndicator } from '@/actions/statusBar.actions';
import { favoritesFetchRequest } from '@/actions/favorites.actions';
import { SearchResult } from '@/serverapi/api';
import { getSearchedElementsByRepositoryIdById } from '@/selectors/searchedDeletedElements.selectors';
import { SearchDeletedElementsBllService } from '@/services/bll/SearchDeletedElementsBLLService';
import { eraseSearchedDeletedElementsSuccess } from '@/actions/searchedDeletedElements.actions';

export function* eraseDeletedNodeRequest({ payload: { nodeId } }: TTreeItemEraseRequest) {
    const deleteRequestData: DeleteNodeRequest | undefined = yield getDeleteNodeRequestData(nodeId);

    if (!deleteRequestData) {
        return;
    }

    yield put(openDialog(DialogType.ERASE_DELETED_NODE_DIALOG, deleteRequestData));
}

function* handlEraseDeletedNodeDialogSubmit({
    payload: { nodeId, closeTabsIds },
}: TEraseDeletedNodeDialogSubmitAction) {
    const { serverId, repositoryId } = nodeId;
    const node: TreeNode = yield select(TreeSelectors.unfilteredItemById(nodeId));
    const refreshNodeType = node.type === TreeItemType.Repository ? TreeItemType.Server : TreeItemType.Repository;

    for (const closeTabId of closeTabsIds) {
        yield put(workspaceRemoveTabByNodeId(closeTabId));
    }

    const locale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(locale);
    const processName: string = `${intl.formatMessage(ProcessIndicatorMessages.processDelete)} "${node.name}"`;
    const process: TExecutingProcess = { id: uuid(), name: processName, type: ProcessType.DELETE };

    yield put(setProcessIndicator(true, process));

    try {
        yield put(closeDialog(DialogType.ERASE_DELETED_NODE_DIALOG));
        yield call(() => TreeDaoService.erase([nodeId]));
        yield put(treeItemRefresh({ serverId, repositoryId, id: repositoryId }, refreshNodeType));

        const searchResult: SearchResult[] = yield select(
            getSearchedElementsByRepositoryIdById(nodeId.repositoryId, nodeId.repositoryId),
        );
        if (searchResult.length > 0) {
            const idsToDeleteFromStore = SearchDeletedElementsBllService.getIdsToDeleteOnEraseNodes(
                [nodeId.id],
                searchResult,
            );

            yield put(
                eraseSearchedDeletedElementsSuccess({
                    repositoryId: nodeId.repositoryId,
                    id: nodeId.repositoryId,
                    idsToDelete: idsToDeleteFromStore,
                }),
            );
        }

        yield put(recentRefresh());
        yield put(favoritesFetchRequest(nodeId.serverId));
    } catch (e) {
        yield put(showNotificationByType(NotificationType.ERASE_NODE));
    } finally {
        yield put(setProcessIndicator(false, process));
    }
}

export function* eraseDeletedNodeSaga() {
    yield takeEvery(TREE_ITEM_ERASE_REQUEST, eraseDeletedNodeRequest);
    yield takeEvery(ERASE_DELETED_NODE_DIALOG_SUBMIT, handlEraseDeletedNodeDialogSubmit);
}
