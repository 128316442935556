export type TResizeData = {
    start: number;
    size: number;
    id: string;
};

export type THeaderResizeData = {
    x: number;
    width: number;
    y: number;
    height: number;
    direction: HeaderResizeDirections;
};

export enum EdgeSymbolType {
    custom = 'CUSTOM',
    icon = 'ICON',
    userIcon = 'USER_ICON',
}

export enum IconType {
    plus = 'PLUS',
    minus = 'MINUS',
    check = 'CHECK',
    cross = 'CROSS',
}

export enum HeaderType {
    row = 'row',
    column = 'column',
}

export enum ContextActions {
    levelUp = 'levelUp',
    levelDown = 'levelDown',
    symbolsSettings = 'symbolsSettings',
}

export enum HeaderResizeDirections {
    horizontal = 'horizontal',
    vertical = 'vertical',
    both = 'both',
}
