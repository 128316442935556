import { TWorkspaceTab } from '../../../models/tab.types';
import { TRootState } from '../../../reducers/root.reducer.types';
import { connect } from 'react-redux';
import { MatrixEditor } from './MatrixEditor.component';
import { TMatrixEditorProps } from './MatrixEditor.types';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { ServerSelectors } from '../../../selectors/entities/server.selectors';
import { getTreeItems } from '../../../selectors/tree.selectors';
import { CommentsSelectors } from '../../../selectors/comments.selectors';
import { MatrixSelectors } from '../../../selectors/entities/matrix.selectors';
import { TreeNode } from '../../../models/tree.types';
import { relock, unlock } from '../../../actions/lock.actions';
import { matrixChangeDataRequest } from '../../../actions/entities/matrix.actions';
import { MatrixData, MatrixNode, NodeId } from '../../../serverapi/api';
import { EditorMode } from '../../../models/editorMode';

type TMatrixEditorContainerOwnProps = {
    tab: TWorkspaceTab;
};

const mapStateToProps = (state: TRootState, ownProps: TMatrixEditorContainerOwnProps): Partial<TMatrixEditorProps> => {
    const content = ownProps.tab.content as MatrixNode;
    const matrixNodeId = content.nodeId;
    const activeScheme: TWorkspaceTab | undefined = TabsSelectors.byId(matrixNodeId)(state);
    const commentsEnabledSchemesIds = CommentsSelectors.commentsEnabledSchemesIds()(state);

    let treeItemsById: { [id: string]: TreeNode } = {};

    if (matrixNodeId && matrixNodeId.repositoryId) {
        treeItemsById = getTreeItems(matrixNodeId.serverId, matrixNodeId.repositoryId)(state);
    }

    const matrixNode: MatrixNode | undefined = MatrixSelectors.byId(matrixNodeId)(state);

    return {
        nodeId: matrixNodeId,
        serverUrl: ServerSelectors.server(matrixNodeId.serverId)(state).url,
        matrix: matrixNode,
        activeKey: TabsSelectors.getActiveTabId(state),
        mode: activeScheme?.mode || EditorMode.Read,
        commentsEnabledSchemesIds,
        treeItemsById,
        // checkFocus,
    };
};

const mapDispatchToProps: (dispatch, ownProps: TMatrixEditorContainerOwnProps) => Partial<TMatrixEditorProps> = (
    dispatch,
    ownProps,
) => {
    const content = ownProps.tab.content as MatrixNode;

    return {
        onSave: (nodeId: NodeId, data: MatrixData | undefined) => {
            if (data) dispatch(matrixChangeDataRequest(nodeId, data));
        },
        onLock: () => {
            dispatch(relock(content.nodeId, 'MATRIX'));
        },
        onUnLock: () => {
            dispatch(unlock(content.nodeId, 'MATRIX'));
        },
        // onFocus: () => {
        //     dispatch(
        //         navigatorPropertiesRenderPropAction({
        //             nodeId: ownProps.content.nodeId,
        //             entityType: EntityEnum.MATRIX,
        //         }),
        //     );
        // },
    };
};

export const MatrixEditorContainer = connect(mapStateToProps, mapDispatchToProps)(MatrixEditor);
