import { accessTypes } from '../actions/audit.actions.types';
import { TRootState } from '../reducers/root.reducer.types';
export namespace ActionAuditSelectors {
    export const filteredTypes = (state: TRootState): string[] => state.audit.filteredTypes;

    export const auditData = (state: TRootState) => state.audit.byServerId[state.admintool.currentServerId] || [];

    export const statusAudit = (state: TRootState): boolean => state.audit.statusAudit;

    export const statusDownLoad = (state: TRootState): boolean => state.audit.statusDownload;

    export const filteredAccesses = (state: TRootState): accessTypes[] => state.audit.filteredAccesses;
}
