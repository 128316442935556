import type { TreeNode } from '@/models/tree.types';
import type { MxCell, MxUndoableEdit } from './mxgraph';
import type { NodeId } from '@/serverapi/api';
import { getActiveGraph } from '@/selectors/editor.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { getStore } from '@/store';
import type { CommentMarker, EdgeInstanceImpl, ObjectInstanceImpl } from '@/models/bpm/bpm-model-impl';
import { clearContextMenuParentId } from '@/actions/contextMenu.actions';

const isChangeRevertable = (edit: MxUndoableEdit): boolean => {
    const state = getStore().getState();
    const activeGraphId = getActiveGraph(state);

    if (!activeGraphId) {
        return true;
    }

    const hasUndoUnavailableChanges = edit.changes.some((change) => {
        const cell: MxCell = change.child || change.cell;
        const value = cell?.getValue();

        if (!value) {
            return false;
        }
        if (value.objectDefinitionId && (value as ObjectInstanceImpl).type === 'object') {
            const objectDefinitionId: NodeId = { ...activeGraphId, id: value.objectDefinitionId };
            const objectDefinitionTreeNode: TreeNode | undefined = TreeSelectors.itemById(objectDefinitionId)(state);

            if (!objectDefinitionTreeNode || objectDefinitionTreeNode.deleted) {
                return true;
            }
        }
        if (value.edgeDefinitionId && (value as EdgeInstanceImpl).type === 'edge') {
            const edgeDefinitionId: NodeId = { ...activeGraphId, id: value.edgeDefinitionId };
            const edgeDefinitionNodeTreeNode: TreeNode | undefined = TreeSelectors.itemById(edgeDefinitionId)(state);

            if (!edgeDefinitionNodeTreeNode || edgeDefinitionNodeTreeNode.deleted) {
                return true;
            }
        }
        if ((value as CommentMarker).type === 'CommentMarker') {
            return true;
        }

        return false;
    });

    return !hasUndoUnavailableChanges;
};

const closeNavigatorContextMenu = () => {
    getStore().dispatch(clearContextMenuParentId());
};

const getActiveGraphNodeId = (): NodeId | undefined => {
    const state = getStore().getState();

    return getActiveGraph(state);
};

export default { isChangeRevertable, closeNavigatorContextMenu, getActiveGraphNodeId };
