import { Tooltip, Button } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import icEdit from '../../../resources/icons/ic-edit.svg';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import messages from './RowButtons.messages';

type TEditButton = {
    onClick: () => void;
};

const EditButton: FC<TEditButton> = (props) => {
    const { onClick } = props;
    const intl = useIntl();

    return (
        <Tooltip mouseLeaveDelay={0} title={intl.formatMessage(messages.edit)}>
            <Button data-test="row-button_EDIT" type="link" onClick={onClick} icon={<Icon spriteSymbol={icEdit} />} />
        </Tooltip>
    );
};

export default EditButton;
