import React, { FC, useEffect, useRef, useState } from 'react';
import theme from './NewMatrixEditor.scss';
import messages from './NewMatrixContextMenu/NewMatrixContextMenu.messages';
import { ContextActions, HeaderType } from './NewMatrix.types';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import { Icon } from '@/modules/UIKit';
import defaultIcon from '@/resources/icons/defaultIcon.svg';
import { InternationalString, MatrixCellBPM8764, MatrixDataBPM8764, MatrixLane, NodeId } from '@/serverapi/api';
import { matrixSelectHeaderCells } from '@/actions/entities/newMatrix.actions';
import { useDispatch } from 'react-redux';
import { TSelectedHeadersCells } from '@/reducers/entities/newMatrix.reducer.types';
import { showNotification } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { TTreeEntityState } from '@/models/tree.types';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { useContextMenuDublicateDelete } from '@/hooks/useContextMenuDublicateDelete';
import { NewMatrixContextMenu } from './NewMatrixContextMenu/NewMatrixContextMenu.component';
import { getMenuItem } from '@/utils/antdMenuItem.utils';
import { useIntl } from 'react-intl';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { MenuInfo } from 'rc-menu/es/interface';
import {
    getCurrentLvl,
    haveChildren,
    checkSameLvls,
    checkLanesSpaces,
    checkLanesCanUpLvl,
    reorderLanes,
    getParenForLvlDown,
    excludeChildIds,
    fixLanes,
    isLaneExist,
    getSelectedLanesWithChildren,
    getStyleIdsFromCellWithSameLinkedNodeIds,
    getCells,
} from '../NewMatrix.utils';
import { MIN_HEADER_SIZE } from '../NewMatrix.constants';

type TNewMatrixHeaderCellProps = {
    id: string;
    text: string;
    icon: string;
    index: number;
    isReadMode: boolean;
    isRowDrag: boolean;
    nodeId: NodeId;
    type: HeaderType;
    selectedHeaderCells: TSelectedHeadersCells;
    matrixData: MatrixDataBPM8764;
    rowsHeaders: MatrixLane[];
    colsHeaders: MatrixLane[];
    objectDefinitions: {
        [id: string]: TTreeEntityState;
    };
    matrixContainerRef: React.RefObject<HTMLDivElement>;
    linkedNodeId?: string;
};

type TNewMatrixHeaderCellActionProps = {
    updateMatrixData: (data: MatrixDataBPM8764) => void;
    openRenameDialog: (onSubmit: (newName: InternationalString) => void, initName?: InternationalString) => void;
    setIsRowDrag: (flag: boolean) => void;
};

export const NewMatrixHeaderCell: FC<TNewMatrixHeaderCellProps & TNewMatrixHeaderCellActionProps> = ({
    id,
    text,
    icon,
    index,
    isReadMode,
    isRowDrag,
    nodeId,
    type,
    selectedHeaderCells,
    matrixData,
    colsHeaders,
    rowsHeaders,
    objectDefinitions,
    linkedNodeId,
    matrixContainerRef,
    updateMatrixData,
    openRenameDialog,
    setIsRowDrag,
}) => {
    const [isDropTarget, setIsDropTarget] = useState<boolean>(false);
    const [isContextMenuVisible, setContextMenuVisible] = useContextMenuDublicateDelete('matrix', { ...nodeId, id });

    const [columnTextXOffset, setColumnTextXOffset] = useState<number>(0);

    const intl = useIntl();
    const dispatch = useDispatch();

    const columnHeaderHeight = matrixData?.columnHeaderHeight || MIN_HEADER_SIZE;
    const rowHeaderWidth = matrixData?.rowHeaderWidth || MIN_HEADER_SIZE;

    const isColumn = type === HeaderType.column;
    const isRow = type === HeaderType.row;

    const currentMatrixLanes = (isColumn ? colsHeaders : rowsHeaders) || [];

    const cellElement = useRef<HTMLTableCellElement | null>(null);
    const headerCellClickTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            const { height, width } = entries[0].contentRect;
            setColumnTextXOffset((width - height) / 2);
        });
        if (cellElement.current) {
            observer.observe(cellElement.current);
        }

        return () => {
            cellElement.current && observer.unobserve(cellElement.current);
        };
    }, [cellElement.current]);

    const updateMatrixHandeler = (data: MatrixDataBPM8764) => {
        fixLanes(data.rows);
        fixLanes(data.columns);

        const newRowsOrder: MatrixLane[] = [];
        reorderLanes(data.rows[0], data.rows, newRowsOrder);
        data.rows = newRowsOrder;

        const newColsOrder: MatrixLane[] = [];
        reorderLanes(data.columns[0], data.columns, newColsOrder);
        data.columns = newColsOrder;

        updateMatrixData(data);
    };

    const selectHeaderCellsAction = (type: HeaderType, ids: string[]) => {
        dispatch(matrixSelectHeaderCells(nodeId, type, ids));
    };

    const isIdSelected = (id: string) => {
        return selectedHeaderCells.ids.includes(id);
    };

    const selected = isIdSelected(id);

    const onHeaderCellSelect = (event: React.MouseEvent) => {
        event.preventDefault();
        if (event.ctrlKey) {
            let newCellsIds = selectedHeaderCells.type !== type ? [] : [...selectedHeaderCells.ids];
            if (newCellsIds.includes(id)) {
                newCellsIds = newCellsIds.filter((selectedId) => selectedId !== id);
            } else {
                newCellsIds.push(id);
            }
            selectHeaderCellsAction(type, newCellsIds);
        } else if (event.shiftKey) {
            if (selectedHeaderCells.type !== type || selectedHeaderCells.ids.length === 0) {
                selectHeaderCellsAction(type, [id]);
            } else {
                const currentLanes = isColumn ? colsHeaders : rowsHeaders;
                const lasSelectedId = selectedHeaderCells.ids[selectedHeaderCells.ids.length - 1];
                const firstIndex = currentLanes.findIndex((lane) => lane.id === lasSelectedId);
                const secondIndex = currentLanes.findIndex((lane) => lane.id === id);
                const newCells: string[] = [];
                if (firstIndex > secondIndex) {
                    for (let i = firstIndex; i >= secondIndex; i--) {
                        newCells.push(currentLanes[i].id);
                    }
                } else {
                    for (let i = firstIndex; i <= secondIndex; i++) {
                        newCells.push(currentLanes[i].id);
                    }
                }

                selectHeaderCellsAction(type, newCells);
            }
        } else {
            selectHeaderCellsAction(type, [id]);
        }
    };

    const onHeaderCellDoubleClick = () => {
        const currentMatrixLane = currentMatrixLanes.find((matrixLane) => matrixLane.id === id);
        if (currentMatrixLane && matrixData) {
            matrixData.columns = colsHeaders;
            matrixData.rows = rowsHeaders;
            const onSubmit = (newName: InternationalString) => {
                if (!currentMatrixLane.text) {
                    currentMatrixLane.id = uuid();
                }
                currentMatrixLane.text = newName;
                updateMatrixHandeler(matrixData);
            };
            openRenameDialog(onSubmit, currentMatrixLane.text);
        }
    };

    const onHeaderCellClickHandler = (event: React.MouseEvent) => {
        if (isReadMode) return;

        if (headerCellClickTimer.current) clearTimeout(headerCellClickTimer.current);
        onHeaderCellSelect(event);

        if (event.detail === 1) {
            headerCellClickTimer.current = setTimeout(() => {}, 200);
        } else if (event.detail === 2) {
            onHeaderCellDoubleClick();
        }
    };

    const getAddedObjectById = (objectNodeId: NodeId): TTreeEntityState | null => {
        const addedObject = objectDefinitions[objectNodeId.id];

        if (!addedObject) return null;

        if (
            addedObject.type !== TreeItemType.ObjectDefinition ||
            objectNodeId.repositoryId !== nodeId.repositoryId ||
            objectNodeId.serverId !== nodeId.serverId
        ) {
            dispatch(
                showNotification({
                    id: uuid(),
                    type: NotificationType.DND_ERROR_WRONG_NODE_TYPE,
                    data: addedObject.type,
                }),
            );
            return null;
        }

        return addedObject;
    };

    const addObjectOnLane = (object: TTreeEntityState) => {
        const currentMatrixLane = currentMatrixLanes.find((matrixLane) => matrixLane.id === id);
        if (matrixData && currentMatrixLane) {
            currentMatrixLane.linkedNodeId = object.nodeId.id;
            currentMatrixLane.symbolId = object.idSymbol;
            currentMatrixLane.text = object.multilingualName;
            matrixData.columns = colsHeaders;
            matrixData.rows = rowsHeaders;

            const newCells: MatrixCellBPM8764[] = matrixData.cells.filter((cell) => {
                if (isColumn) {
                    return cell.columnId === id;
                } else {
                    return cell.rowId === id;
                }
            });
            newCells.forEach((newCell) => getStyleIdsFromCellWithSameLinkedNodeIds(newCell, matrixData));

            updateMatrixHandeler(matrixData);
        }
    };

    const onDropHandler = (event: React.DragEvent, type: HeaderType, id: string) => {
        if (!matrixData) return;

        if (isReadMode) {
            dispatch(showNotification({ id: uuid(), type: NotificationType.DND_ERROR_MODEL_IS_LOCKED }));
            return;
        }

        if (isRowDrag) {
            if (selected) return;
            const isDropBetweenLanes = type !== selectedHeaderCells.type;
            const isColumnDrop = colsHeaders.some((col) => col.id === id);

            const currentLanes = isColumnDrop ? [...colsHeaders] : [...rowsHeaders];
            let initLanes: MatrixLane[] = [];
            if (isDropBetweenLanes) {
                initLanes = isColumnDrop ? [...rowsHeaders] : [...colsHeaders];
            } else {
                initLanes = isColumnDrop ? [...colsHeaders] : [...rowsHeaders];
            }

            const dropIndex = currentLanes.findIndex((lane) => lane.id === id);

            const lanesToPastWithChildern: MatrixLane[] = getSelectedLanesWithChildren(
                selectedHeaderCells.ids,
                initLanes,
            );

            const notEmptySelectedLanes = initLanes.filter((lane) => isIdSelected(lane.id) && lane.text);
            const lanesForNewParent = notEmptySelectedLanes.filter(
                (lane) =>
                    lane.parentId && !lanesToPastWithChildern.some((laneToPast) => laneToPast.id === lane.parentId),
            );

            const lanesBefore = currentLanes.filter(
                (lane, index) => index < dropIndex && !isLaneExist(lane, lanesToPastWithChildern),
            );

            const lanesAfter = currentLanes.filter(
                (lane, index) => index >= dropIndex && !isLaneExist(lane, lanesToPastWithChildern),
            );

            const newLanes = [...lanesBefore, ...lanesToPastWithChildern, ...lanesAfter];

            let newParent: MatrixLane | undefined;
            const lastBeforeLane = lanesBefore[lanesBefore.length - 1];
            if (
                lastBeforeLane &&
                !haveChildren(lastBeforeLane.id, newLanes) &&
                getCurrentLvl(lastBeforeLane.id, newLanes) === 0
            ) {
                newParent = undefined;
            } else {
                newParent = lanesBefore.findLast((lane) => haveChildren(lane.id, currentLanes));
            }
            lanesForNewParent.forEach((lane) => {
                lane.parentId = newParent?.id;
            });

            if (isColumnDrop) {
                matrixData.columns = newLanes;
                if (isDropBetweenLanes) {
                    matrixData.rows = rowsHeaders.filter((row) => !isLaneExist(row, lanesToPastWithChildern));
                }
            } else {
                matrixData.rows = newLanes;
                if (isDropBetweenLanes) {
                    matrixData.columns = colsHeaders.filter((col) => !isLaneExist(col, lanesToPastWithChildern));
                }
            }
            if (isDropBetweenLanes) {
                const newSelectedHeaderType = isColumnDrop ? HeaderType.column : HeaderType.row;
                selectHeaderCellsAction(newSelectedHeaderType, selectedHeaderCells.ids);

                const updatedCells = getCells(matrixData);
                lanesToPastWithChildern.forEach((pastedLane) => {
                    const newCells: MatrixCellBPM8764[] = updatedCells.filter((cell) => {
                        return cell.rowId === pastedLane.id || cell.columnId === pastedLane.id;
                    });
                    newCells.forEach((newCell) =>
                        getStyleIdsFromCellWithSameLinkedNodeIds(newCell, matrixData, pastedLane.id),
                    );
                });
            }

            updateMatrixHandeler(matrixData);
        } else {
            const droppedObjNodeId: NodeId = JSON.parse(event.dataTransfer.getData('text'));
            if (!droppedObjNodeId) return;

            const droppedObject = getAddedObjectById(droppedObjNodeId);
            if (!droppedObject) return;

            addObjectOnLane(droppedObject);
        }
    };

    const getFrame = (items: HTMLTableCellElement[]) => {
        const { offsetLeft, offsetTop } = items[0],
            frame = { lx: offsetLeft, ly: offsetTop, rx: offsetLeft, ry: offsetTop };

        items.forEach((item) => {
            const { offsetLeft, offsetTop, clientHeight, clientWidth } = item;

            if (offsetLeft < frame.lx) frame.lx = offsetLeft;
            if (offsetTop < frame.ly) frame.ly = offsetTop;
            if (offsetLeft + clientWidth > frame.rx) frame.rx = offsetLeft + clientWidth;
            if (offsetTop + clientHeight > frame.ry) frame.ry = offsetTop + clientHeight;
        });
        return frame;
    };

    const startDragHeaderCellHandler = (event: React.DragEvent) => {
        setIsRowDrag(true);
        const selectedElements: HTMLTableCellElement[] = [];
        selectedHeaderCells.ids.forEach((id) => {
            const selectedElement = document.getElementById(id) as HTMLTableCellElement | null;
            if (selectedElement) {
                selectedElements.push(selectedElement);
            }
        });

        if (selectedElements.length === 0) return;

        const frame = getFrame(selectedElements);
        const image = document.createElement('div');
        image.style.position = 'absolute';
        image.style.zIndex = '-1';
        const dy = isColumn ? 165 : 0;
        selectedElements.forEach((selectedElement) => {
            const clone = selectedElement.cloneNode(true) as HTMLTableCellElement;
            const { offsetLeft, offsetTop, clientWidth, clientHeight, style, className } = selectedElement;
            clone.setAttribute('style', style.cssText);
            clone.className = className;
            clone.style.position = 'absolute';
            clone.style.left = `${offsetLeft - frame.lx}px`;
            clone.style.top = `${offsetTop - frame.ly}px`;
            clone.style.width = `${clientWidth}px`;
            clone.style.height = `${clientHeight}px`;
            if (isColumn) {
                (clone.childNodes[0] as HTMLDivElement).style.transform =
                    'rotate(-90deg) translate(calc(var(--headerSize)* -0.4), calc(var(--headerSize)* -0.4))';
            }
            clone.setAttribute('id', clone.id + '_clone');
            const wrapper = document.createElement('div');
            wrapper.setAttribute('id', clone.id + '_clone_wrapper');
            wrapper.appendChild(clone);
            image.appendChild(wrapper);
        });
        if (matrixContainerRef.current) {
            matrixContainerRef.current.appendChild(image);
            event.dataTransfer.setDragImage(image, 0, dy);
            setTimeout(() => {
                image.remove();
            }, 0);
        }
    };

    const getHeaderCellAttributes = (): React.HTMLAttributes<HTMLTableCellElement> => {
        return {
            id,
            className: cn(
                theme.headerCell,
                { [theme.filled]: !!text },
                { [theme.dropTarget]: isDropTarget },
                { [theme.selected]: selected },
                {
                    [theme.leftBorder]: !selected && isRow && rowsHeaders.findLastIndex((row) => row.text) >= index,
                },
                {
                    [theme.topBorder]: !selected && isColumn && colsHeaders.findLastIndex((col) => col.text) >= index,
                },
                {
                    [theme.noPointer]: isReadMode,
                },
            ),
            onClick: onHeaderCellClickHandler,
            onDragEnter: (event: React.DragEvent) => {
                setIsDropTarget(true);
            },
            onDragLeave: (event: React.DragEvent) => {
                setIsDropTarget(false);
            },
            onDragOver: (event: React.DragEvent) => {
                event.preventDefault();
            },
            onDrop: (event: React.DragEvent) => {
                setIsDropTarget(false);
                onDropHandler(event, type, id);
                setIsRowDrag(false);
            },
            onDragStart: startDragHeaderCellHandler,
            draggable: selected,
            onContextMenu: (event) => {
                event.preventDefault();
                if (selected) setContextMenuVisible(true);
            },
        };
    };

    const renderObjectIcon = () => {
        if (!text || !linkedNodeId) return undefined;

        return icon ? (
            <svg className={theme.entityIcon}>
                <image xlinkHref={icon} />
            </svg>
        ) : (
            <Icon className={theme.entityIcon} spriteSymbol={defaultIcon} />
        );
    };

    const lvl = getCurrentLvl(id, currentMatrixLanes);
    const selectedCellsHaveSameLvl = checkSameLvls(selectedHeaderCells.ids, currentMatrixLanes);
    const selectedCellsWithoutSpaces = checkLanesSpaces(selectedHeaderCells.ids, currentMatrixLanes);
    const selectedCellsCanUpLvl = checkLanesCanUpLvl(selectedHeaderCells.ids, currentMatrixLanes);
    const parentForLvlDown = getParenForLvlDown(currentMatrixLanes, selectedHeaderCells.ids, lvl);

    const disableLvlDown = !selectedCellsHaveSameLvl || !selectedCellsWithoutSpaces || !parentForLvlDown;
    const disableLvlUp = !selectedCellsCanUpLvl || !selectedCellsWithoutSpaces;

    const contextMenuItems: ItemType[] = [
        getMenuItem(intl.formatMessage(messages[ContextActions.levelUp]), ContextActions.levelUp, disableLvlUp),
        getMenuItem(intl.formatMessage(messages[ContextActions.levelDown]), ContextActions.levelDown, disableLvlDown),
    ];

    const hideContextMenu = () => {
        setContextMenuVisible(false);
    };

    const headerLevelUp = () => {
        const selectedIdsWithoutChildren = excludeChildIds(selectedHeaderCells.ids, currentMatrixLanes);
        selectedIdsWithoutChildren.forEach((id) => {
            const selectedHederCell = currentMatrixLanes.find((lane) => lane.id === id);
            if (!selectedHederCell?.parentId) return;

            const parent = currentMatrixLanes.find((lane) => lane.id === selectedHederCell.parentId);
            if (parent) {
                selectedHederCell.parentId = parent.parentId;
            }
        });
        matrixData.columns = colsHeaders;
        matrixData.rows = rowsHeaders;
        updateMatrixHandeler(matrixData);
    };

    const headerLevelDown = () => {
        const selectedIdsWithoutChildren = excludeChildIds(selectedHeaderCells.ids, currentMatrixLanes);
        if (parentForLvlDown) {
            currentMatrixLanes.forEach((lane) => {
                if (selectedIdsWithoutChildren.includes(lane.id)) lane.parentId = parentForLvlDown.id;
            });
            matrixData.columns = colsHeaders;
            matrixData.rows = rowsHeaders;
            updateMatrixHandeler(matrixData);
        }
    };

    const handleMenuAction = (event: MenuInfo) => {
        switch (event.key) {
            case ContextActions.levelUp:
                headerLevelUp();
                break;
            case ContextActions.levelDown:
                headerLevelDown();
                break;
        }
    };

    const isHaveChildren = haveChildren(id, currentMatrixLanes);

    const headerLaneStyle: React.CSSProperties = {
        paddingLeft: `${25 * lvl}px`,
        transform: isColumn ? `translateX(${columnTextXOffset}px) rotate(-90deg)` : 'unset',
        width: `${isColumn ? columnHeaderHeight : rowHeaderWidth}px`,
    };

    return (
        <th {...getHeaderCellAttributes()} ref={cellElement}>
            {isContextMenuVisible && (
                <NewMatrixContextMenu
                    visible={isContextMenuVisible}
                    menuItems={contextMenuItems}
                    hideContextMenu={hideContextMenu}
                    handleMenuAction={handleMenuAction}
                />
            )}
            <div
                className={cn({
                    [theme.headerColumn]: isColumn,
                    [theme.headerRow]: isRow,
                })}
                style={headerLaneStyle}
            >
                <div
                    className={cn({
                        [theme.toggler]: isHaveChildren,
                        [theme.haveChildren]: isHaveChildren,
                        [theme.open]: true,
                    })}
                />
                <div className={theme.headerIcon}>{renderObjectIcon()}</div>
                <div className={theme.headerText}>{text}</div>
            </div>
        </th>
    );
};
