import { defineMessages } from 'react-intl';

export default defineMessages({
    objectParameters: {
        id: 'SymbolGenerator.objectParameters',
        defaultMessage: 'Параметры объекта:',
    },
    save: {
        id: 'SymbolGenerator.save',
        defaultMessage: 'Сохранить',
    },
    cancel: {
        id: 'SymbolGenerator.cancel',
        defaultMessage: 'Отмена',
    },
    width: {
        id: 'SymbolGenerator.width',
        defaultMessage: 'Ширина объекта:',
    },
    height: {
        id: 'SymbolGenerator.height',
        defaultMessage: 'Высота объекта:',
    },
    color: {
        id: 'SymbolGenerator.color',
        defaultMessage: 'Цвет объекта:',
    },
    borders: {
        id: 'SymbolGenerator.borders',
        defaultMessage: 'Границы объекта:',
    },
    bordersWidth: {
        id: 'SymbolGenerator.bordersWidth',
        defaultMessage: 'Грани',
    },
    borderThickness: {
        id: 'SymbolGenerator.borderThickness',
        defaultMessage: 'Толщина границы:',
    },
    cornerRounding: {
        id: 'SymbolGenerator.cornerRounding',
        defaultMessage: 'Скругление углов:',
    },
    rounding: {
        id: 'SymbolGenerator.rounding',
        defaultMessage: 'Скругление',
    },
    borderType: {
        id: 'SymbolGenerator.borderType',
        defaultMessage: 'Тип границы:',
    },
    iconSvg: {
        id: 'SymbolGenerator.iconSvg',
        defaultMessage: 'Иконка (формат SVG):',
    },
    iconWidth: {
        id: 'SymbolGenerator.iconWidth',
        defaultMessage: 'Ширина иконки:',
    },
    iconHeight: {
        id: 'SymbolGenerator.iconHeight',
        defaultMessage: 'Высота иконки:',
    },
    iconPosition: {
        id: 'SymbolGenerator.iconPosition',
        defaultMessage: 'Положение иконки:',
    },
    rightTop: {
        id: 'SymbolGenerator.rightTop',
        defaultMessage: 'Право верх',
    },
    centerTop: {
        id: 'SymbolGenerator.centerTop',
        defaultMessage: 'Центр верх',
    },
    leftTop: {
        id: 'SymbolGenerator.leftTop',
        defaultMessage: 'Лево верх',
    },
    rightCenter: {
        id: 'SymbolGenerator.rightCenter',
        defaultMessage: 'Право центр',
    },
    center: {
        id: 'SymbolGenerator.center',
        defaultMessage: 'Центр',
    },
    leftCenter: {
        id: 'SymbolGenerator.leftCenter',
        defaultMessage: 'Лево центр',
    },
    rightBottom: {
        id: 'SymbolGenerator.rightBottom',
        defaultMessage: 'Право низ',
    },
    centerBottom: {
        id: 'SymbolGenerator.centerBottom',
        defaultMessage: 'Центр низ',
    },
    leftBottom: {
        id: 'SymbolGenerator.leftBottom',
        defaultMessage: 'Лево низ',
    },
    iconPadding: {
        id: 'SymbolGenerator.iconPadding',
        defaultMessage: 'Отступы от граней:',
    },
    iconScale: {
        id: 'SymbolGenerator.iconScale',
        defaultMessage: 'Масштабирование иконки:',
    },
    fixed: {
        id: 'SymbolGenerator.fixed',
        defaultMessage: 'Без сохранения пропорций',
    },
    relative: {
        id: 'SymbolGenerator.relative',
        defaultMessage: 'С сохранением пропорций',
    },
    none: {
        id: 'SymbolGenerator.none',
        defaultMessage: 'Не масштабируется',
    },
    iconLib: {
        id: 'SymbolGenerator.iconLib',
        defaultMessage: 'Библиотека иконок',
    },
    borderColor: {
        id: 'SymbolGenerator.borderColor',
        defaultMessage: 'Цвет границы:',
    },
    uploadIcon: {
        id: 'SymbolGenerator.uploadIcon',
        defaultMessage: 'Загрузить иконку',
    },
    symbolForm: {
        id: 'SymbolGenerator.symbolForm',
        defaultMessage: 'Форма объекта:',
    },

    rectangleFixedIcon: {
        id: 'SymbolGenerator.rectangleFixedIcon',
        defaultMessage: 'Прямоугольник (фиксированная иконка)',
    },
    rectangle: {
        id: 'SymbolGenerator.rectangle',
        defaultMessage: 'Прямоугольник',
    },
    hexagon: {
        id: 'SymbolGenerator.hexagon',
        defaultMessage: 'Шестиугольник',
    },
    rectangleFunction: {
        id: 'SymbolGenerator.rectangleFunction',
        defaultMessage: 'Рыбка/функция/процесс (фиксированная иконка)',
    },
    circle: {
        id: 'SymbolGenerator.circle',
        defaultMessage: 'Круг',
    },
    rhombus: {
        id: 'SymbolGenerator.rhombus',
        defaultMessage: 'Ромб',
    },
});
