import { connect } from 'react-redux';
import { TChangedProperties } from '../../../actions/changedProperties.types';
import { closeDialog } from '../../../actions/dialogs.actions';
import { navigatorPropertiesChangePropertySetAction } from '../../../actions/navigatorProperties.actions';
import {
    treeItemProperiesChangeRequest,
    treeItemUmlClassObjectSave,
    treeNodeSubscribe,
    treeNodeUnsubscribe,
} from '../../../actions/tree.actions';
import { EditorMode } from '../../../models/editorMode';
import { TNavigatorPropertiesData } from '../../../models/navigatorPropertiesSelectorState.types';
import {
    buildCommonProperties,
    buildEdgeProperties,
    buildKanbanProperties,
    buildMatrixProperties,
    buildModelProperties,
    buildInstanceProperties,
    buildObjectProperties,
    buildScriptProperties,
    buildSpreadsheetProperties,
    buildWikiProperties,
    buildEdgeDefinitionProperties,
    buildSettingsProperties,
} from '../../../models/properties/accessible-properties';
import { MxCell } from '../../../mxgraph/mxgraph';
import { TRootState } from '../../../reducers/root.reducer.types';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { ObjectTypeSelectors } from '../../../selectors/objectType.selectors';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import {
    AttributeValue,
    DiagramElement,
    EdgeDefinitionNode,
    EdgeInstance,
    EventDescriptorMacros,
    EdgeType,
    FolderType,
    Node,
    NodeId,
    ObjectDefinitionNode,
    ObjectType,
    ParentModelOfEdgeDefinition,
    PresetImage,
    ModelNode,
    ModelType,
    ApprovalDTO,
    Symbol,
    FolderNode,
    AttributeType,
} from '../../../serverapi/api';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TreeItemType } from '../../Tree/models/tree';
import { ObjectPropertiesDialog } from '../components/ObjectPropertiesDialog.component';
import { TObjectPropertiesDialogProps } from '../components/ObjectPropertiesDialog.types';
import { UserProfileSelectors } from '../../../selectors/userProfile.selectors';
import { SymbolSelectors } from '../../../selectors/symbol.selectors';
import { hasModelEditorLicense } from '../../../selectors/authorization.selectors';
import { FolderTypeSelectors } from '../../../selectors/folderType.selectors';
import { PresetImageSelectors } from '../../../selectors/presetSettings/presetImage.selectors';
import { getCreatableFolderTypes } from '../../../services/bll/FolderTypeBLLService';
import { TCurrentUserProfile } from '../../../reducers/userProfile.reducer.types';
import { PrincipalsSelectors } from '../../../selectors/principals.selectors';
import { saveModelEvents } from '../../../actions/modelEvents.actions';
import { syncGraphOnAttributeChange } from '../../../actions/attribute.actions';
import { ObjectDefinitionSelectors } from '../../../selectors/objectDefinition.selectors';
import { ObjectDefinitionImpl, ObjectInstanceImpl } from '../../../models/bpm/bpm-model-impl';
import { getNodeWithChildrenFromNodes } from '../../../services/utils/treeService.utils';
import { UML_OBJECT_TYPE } from '../../../mxgraph/ComplexSymbols/symbols/UML/UMLSymbols.constants';
import { orderSortCompareTreeUml } from '../../../models/tree';
import { EdgeDefinitionSelectors } from '../../../selectors/edgeDefinition.selector';
import { BPMMxGraph } from '../../../mxgraph/bpmgraph';
import { updateAllCellsOverlays, updateDefinitionOverlay } from '../../../actions/overlay.actions';
import { EdgeTypeSelectors } from '../../../selectors/edgeType.selectors';
import { ModelTypeSelectors } from '@/selectors/modelType.selectors';
import { ApprovalSelectors } from '@/selectors/approval.selectors';
import { AttributeTypeSelectors } from '@/selectors/attributeType.selectors';
import { isUndefined } from 'is-what';
import { SubscribedNodesIdsSelectors } from '@/selectors/subscribedNodesIds.selectors';

const mapStateToProps = (
    state: TRootState,
    props: TObjectPropertiesDialogProps,
): Partial<TObjectPropertiesDialogProps> => {
    const currentLocale = getCurrentLocale(state);
    const { node, diagramElementAttributeTypes, cellId, graph, tab, elementId } = props;
    // если нет node то это связь, значит есть graph. Графа нет если окно открыто из навигатора, значит это не связь
    const serverId = node?.nodeId.serverId || (graph?.id.serverId as string);
    const isModelEditor: boolean = hasModelEditorLicense(state);
    const repositoryId = node?.nodeId.repositoryId || (graph?.id.repositoryId as string);
    let diagramElementChangedAttributes: AttributeValue[] | undefined;

    const userProfile: TCurrentUserProfile | undefined = UserProfileSelectors.selectUserProfileByNodeId(node?.nodeId)(
        state,
    );
    const isSubscribedToNode = node ? SubscribedNodesIdsSelectors.getIsSubscribedToNode(node.nodeId)(state) : false;

    const presetId: string = TreeSelectors.presetById(node?.nodeId || graph?.id)(state);
    const attributeTypes: AttributeType[] = AttributeTypeSelectors.allInPreset(serverId, presetId)(state);

    if (graph?.getModel && cellId) {
        const element: DiagramElement | undefined = graph.getModel().getCell(cellId)?.getValue();
        diagramElementChangedAttributes = element?.attributes || [];
    }

    let propertiesData: TNavigatorPropertiesData | undefined;
    let settingsData: TNavigatorPropertiesData | undefined;
    let instancePropertiesData: TNavigatorPropertiesData | undefined;
    let isEdge = false;
    let edgeInstance: EdgeInstance | undefined;
    let isEntityEditable: boolean = true;
    let folderTypes: FolderType[] = [];
    let allNotationFolderTypes: FolderType[] = [];
    let presetImages: PresetImage[] = [];
    const ignorePresetStyles = graph
        ?.getModel()
        .getCell(cellId || '')
        ?.getValue().ignorePresetStyles;
    let classPropertyObjects: ObjectDefinitionNode[] = [];
    let classMethodObjects: ObjectDefinitionNode[] = [];
    let classReceptionObjects: ObjectDefinitionNode[] = [];
    let classMethodParameterObjects: ObjectDefinitionNode[] = [];
    let hasDefinitionOfEdgeInstance: boolean = false;
    let edgeEntries: ParentModelOfEdgeDefinition[] | undefined;
    let isAllowedApprovals: boolean = false;
    let approvals: ApprovalDTO[] = [];
    let symbol: Symbol | undefined;
    let isModelTypeDeleted: boolean = false;
    let isObjectTypeDeleted: boolean = false;
    let isFolderTypeDeleted: boolean = false;
    let isEdgeTypeDeleted: boolean = false;
    let userModelTypeAttributes: AttributeType[] | undefined;

    if (node) {
        presetImages = PresetImageSelectors.listAllByPreset(serverId, presetId)(state);
        // строим проперти выделенного узла
        const { type } = node;

        // todo: надо переписать контейнер. Эти ifы уйдут если в сагах билдить проперти
        switch (type) {
            case TreeItemType.Model:
                propertiesData = buildModelProperties(node, currentLocale, attributeTypes, userProfile);
                settingsData= buildSettingsProperties(node, currentLocale);
                const { modelTypeId } = node as ModelNode;
                const modelType: ModelType | undefined = ModelTypeSelectors.byId(
                    { modelTypeId: modelTypeId || '', serverId },
                    presetId,
                )(state);
                isModelTypeDeleted = isUndefined(modelType);
                isAllowedApprovals = !!modelType?.allowApprovals;
                break;
            case TreeItemType.Kanban:
                propertiesData = buildKanbanProperties(node, currentLocale, attributeTypes, userProfile);
                isAllowedApprovals = true;
                break;
            case TreeItemType.Wiki:
                propertiesData = buildWikiProperties(node, currentLocale, attributeTypes, userProfile);
                isAllowedApprovals = true;
                break;
            case TreeItemType.Script:
                propertiesData = buildScriptProperties(node, currentLocale, attributeTypes, userProfile);
                isAllowedApprovals = true;
                break;
            case TreeItemType.Matrix:
                propertiesData = buildMatrixProperties(node, currentLocale, attributeTypes, userProfile);
                isAllowedApprovals = true;
                break;
            case TreeItemType.Spreadsheet:
                propertiesData = buildSpreadsheetProperties(node, currentLocale, attributeTypes, userProfile);
                isAllowedApprovals = true;
                break;
            case TreeItemType.File:
                propertiesData = buildCommonProperties(node, currentLocale, attributeTypes, userProfile);
                settingsData = buildSettingsProperties(node, currentLocale);
                isAllowedApprovals = true;
                break;
            case TreeItemType.ObjectDefinition:
                {
                    const { objectTypeId, idSymbol } = node as ObjectDefinitionNode;
                    const typeId = { objectTypeId: objectTypeId!, presetId, serverId };
                    const objectType: ObjectType | undefined = ObjectTypeSelectors.byId(typeId)(state);
                    settingsData = buildSettingsProperties(node, currentLocale);
                    propertiesData = buildObjectProperties(
                        node,
                        currentLocale,
                        objectType,
                        attributeTypes,
                        userProfile,
                    );
                    isObjectTypeDeleted = isUndefined(objectType);
                    symbol = SymbolSelectors.byId({ id: idSymbol || '', serverId }, presetId)(state);
                    const value: ObjectInstanceImpl | undefined =
                        cellId && graph?.getModel()?.getCell(cellId)?.getValue();

                    if (value && (attributeTypes || diagramElementAttributeTypes)) {
                        const instanceSymbol: Symbol | undefined = SymbolSelectors.byId(
                            { id: value.symbolId || '', serverId },
                            presetId,
                        )(state);

                        instancePropertiesData = buildInstanceProperties(
                            value,
                            currentLocale,
                            attributeTypes || diagramElementAttributeTypes,
                            userProfile,
                            instanceSymbol?.name,
                        );
                    }
                    isEntityEditable = UserProfileSelectors.isObjectTypeEditable(
                        serverId,
                        presetId,
                        objectTypeId!,
                    )(state);

                    const objectDefinition: ObjectDefinitionImpl | undefined = ObjectDefinitionSelectors.byId({
                        ...node.nodeId,
                    })(state);

                    if (objectDefinition) {
                        const allClassObjects = getNodeWithChildrenFromNodes(
                            objectDefinition.nodeId,
                            ObjectDefinitionSelectors.values()(state),
                        ) as ObjectDefinitionNode[];

                        classPropertyObjects = allClassObjects
                            .filter((item) => item.objectTypeId === UML_OBJECT_TYPE.ATTRIBUTE)
                            .sort(orderSortCompareTreeUml(node.childrenIdOrder));
                        classMethodObjects = allClassObjects
                            .filter((item) => item.objectTypeId === UML_OBJECT_TYPE.METHOD)
                            .sort(orderSortCompareTreeUml(node.childrenIdOrder));

                        const methodParametersChildrenIdOrder = classMethodObjects
                            .map((item) => item.childrenIdOrder || [])
                            .flat();

                        classMethodParameterObjects = allClassObjects
                            .filter((item) => item.objectTypeId === UML_OBJECT_TYPE.PARAMETER)
                            .sort(orderSortCompareTreeUml(methodParametersChildrenIdOrder));
                        classReceptionObjects = allClassObjects
                            .filter((item) => item.objectTypeId === UML_OBJECT_TYPE.RECEPTION)
                            .sort(orderSortCompareTreeUml(node.childrenIdOrder));
                    }
                    isAllowedApprovals = !!objectType?.allowApprovals;
                }
                break;
            case TreeItemType.EdgeDefinition: {
                settingsData = buildSettingsProperties(node, currentLocale);
                const { edgeTypeId } = node as EdgeDefinitionNode;
                const edgeType: EdgeType | undefined = EdgeTypeSelectors.byId({
                    edgeTypeId: edgeTypeId!,
                    presetId,
                    serverId,
                })(state);

                isEdgeTypeDeleted = isUndefined(edgeType);
                propertiesData = buildEdgeDefinitionProperties(
                    node,
                    currentLocale,
                    edgeType,
                    attributeTypes,
                    userProfile,
                );
                isAllowedApprovals = !!edgeType?.allowApprovals;
                break;
            }
            default:
                propertiesData = buildCommonProperties(node, currentLocale, attributeTypes, userProfile);
                isAllowedApprovals = true;
                break;
        }
        if (isAllowedApprovals && node?.nodeId) {
            approvals = ApprovalSelectors.byApprovedItemIdArrNoDraft(node.nodeId)(state);
        }
        if (type === TreeItemType.Folder) {
            allNotationFolderTypes = FolderTypeSelectors.listByPresetId({ serverId, presetId })(state);
            const parentNodeFolderType = allNotationFolderTypes.find(
                (folderType) => folderType.id === props.parentFolderTypeId,
            );
            folderTypes = getCreatableFolderTypes(allNotationFolderTypes, parentNodeFolderType, currentLocale);
            const folderType: FolderType | undefined = folderTypes.find(
                (ft) => ft.id === (node as FolderNode)?.folderType,
            );
            isFolderTypeDeleted = isUndefined(folderType);
  
            isAllowedApprovals = !!folderType?.allowApprovals;
        } else if (propertiesData) {
            // тип должен меняться только у папки
            delete propertiesData.folderType;
        }
    }

    if (graph && cellId) {
        // строим проперти выделенной связи
        const cell: MxCell | undefined = graph.getModel().getCell(cellId);
        userModelTypeAttributes = graph?.modelType?.attributes;

        if (cell && cell.isEdge() && cell.value) {
            isEdge = true;
            edgeInstance = cell.getValue() as EdgeInstance;
            hasDefinitionOfEdgeInstance = edgeInstance?.edgeDefinitionId !== undefined;

            let edgeDefintion: EdgeDefinitionNode | undefined;

            if (hasDefinitionOfEdgeInstance) {
                edgeDefintion = EdgeDefinitionSelectors.byId({ ...graph.id, id: edgeInstance?.edgeDefinitionId! })(
                    state,
                );
                edgeEntries = edgeDefintion?.edgeEntries;
            }

            presetImages = PresetImageSelectors.listAllByPreset(serverId, presetId)(state);
            isEntityEditable = UserProfileSelectors.isEdgeTypeEditable(
                serverId,
                presetId,
                edgeInstance.edgeTypeId,
            )(state);
            const edgeType: EdgeType | undefined = EdgeTypeSelectors.byId({
                edgeTypeId: edgeInstance.edgeTypeId,
                presetId,
                serverId,
            })(state);
            if (!hasDefinitionOfEdgeInstance) {
                propertiesData = buildEdgeProperties(graph, cell)(
                    { ...edgeInstance, edgeType },
                    currentLocale,
                    [],
                    userProfile,
                );
            }

            if (attributeTypes) {
                // у связи атрибуты хранятся в attributeTypes
                instancePropertiesData = buildInstanceProperties(
                    { ...edgeInstance, edgeType },
                    currentLocale,
                    attributeTypes || diagramElementAttributeTypes || [],
                    userProfile,
                );
            }
        }
    }

    return {
        ignorePresetStyles,
        isEdge,
        edgeInstance,
        hasDefinitionOfEdgeInstance,
        edgeEntries,
        node,
        serverId,
        repositoryId,
        propertiesData,
        settingsData,
        graphId: graph?.id,
        graph,
        symbol,
        instancePropertiesData,
        modelLocked: graph?.mode === EditorMode.Read,
        diagramElementChangedAttributes,
        isEntityEditable,
        folderTypes,
        allNotationFolderTypes,
        locale: currentLocale,
        isModelEditor,
        presetImages,
        attributeTypes: props.attributeTypes,
        allAttributeTypes: attributeTypes,
        userProfile,
        principals: PrincipalsSelectors.getAll(state),
        classPropertyObjects,
        classMethodObjects,
        classReceptionObjects,
        classMethodParameterObjects,
        isAllowedApprovals,
        approvals,
        isModelTypeDeleted,
        isObjectTypeDeleted,
        isFolderTypeDeleted,
        isEdgeTypeDeleted,
        isSubscribedToNode,
        userModelTypeAttributes,
        tab,
        elementId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onCancel() {
        dispatch(closeDialog(DialogType.PROPERTIES_DIALOG));
    },
    onSubmit( node: Node, oldNode: Node, changedProperties: TChangedProperties) {
        dispatch(treeItemProperiesChangeRequest(node, oldNode, changedProperties));
    },
    onSubmitDiagramElement(graphId: NodeId, cellId: string, changes: TNavigatorPropertiesData, removed: string[]) {
        dispatch(navigatorPropertiesChangePropertySetAction({ graphId, cellId, changes, removed }));
        dispatch(closeDialog(DialogType.PROPERTIES_DIALOG));
    },
    onSubmitFloatingAttributes() {
        dispatch(closeDialog(DialogType.PROPERTIES_DIALOG));
    },
    saveModelEvents(nodeId: NodeId, events: EventDescriptorMacros[]) {
        dispatch(saveModelEvents(nodeId, events));
    },
    syncGraphOnAttributeChange(attributes: AttributeValue[], graphId: NodeId, cellId: string) {
        dispatch(
            syncGraphOnAttributeChange({
                attributes,
                graphId,
                cellId,
            }),
        );
    },
    onSubmitUmlObjects(
        objectDefinitions: ObjectDefinitionNode[],
        deletedObjectIds: NodeId[],
        newMethods: ObjectDefinitionNode[],
        serverId: string,
        classObject: ObjectDefinitionNode,
    ) {
        dispatch(
            treeItemUmlClassObjectSave({ deletedObjectIds, objectDefinitions, newMethods, serverId, classObject }),
        );
    },
    onSubmitEdgeInstance(graph: BPMMxGraph) {
        dispatch(updateAllCellsOverlays(graph.id));
    },
    onChangeFloatingAttributes(nodeId: NodeId) {
        dispatch(updateDefinitionOverlay(nodeId));
    },
    onSubscribe(nodeId: NodeId) {
        dispatch(treeNodeSubscribe(nodeId));
    },
    onUnsubscribe(nodeId: NodeId) {
        dispatch(treeNodeUnsubscribe(nodeId));
    },
});

export const ObjectPropertiesDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ObjectPropertiesDialog);
