/* eslint-disable @typescript-eslint/no-use-before-define */
import { Button, ConfigProvider, DatePicker, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ruLocale from 'antd/es/locale/ru_RU';
import enLocale from 'antd/es/locale/en_US';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AccessModel, AuditActions, IAuditFetchParams, accessTypes } from '../../../actions/audit.actions.types';
import {
    AuditDto,
    DetailedAuditDTO,
    PermissionModel,
    PrincipalPermissionsAudit,
    UserDTO,
} from '../../../serverapi/api';
import style from './ActionsAudit.scss';
import actionTypesMessages from './actionTypes.messages';
import messages from './audit.messages';
import licenseMessages from '../../../models/licenseTypes.messages';
import accessMessages from '../../../models/userAccesses.messages';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Table, TableCellProps } from 'react-virtualized';
import { Locale } from '../../Header/components/Header/header.types';
import { IAuditServerState, TDetailedAudit } from '../../../reducers/audit.reducer.types';
import { useDispatch, useSelector } from 'react-redux';
import { ActionAuditSelectors } from '../../../selectors/actionAudit.selector';
import { fetchAudit, downloadCSV, fetchDetailedAudit } from '../../../actions/audit.actions';
import { MetaDataSelectors } from '../../../selectors/admintools.selectors';
import { ActionAuditDropdown } from './ActionAuditMenu';
import { TradeSecretPersonalDataFilter } from './TradeSecretPresonalDataFilter';
import { Spinner } from '@/modules/Spinner/Spinner.component';

const { RangePicker } = DatePicker;
const HEADER_HEIGHT = 35;

export const ActionsAuditList = () => {
    const intl = useIntl();

    const dispatch = useDispatch();

    const [dateRange, setDateRange] = useState({ start: undefined, end: undefined });
    const [searchInAuditList, setSearchInAuditList] = useState('');

    const filteredTypes = useSelector(ActionAuditSelectors.filteredTypes);
    const serverId = useSelector(MetaDataSelectors.getCurrentServerId);
    const auditData: IAuditServerState = useSelector(ActionAuditSelectors.auditData);
    const filteredAccesses: accessTypes[] = useSelector(ActionAuditSelectors.filteredAccesses);
    const statusAudit = useSelector(ActionAuditSelectors.statusAudit);
    const statusDownload = useSelector(ActionAuditSelectors.statusDownLoad);
    const { start, end } = dateRange;

    const cellHeightCache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 30,
    });

    useEffect(() => {
        loadAudit();
    }, []);

    useEffect(() => {
        cellHeightCache.clearAll();
    });

    const cellRendererId = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const content = auditData.data[rowIndex][dataKey];

        return (
            <CellMeasurer columnIndex={0} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={style.measurerCell}>{content}</div>
            </CellMeasurer>
        );
    };

    const timeCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const content = dayjs(auditData.data[rowIndex][dataKey]).format('DD-MM-YYYY HH:mm:ss');

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={1}>
                <div className={style.measurerCell}>{content}</div>
            </CellMeasurer>
        );
    };

    const cellRendererLogin = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const content = auditData.data[rowIndex][dataKey];

        return (
            <CellMeasurer columnIndex={2} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={style.measurerCell}>{content}</div>
            </CellMeasurer>
        );
    };

    const cellRendererObjectName = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const content = auditData.data[rowIndex][dataKey];

        return (
            <CellMeasurer columnIndex={3} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={style.measurerCell}>{content}</div>
            </CellMeasurer>
        );
    };

    const cellRendererTradeSecretPersonalData = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const audit: AuditDto = auditData.data[rowIndex];
        let content = '';
        if (audit.tradeSecret && audit.personalData) {
            content = intl.formatMessage(messages.tradeSecretPersonalData);
        } else if (audit.tradeSecret) {
            content = intl.formatMessage(messages.tradeSecret);
        } else if (audit.personalData) {
            content = intl.formatMessage(messages.personalData);
        }
        return (
            <CellMeasurer columnIndex={4} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={style.measurerCell}>{content}</div>
            </CellMeasurer>
        );
    };

    const cellRendererElementType = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const content =
            (auditData.data[rowIndex][dataKey] &&
                messages[auditData.data[rowIndex][dataKey]] &&
                intl.formatMessage(messages[auditData.data[rowIndex][dataKey]])) ||
            '';

        return (
            <CellMeasurer columnIndex={5} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={style.measurerCell}>{content}</div>
            </CellMeasurer>
        );
    };

    const cellRenderExceptionType = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const detailedDataMap: TDetailedAudit = auditData?.detailedAudit;
        const detailedAuditData: DetailedAuditDTO | undefined = detailedDataMap?.get({ id: auditData.data[rowIndex].id });
        const content = (
            <>
                {auditData.data[rowIndex].exceptionType || ''}{' '}
                {detailedAuditData?.exceptionMessage ? (
                    <span className={style.exceptionMessage}>
                        {intl.formatMessage(messages.exceptionMessage) + detailedAuditData?.exceptionMessage}
                    </span>
                ) : (
                    ''
                )}
            </>
        );

        return (
            <CellMeasurer columnIndex={6} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={style.measurerCell}>{content}</div>
            </CellMeasurer>
        );
    };

    const actionColumnCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const item = auditData.data[rowIndex];
        const detailedDataMap: TDetailedAudit | undefined = auditData?.detailedAudit;
        const detailedAuditData: DetailedAuditDTO | undefined = detailedDataMap?.get({ id: item.id });
        const actionsArr = Object.keys(AuditActions);

        const expandButton = (): JSX.Element => {
            return (
                <>
                    {actionsArr.includes(item.action) && !detailedAuditData && (
                        <div>
                            <Button
                                className={style.coverButton}
                                type="link"
                                onClick={() => {
                                    dispatch(fetchDetailedAudit({ serverId, id: item.id }));
                                }}
                            >
                                {intl.formatMessage(messages.uncover)}
                            </Button>
                        </div>
                    )}
                </>
            );
        };

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={7}>
                <div className={style.measurerCell}>
                    <div>
                        {actionTypesMessages[item.action]
                            ? intl.formatMessage(actionTypesMessages[item.action])
                            : item.action}
                    </div>
                    {expandButton()}
                    <div>{getDetails(detailedAuditData, item)}</div>
                </div>
            </CellMeasurer>
        );
    };

    const getDetails = (detailedAuditData: DetailedAuditDTO | undefined, item: AuditDto) => {
        switch (item.action) {
            case AuditActions.USER_TRY_AUTH:
            case AuditActions.USER_SUCCESS_AUTH:
            case AuditActions.USER_FAIL_AUTH: {
                try {
                    const params = detailedAuditData?.params && JSON.parse(detailedAuditData.params);

                    return params?.ip ? `${intl.formatMessage(messages.connectIp)}: ${params.ip}` : '';
                } catch (e) {
                    console.log(e);

                    return '';
                }
            }
            case AuditActions.WRITE_OBJECT_ACL: {
                const newPermissions: PrincipalPermissionsAudit[] =
                    (detailedAuditData?.newState && JSON.parse(detailedAuditData.newState)) || [];
                const oldPermissions: PrincipalPermissionsAudit[] =
                    (detailedAuditData?.oldState && JSON.parse(detailedAuditData.oldState)) || [];

                return newPermissions.map((p: PrincipalPermissionsAudit) => {
                    const convertPermission = (type: string, permissions: Array<PermissionModel>) => {
                        const permissionValue: boolean | undefined = permissions?.find(
                            (permissionModel: PermissionModel) => permissionModel.permission === type,
                        )?.isGranting;

                        if (permissionValue === false) {
                            return AccessModel.DENY;
                        }

                        if (permissionValue === true) {
                            return AccessModel.ALLOW;
                        }

                        return AccessModel.INHERIT;
                    };
                    const createValue = (permissions: Array<PermissionModel> | undefined) =>
                        permissions
                            ? intl.formatMessage(messages.value, {
                                  read: convertPermission('READ', permissions),
                                  create: convertPermission('CREATE', permissions),
                                  update: convertPermission('UPDATE', permissions),
                                  delete: convertPermission('DELETE', permissions),
                                  control: convertPermission('CONTROL', permissions),
                              })
                            : intl.formatMessage(messages.missing);

                    const oldPermission: PrincipalPermissionsAudit | undefined = oldPermissions.find(
                        (old) => old.adminId === p.adminId && old.userId === p.userId,
                    );

                    return intl.formatMessage(messages.WRITE_OBJECT_ACL_DETAILED, {
                        userName: p.userName,
                        adminName: p.adminName,
                        oldValue: createValue(oldPermission?.permissions),
                        newValue: createValue(p.permissions),
                    });
                });
            }
            case AuditActions.CHANGE_PRINCIPAL_GROUP: {
                const oldGroups: string[] =
                    (detailedAuditData?.oldState && JSON.parse(detailedAuditData.oldState)) || [];
                const newGroups: string[] =
                    (detailedAuditData?.newState && JSON.parse(detailedAuditData.newState)) || [];

                return intl.formatMessage(messages.CHANGE_PRINCIPAL_GROUP, {
                    oldGroups: oldGroups.toString(),
                    newGroups: newGroups.toString(),
                });
            }
            case AuditActions.DELETE_PRINCIPAL: {
                return intl.formatMessage(messages.DELETE_PRINCIPAL, {
                    login: detailedAuditData?.objectName,
                });
            }
            case AuditActions.CREATE_PRINCIPAL: {
                const userDTO: UserDTO = (detailedAuditData?.newState && JSON.parse(detailedAuditData.newState)) || [];

                return intl.formatMessage(messages.CREATE_PRINCIPAL, {
                    firstName: userDTO.firstName,
                    lastName: userDTO.lastName,
                    middleName: userDTO.middleName,
                    mail: userDTO.email,
                    department: userDTO.department,
                    position: userDTO.position,
                    company: userDTO.company,
                    blocked: userDTO.blocked,
                });
            }
            case AuditActions.CHANGE_PRINCIPAL_LICENSE: {
                const oldLicenses: string[] =
                    (detailedAuditData?.oldState && JSON.parse(detailedAuditData.oldState)) || [];
                const newLicenses: string[] =
                    (detailedAuditData?.newState && JSON.parse(detailedAuditData.newState)) || [];

                return intl.formatMessage(messages.CHANGE_PRINCIPAL_LICENSE, {
                    oldLicenses: oldLicenses.map((license) => intl.formatMessage(licenseMessages[license])).toString(),
                    newLicenses: newLicenses.map((license) => intl.formatMessage(licenseMessages[license])).toString(),
                });
            }
            case AuditActions.CHANGE_PRINCIPAL_ACCESS: {
                const oldAccess: string[] =
                    (detailedAuditData?.oldState && JSON.parse(detailedAuditData.oldState)) || [];
                const newAccess: string[] =
                    (detailedAuditData?.newState && JSON.parse(detailedAuditData.newState)) || [];

                return intl.formatMessage(messages.CHANGE_PRINCIPAL_ACCESS, {
                    oldLicenses: oldAccess.map((access) => intl.formatMessage(accessMessages[access])).toString(),
                    newLicenses: newAccess.map((access) => intl.formatMessage(accessMessages[access])).toString(),
                });
            }
            case AuditActions.RENAME_TREE_NODE: {
                const oldName: string | undefined = detailedAuditData?.objectName;
                const newName: string | undefined = detailedAuditData?.info;
                // oldName и newName могут быть пустыми строками, поэтому нужно их сравнение с undefined
                return oldName !== undefined && newName !== undefined
                    ? intl.formatMessage(messages.RENAME_TREE_NODE, { oldName, newName })
                    : ''
            }
            case AuditActions.IMPORT_PRESET:
            case AuditActions.IMPORT_PRESET_ELEMENTS: {
                return detailedAuditData?.params
                    ? intl.formatMessage(messages.presetId) + detailedAuditData?.params
                    : '';
            }
            case AuditActions.CHANGE_PRINCIPAL: {
                const oldUserDTO: UserDTO =
                    (detailedAuditData?.oldState && JSON.parse(detailedAuditData.oldState)) || [];
                const userDTO: UserDTO = (detailedAuditData?.newState && JSON.parse(detailedAuditData.newState)) || [];

                return [
                    userDTO.firstName !== oldUserDTO.firstName &&
                        intl.formatMessage(messages.CHANGE_PRINCIPAL_NAME, {
                            oldFirstName: oldUserDTO.firstName,
                            newFirstName: userDTO.firstName,
                        }),
                    userDTO.lastName !== oldUserDTO.lastName &&
                        intl.formatMessage(messages.CHANGE_PRINCIPAL_LAST_NAME, {
                            oldLastName: oldUserDTO.lastName,
                            newLastName: userDTO.lastName,
                        }),
                    userDTO.middleName !== oldUserDTO.middleName &&
                        intl.formatMessage(messages.CHANGE_PRINCIPAL_MIDDLE_NAME, {
                            oldMiddleName: oldUserDTO.middleName,
                            newMiddleName: userDTO.middleName,
                        }),
                    userDTO.email !== oldUserDTO.email &&
                        intl.formatMessage(messages.CHANGE_PRINCIPAL_MAIL, {
                            oldMail: oldUserDTO.email,
                            newMail: userDTO.email,
                        }),
                    userDTO.department !== oldUserDTO.department &&
                        intl.formatMessage(messages.CHANGE_PRINCIPAL_DEPARTMENT, {
                            oldDepartment: oldUserDTO.department,
                            newDepartment: userDTO.department,
                        }),
                    userDTO.position !== oldUserDTO.position &&
                        intl.formatMessage(messages.CHANGE_PRINCIPAL_POSITION, {
                            oldPosition: oldUserDTO.position,
                            newPosition: userDTO.position,
                        }),
                    userDTO.company !== oldUserDTO.company &&
                        intl.formatMessage(messages.CHANGE_PRINCIPAL_COMPANY, {
                            oldCompany: oldUserDTO.company,
                            newCompany: userDTO.company,
                        }),
                    userDTO.blocked !== oldUserDTO.blocked &&
                        intl.formatMessage(messages.CHANGE_PRINCIPAL_BLOCKED, {
                            oldBlocked: oldUserDTO.blocked,
                            newBlocked: userDTO.blocked,
                        }),
                ]
                    .filter((t) => t)
                    .join('\n');
            }
            default:
                return '';
        }
    };

    const onChangeRangeDate = (dates: any) => {
        setDateRange({
            start: dates?.[0]?.startOf('day').valueOf(),
            end: dates?.[1]?.endOf('day').valueOf(),
        });
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== searchInAuditList) {
            setSearchInAuditList(e.target.value.trim().toLowerCase());
        }
    };
    const auditFetchParams: IAuditFetchParams = {
        start,
        end,
        search: searchInAuditList,
        actions: filteredTypes.join(','),
        ts: filteredAccesses.includes(accessTypes.tradeSecret),
        pd: filteredAccesses.includes(accessTypes.personalData),
    };
    const getAuditCsvFile = () => {
        dispatch(downloadCSV(serverId, auditFetchParams));
    };

    const loadAudit = () => {
        dispatch(fetchAudit(serverId, auditFetchParams));
    };

    return (
        <div className={style.container} data-test="security_audit-container">
            <div className={style.headerContainer} data-test="security_audit_top-container">
                <div className={style.optionsContainer}>
                    <div className={style.searchContainer}>
                        <Input
                            data-test="security_audit_search-input"
                            suffix={<SearchOutlined />}
                            onChange={handleSearch}
                            style={{ width: 200 }}
                        />
                    </div>

                    <div className={style.datePickerContainer} data-test="security_audit_date-picker">
                        <ConfigProvider locale={intl.locale === Locale.ru ? ruLocale : enLocale}>
                            <RangePicker
                                className={style.rangePicker}
                                format="DD-MM-YYYY"
                                size="middle"
                                ranges={{
                                    [intl.formatMessage(messages.today)]: [dayjs(), dayjs()],
                                    [intl.formatMessage(messages.currentMonth)]: [
                                        dayjs().startOf('month'),
                                        dayjs().endOf('month'),
                                    ],
                                }}
                                onChange={onChangeRangeDate}
                                placeholder={[
                                    intl.formatMessage(messages.startDate),
                                    intl.formatMessage(messages.endDate),
                                ]}
                            />
                        </ConfigProvider>
                    </div>

                    <ActionAuditDropdown />
                    <TradeSecretPersonalDataFilter />
                </div>

                <div className={style.buttonContainer}>
                    <Button
                        data-test="security_audit_download-btn"
                        key="ok"
                        type="primary"
                        onClick={getAuditCsvFile}
                    >
                        {intl.formatMessage(messages.downloadBtn)}
                    </Button>
                    <Button
                        data-test="security_audit_refresh-btn"
                        key="download"
                        type="primary" 
                        onClick={loadAudit}
                    >
                        {intl.formatMessage(messages.loadBtn)}
                    </Button>
                </div>
            </div>
            <Spinner loading={statusDownload || statusAudit}>
                <div className={style.tableContainer}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                rowClassName={style.tableRow}
                                headerHeight={HEADER_HEIGHT}
                                width={width}
                                height={height}
                                rowHeight={cellHeightCache.rowHeight}
                                rowCount={auditData.data.length}
                                rowGetter={({ index }) => auditData.data[index]}
                            >
                                <Column
                                    data-test="security_audit_table_column-id"
                                    width={width}
                                    headerClassName={style.headerRowStyle}
                                    label={intl.formatMessage(messages.N)}
                                    dataKey="id"
                                    cellRenderer={cellRendererId}
                                />
                                <Column
                                    data-test="security_audit_table_column-time"
                                    width={width}
                                    headerClassName={style.headerRowStyle}
                                    label={intl.formatMessage(messages.time)}
                                    dataKey="time"
                                    cellRenderer={timeCellRenderer}
                                />
                                <Column
                                    data-test="security_audit_table_column-login"
                                    width={width}
                                    headerClassName={style.headerRowStyle}
                                    label={intl.formatMessage(messages.login)}
                                    dataKey="login"
                                    cellRenderer={cellRendererLogin}
                                />
                                <Column
                                    data-test="security_audit_table_column-objectName"
                                    width={width}
                                    headerClassName={style.headerRowStyle}
                                    label={intl.formatMessage(messages.objectName)}
                                    dataKey="objectName"
                                    cellRenderer={cellRendererObjectName}
                                />
                                <Column
                                    data-test="security_audit_table_column-elementType"
                                    width={width}
                                    headerClassName={style.headerRowStyle}
                                    label={intl.formatMessage(messages.elementType)}
                                    dataKey="elementType"
                                    cellRenderer={cellRendererElementType}
                                />
                                <Column
                                    data-test="security_audit_table_column-tradeSecretPersonalData"
                                    width={width}
                                    headerClassName={style.headerRowStyle}
                                    label={intl.formatMessage(messages.tradeSecretPersonalData)}
                                    dataKey="tradeSecretPersonalData"
                                    cellRenderer={cellRendererTradeSecretPersonalData}
                                    maxWidth = {45}
                                />
                                <Column
                                    data-test="security_audit_table_column-exceptionType"
                                    width={width}
                                    headerClassName={style.headerRowStyle}
                                    label={intl.formatMessage(messages.exceptionType)}
                                    dataKey="exceptionType"
                                    cellRenderer={cellRenderExceptionType}
                                />
                                <Column
                                    data-test="security_audit_table_column-action"
                                    width={width}
                                    headerClassName={style.headerRowStyle}
                                    label={intl.formatMessage(messages.action)}
                                    dataKey="action"
                                    cellRenderer={actionColumnCellRenderer}
                                    minWidth={80}
                                />
                            </Table>
                        )}
                    </AutoSizer>
                </div>
            </Spinner>
        </div>
    );
};
