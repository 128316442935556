import { put, select, takeEvery } from 'redux-saga/effects';
import { openDialog } from '../actions/dialogs.actions';
import { VIEW_MODEL_PROPERTY } from '../actionsTypes/modelProperty.actionTypes';
import { TViewPropertiesAction } from '../actions/modelProperty.actions.types';
import { IModelNode } from '../models/bpm/bpm-model-impl.types';
import { ObjectPropertiesDialogActiveTab } from '../models/objectPropertiesDialog';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { TreeItemType } from '../modules/Tree/models/tree';
import { ModelTypeSelectors } from '../selectors/modelType.selectors';
import { TreeSelectors } from '../selectors/tree.selectors';
import { AttributeType, Node } from '../serverapi/api';
import { nodeService } from '../services/NodeService';
import { loadNodeApprovals } from '@/actions/approval.actions';

function* handlePropertyView({
    payload,
    payload: {
        nodeId,
        nodeId: { serverId },
    },
}: TViewPropertiesAction) {
    const node: Node | undefined = yield nodeService().loadNodeFromServer(nodeId);
    if (!node) {
        return;
    }

    const modelTypeId: string | undefined = (node as IModelNode).modelTypeId || TreeItemType.Matrix.toLowerCase();
    let attributeTypes: Array<AttributeType> = [];
    if (modelTypeId) {
        const presetId: string = yield select(TreeSelectors.presetById(nodeId));
        (node as IModelNode).modelType = yield select(ModelTypeSelectors.byId({ modelTypeId, serverId }, presetId));
        attributeTypes = (node as IModelNode).modelType?.attributes || [];

        if ((node as IModelNode).modelType?.allowApprovals) {
            yield put(loadNodeApprovals({ nodeId }));
        }
    }

    yield put(
        openDialog(DialogType.PROPERTIES_DIALOG, {
            node,
            attributeTypes,
            tab:
                (payload.activeTab as ObjectPropertiesDialogActiveTab) ||
                ObjectPropertiesDialogActiveTab.NameAndAttributes,
            serverId,
        }),
    );
}

export function* initModelPropertyDialog() {
    yield takeEvery(VIEW_MODEL_PROPERTY, handlePropertyView);
}
