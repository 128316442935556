import { MxEvent, MxKeyHandler } from '../mxgraph';
import { BPMMxGraph } from '../bpmgraph';
import { KeyCodes } from '../../utils/keys';
import { compareNodeIds } from '../../utils/nodeId.utils';
import MxGraphSideEffects from '../sideEffects';
import { NodeId } from '../../serverapi/api';

export class BPMMxKeyHandler extends MxKeyHandler {
    static deleteKey = 'Delete';
    static arrowUp = 'ArrowUp';
    static arrowDown = 'ArrowDown';
    static arrowLeft = 'ArrowLeft';
    static arrowRight = 'ArrowRight';
    graph: BPMMxGraph;
    static keys = [
        BPMMxKeyHandler.deleteKey,
        BPMMxKeyHandler.arrowDown,
        BPMMxKeyHandler.arrowUp,
        BPMMxKeyHandler.arrowRight,
        BPMMxKeyHandler.arrowLeft,
    ];

    codes: Map<string, () => void>;

    constructor(graph: any, target?: any) {
        super(graph, target);
        this.codes = new Map();
    }

    isEventIgnored(evt: any): boolean {
        return this.isEnabledEvent(evt) ? false : super.isEventIgnored(evt);
    }

    isEnabledForEvent(evt: any): boolean {
        return this.isEnabledEvent(evt) ? true : super.isEnabledForEvent(evt);
    }

    /**
     * Function: keyDown
     *
     * Handles the event by invoking the function bound to the respective keystroke
     * if <isEnabledForEvent> returns true for the given event and if
     * <isEventIgnored> returns false, except for escape for which
     * <isEventIgnored> is not invoked.
     *
     * Parameters:
     *
     * evt - Key event that represents the keystroke.
     */
    keyDown(evt: KeyboardEvent) {
        if (this.isEnabledForEvent(evt) && this.isActiveGraph()) {
            // Cancels the editing if escape is pressed
            if (evt.keyCode === KeyCodes.ESCAPE /* Escape */) {
                this.escape(evt);
            }

            // Invokes the function for the keystroke
            else if (!this.isEventIgnored(evt)) {
                const boundFunction = this.getFunction(evt);
                if (boundFunction) {
                    boundFunction(evt);
                    const preventDefault = BPMMxKeyHandler.keys.some((k) => k === evt.key);
                    MxEvent.consume(evt, !preventDefault); // event will be consumed and handled in editor.saga,
                    // or other saga
                    // and if any of BPMMxKeyHandler.keys then default consumer will be called(example:
                    // when edit text on cell and Del is pressed, then text delete occurs instead of cell delete)
                    const hasSelectedCells =
                        this.graph.getSelectionCells() && this.graph.getSelectionCells().length > 0;
                    if (!this.graph.cellEditor.isContentEditing() && hasSelectedCells) {
                        evt.preventDefault();
                    }
                }
            }
        }
    }

    escape(evt) {
        super.escape(evt);
        if (this.graph.popupMenuHandler && this.graph.popupMenuHandler.isMenuShowing()) {
            this.graph.popupMenuHandler.hideMenu();
        }
    }

    private isEnabledEvent(evt: KeyboardEvent): boolean {
        if (evt.ctrlKey && evt.altKey && evt.code === 'KeyC') {
            return true;
        }

        if (!this.graph.isEnabled() || !this.isEnabled()) {
            return false;
        }

        const hasSelectedSells = this.graph.getSelectionCells() && this.graph.getSelectionCells().length > 0;
        const isArrowKey =
            evt.key === BPMMxKeyHandler.arrowUp ||
            evt.key === BPMMxKeyHandler.arrowDown ||
            evt.key === BPMMxKeyHandler.arrowLeft ||
            evt.key === BPMMxKeyHandler.arrowRight;
        const isDeleteKey = evt.key === BPMMxKeyHandler.deleteKey;

        return isDeleteKey || (hasSelectedSells && isArrowKey);
    }

    private isActiveGraph(): boolean {
        const activeGraphNodeId: NodeId | undefined = MxGraphSideEffects.getActiveGraphNodeId();

        return compareNodeIds(this.graph.id, activeGraphNodeId);
    }

    bindCode(keyCode: string, func: any) {
        this.codes.set(keyCode, func);
    }
}
