import React, { ChangeEvent, useEffect } from 'react';
import { ConfigProvider, Input, Button, DatePicker, Checkbox } from 'antd';
import { Column, Table, AutoSizer, CellMeasurerCache, CellMeasurer, TableCellProps } from 'react-virtualized';
import dayjs from 'dayjs';
import messages from './SessionList.messages';
import ruLocale from 'antd/es/locale/ru_RU';
import enLocale from 'antd/es/locale/en_US';
import style from './SessionList.scss';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SessionHistory } from '../../../serverapi/api';
import {
    getSessionData,
    setSessionActive,
    setSessionRange,
    setSessionSearchText,
    userSessionEnd,
} from '../../../actions/sessions.actions';
import { SessionSelectors } from '../../../selectors/sessions.selector';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { SearchOutlined } from '@ant-design/icons';
import { Locale } from '../../Header/components/Header/header.types';

const { RangePicker } = DatePicker;
const HEADER_HEIGHT = 35;

const FormattedDate = ({ children }) => <>{children ? dayjs(children).format('HH:mm DD.MM.YYYY') : ''}</>;

const SessionList = ({ intl }) => {
    const dispatch = useDispatch();
    const dataSource: SessionHistory[] = useSelector(SessionSelectors.searchData);
    const isActive: boolean = useSelector(SessionSelectors.active);
    const start: number | null = useSelector(SessionSelectors.start);
    const end: number | null = useSelector(SessionSelectors.end);
    const searchText: string = useSelector(SessionSelectors.search);
    const cellHeightCache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 44,
    });

    useEffect(() => {
        dispatch(getSessionData());
    }, []);

    const handleClickSearch = (): void => {
        dispatch(getSessionData());
    };

    const handleClickEndSession = (userId: number) => (): void => {
        dispatch(userSessionEnd(userId));
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
        dispatch(setSessionSearchText(event.target.value));
    };

    const handleCheckboxChange = (event: CheckboxChangeEvent): void => {
        dispatch(setSessionActive(event.target.checked));
    };

    const handleChangeRangePicker = (item: [dayjs.Dayjs, dayjs.Dayjs]): void => {
        if (item === null) {
            dispatch(setSessionRange({ start: null, end: null }));
        } else {
            const [startRange, endRange] = item;
            dispatch(
                setSessionRange({ start: startRange?.startOf('day').valueOf(), end: endRange?.endOf('day').valueOf() }),
            );
        }
    };

    const startedAtCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const data = dataSource[rowIndex];
        const content = data.startedAt;

        return formattedDateRenderer(dataKey, parent, rowIndex, content);
    };

    const finishedAtCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const data = dataSource[rowIndex];
        const content = data.finishedAt;

        return formattedDateRenderer(dataKey, parent, rowIndex, content);
    };

    const lastActionAtCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const data = dataSource[rowIndex];
        const content = data.lastActionAt;

        return formattedDateRenderer(dataKey, parent, rowIndex, content);
    };

    function formattedDateRenderer(dataKey, parent, rowIndex, content) {
        return (
            <CellMeasurer cache={cellHeightCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={style.measurerCell}>
                    <FormattedDate>{content}</FormattedDate>
                </div>
            </CellMeasurer>
        );
    }

    const userNameColumnCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const data = dataSource[rowIndex];
        const content = data.userName;

        return (
            <CellMeasurer cache={cellHeightCache} columnIndex={1} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={style.measurerCell}>{content}</div>
            </CellMeasurer>
        );
    };

    const isActiveColumnCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const data = dataSource[rowIndex];
        const content = data.isActive;

        return (
            <CellMeasurer cache={cellHeightCache} columnIndex={1} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={style.measurerCell}>
                    {content && (
                        <Button key="ok" type="primary" onClick={handleClickEndSession(data.userId)} data-test="users-sessions-tab_end-button">
                            {intl.formatMessage(messages.stop)}
                        </Button>
                    )}
                </div>
            </CellMeasurer>
        );
    };

    return (
        <div className={style.container} data-test="users-sessions-tab_container">
            <div className={style.searchFieldsContainer}>
                <div className={style.searchField}>
                    <Input
                        defaultValue={searchText}
                        onChange={handleSearch}
                        style={{ width: 200 }}
                        suffix={<SearchOutlined />}
                    />
                </div>
                <div className={style.rangePicker}>
                    <ConfigProvider locale={intl.locale === Locale.ru ? ruLocale : enLocale}>
                        <RangePicker
                            format="DD-MM-YYYY"
                            size="middle"
                            defaultValue={[start ? dayjs(start) : null, end ? dayjs(end) : null]}
                            ranges={{
                                [intl.formatMessage(messages.today)]: [dayjs(), dayjs()],
                                [intl.formatMessage(messages.currentMonth)]: [
                                    dayjs().startOf('month'),
                                    dayjs().endOf('month'),
                                ],
                            }}
                            onChange={handleChangeRangePicker}
                            placeholder={[intl.formatMessage(messages.startDate), intl.formatMessage(messages.endDate)]}
                        />
                    </ConfigProvider>
                </div>
                <div className={style.checkbox}>
                    <Checkbox defaultChecked={isActive} onChange={handleCheckboxChange}>
                        {intl.formatMessage(messages.onlyActive)}
                    </Checkbox>
                </div>
                <Button key="ok" type="primary" onClick={handleClickSearch} data-test="users-sessions-tab_update-button">
                    {intl.formatMessage(messages.load)}
                </Button>
            </div>
            <div className={style.tableContainer}>
                <AutoSizer>
                    {({ height, width }) => (
                        <Table
                            rowClassName={style.tableRow}
                            headerHeight={HEADER_HEIGHT}
                            width={width}
                            height={height}
                            rowHeight={cellHeightCache.rowHeight}
                            rowCount={dataSource.length}
                            rowGetter={({ index }) => dataSource[index]}
                        >
                            <Column
                                headerClassName={style.headerRowStyle}
                                label={intl.formatMessage(messages.N)}
                                dataKey="sessionNumber"
                                width={width}
                            />
                            <Column
                                headerClassName={style.headerRowStyle}
                                dataKey="startedAt"
                                width={width}
                                cellRenderer={startedAtCellRenderer}
                                label={intl.formatMessage(messages.timeStart)}
                            />
                            <Column
                                headerClassName={style.headerRowStyle}
                                dataKey="lastActionAt"
                                width={width}
                                cellRenderer={lastActionAtCellRenderer}
                                label={intl.formatMessage(messages.lastActionAt)}
                            />
                            <Column
                                headerClassName={style.headerRowStyle}
                                dataKey="finishedAt"
                                width={width}
                                cellRenderer={finishedAtCellRenderer}
                                label={intl.formatMessage(messages.timeEnd)}
                            />
                            <Column
                                headerClassName={style.headerRowStyle}
                                dataKey="userLogin"
                                width={width}
                                label={intl.formatMessage(messages.login)}
                            />
                            <Column
                                headerClassName={style.headerRowStyle}
                                dataKey="userName"
                                width={width}
                                label={intl.formatMessage(messages.userName)}
                                cellRenderer={userNameColumnCellRenderer}
                            />
                            <Column
                                headerClassName={style.headerRowStyle}
                                dataKey="sessionType"
                                width={width}
                                cellRenderer={({ rowData }) =>
                                    rowData.sessionType === 'SCRIPT'
                                        ? intl.formatMessage(messages.sessionTypeIsScript)
                                        : ''
                                }
                                label={intl.formatMessage(messages.sessionType)}
                            />
                            <Column dataKey="action" width={width} cellRenderer={isActiveColumnCellRenderer} />
                        </Table>
                    )}
                </AutoSizer>
            </div>
        </div>
    );
};

const IntlComponent = injectIntl(SessionList);

export { IntlComponent as SessionList };
