export const EDITOR_CREATED = 'EDITOR_CREATED';

export const EDITOR_INIT = 'EDITOR_INIT';

export const EDITOR_DESTROY = 'EDITOR_DESTROY';

export const EDITOR_COPY = 'EDITOR_COPY';

export const EDITOR_COPY_WHITEBOARD = 'EDITOR_COPY_WHITEBOARD';

export const EDITOR_SET_REPOSITORY_ID_WHERE_COPIED_FROM = 'EDITOR_SET_REPOSITORY_ID_WHERE_COPIED_FROM';

export const EDITOR_SET_COPIED_ELEMENTS = 'EDITOR_SET_COPIED_ELEMENTS';

export const EDITOR_PASTE = 'EDITOR_PASTE';

export const EDITOR_PASTE_WHITEBOARD = 'EDITOR_PASTE_WHITEBOARD';

export const EDITOR_DROP_PASTE_PREVIEW = 'EDITOR_DROP_PASTE_PREVIEW';

export const EDITOR_DRAG_SHAPE = 'EDITOR_DRAG_SHAPE';

export const EDITOR_ALIGN = 'EDITOR_ALIGN';

export const EDITOR_DISTRIBUTE = 'EDITOR_DISTRIBUTE';

export const EDITOR_MOVE_LAYER = 'EDITOR_MOVE_LAYER';

export const EDITOR_SELECT_ALL = 'EDITOR_SELECT_ALL';

export const EDITOR_DELETE = 'EDITOR_DELETE';

export const DELETE_SELECTED_CELLS_FROM_ACTIVE_GRAPH = 'DELETE_SELECTED_CELLS_FROM_ACTIVE_GRAPH';

export const EDITOR_MOVE_OBJECT = 'EDITOR_MOVE_OBJECT';

export const EDITOR_ESCAPE = 'EDITOR_ESCAPE';

export const EDITOR_GROUPING = 'EDITOR_GROUPING';

export const EDITOR_PROCESS_SPACE_ACTION = 'EDITOR_PROCESS_SPACE_ACTION';

export const EDITOR_LABEL_CHANGED = 'EDITOR_LABEL_CHANGED';

export const EDITOR_CELL_ADDED = 'EDITOR_CELL_ADDED';

export const EDITOR_CELL_COLOR_PICKED = 'EDITOR_CELL_COLOR_PICKED';

export const EDITOR_MODE_CHANGED = 'EDITOR_MODE_CHANGED';

export const EDITOR_MODE_CHANGED_PREPARE = 'EDITOR_MODE_CHANGED_PREPARE';

export const EDITOR_SET_FOCUS_AND_START_EDIT_LABEL = 'EDITOR_SET_FOCUS_AND_START_EDIT_LABEL';

export const EDITOR_CHANGE_EDGE_TYPE = 'EDITOR_CHANGE_EDGE_TYPE';

export const EDITOR_CHANGE_EDGE_MULTILINGUAL_NAME = 'EDITOR_CHANGE_EDGE_MULTILINGUAL_NAME';

export const EDITOR_CHANGE_EDGE_COLOR = 'EDITOR_CHANGE_EDGE_COLOR';

export const EDITOR_CHANGE_FONT_COLOR = 'EDITOR_CHANGE_FONT_COLOR';

export const EDITOR_CHANGE_EDGE_STYLE = 'EDITOR_CHANGE_EDGE_STYLE';

export const EDITOR_SET_OBJECT_TO_CELL = 'EDITOR_SET_OBJECT_TO_CELL';

export const CREATE_NEW__OBJECT_NAME = 'CREATE_NEW__OBJECT_NAME';

export const EDITOR_SINGLE_CELL_SELECTED = 'EDITOR_SINGLE_CELL_SELECTED';

export const EDITOR_FORMAT_BY_EXAMPLE = 'EDITOR_FORMAT_BY_EXAMPLE';

export const EDITOR_SET_CELL_DEFAULT_STYLE = 'EDITOR_SET_CELL_DEFAULT_STYLE';

export const EDITOR_LOOKUP_EXISTING_OBJECT_DEFINITIONS = 'EDITOR_LOOKUP_EXISTING_OBJECT_DEFINITIONS';

export const EDITOR_STOP_EDITING_CELL = 'EDITOR_STOP_EDITING_CELL';

export const EDITOR_PICK_OUT = 'EDITOR_PICK_OUT';

export const EDITOR_MOVE_TO = 'EDITOR_MOVE_TO';

export const EDITOR_MOVE_TO_DIRECT = 'EDITOR_MOVE_TO_DIRECT';

export const EDITOR_UPDATE = 'EDITOR_UPDATE';

export const EDITOR_SEARCH_ELEMENT = 'EDITOR_SEARCH_ELEMENT';

export const EDITOR_ADD_TABLE_ROW = 'EDITOR_ADD_TABLE_ROW';

export const EDITOR_REMOVE_TABLE_ROW = 'EDITOR_REMOVE_TABLE_ROW';

export const EDITOR_ADD_TABLE_COLUMN = 'EDITOR_ADD_TABLE_COLUMN';

export const EDITOR_REMOVE_TABLE_COLUMN = 'EDITOR_REMOVE_TABLE_COLUMN';

export const EDITOR_CHANGE_MODEL = 'EDITOR_CHANGE_MODEL';

export const EDITOR_ADD_BPMN_TABLE_ROW = 'EDITOR_ADD_BPMN_TABLE_ROW';

export const EDITOR_REMOVE_BPMN_TABLE_ROW = 'EDITOR_REMOVE_BPMN_TABLE_ROW';

export const EDITOR_DRAG_SYMBOL = 'EDITOR_DRAG_SYMBOL';

export const EDITOR_DRAG_NODE = 'EDITOR_DRAG_NODE';

export const EDITOR_SELECTION_MODEL_CHANGE = 'EDITOR_SELECTION_MODEL_CHANGE';

export const CHANGE_SYMBOL_FOR_CELL = 'CHANGE_SYMBOL_FOR_CELL';

export const EDITOR_ZOOM_IN = 'EDITOR_ZOOM_IN';

export const EDITOR_ZOOM_OUT = 'EDITOR_ZOOM_OUT';

export const EDITOR_ZOOM_TO = 'EDITOR_ZOOM_TO';

export const EDITOR_DROP_FILE_NODE = 'EDITOR_DROP_FILE_NODE';

export const EDITOR_CUT = 'EDITOR_CUT';

export const OPEN_SEARCH_ACTION = 'OPEN_SEARCH_ACTION';

export const OPEN_ELEMENT_PROPERTIES = 'OPEN_ELEMENT_PROPERTIES';

export const OPEN_BD_SEARCH_ACTION = 'OPEN_BD_SEARCH_ACTION';
