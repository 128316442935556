import React, { useCallback, useMemo } from 'react';
import theme from './Workspace.scss';
import { DnDTabs } from './DnDTabs.component';
import { homePageTab } from '../../../../models/home-page';
import { TWorkspaceTab } from '../../../../models/tab.types';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import { WorkSpaceTabTypes } from '../../WorkSpaceTabTypesEnum';
import { convertNodeIdToString } from './Workspace.component.utils';
import { TWorkspaceProps, TTabComponentProps } from '../../Workspace.types';
import { WorkspaceTabTitle } from './WorkspaceTabTitle.component';
import { WORKSPACE_CONTAINER_ID } from '../../../../mxgraph/components/BPMPopupMenu.types';
import { IMatrixNode } from '@/models/bpm/bpm-model-impl.types';
import { isNewMatrix } from '@/modules/Matrix/NewMatrix.utils';

export const Workspace = (props: TWorkspaceProps) => {
    const onTabsEdit = useCallback(
        (id: string, action: string) => {
            const { onAddTab, onChangeTab, onRemoveTabRequest, tabs } = props;
            if (action === 'add' && onAddTab) {
                const tab = tabs.find((t) => t.nodeId === homePageTab.nodeId);
                if (tab && onChangeTab) {
                    onChangeTab(tab);
                } else {
                    onAddTab(homePageTab);
                }
            }
            if (action === 'remove' && onRemoveTabRequest) {
                const tab = props.tabs.find(({ nodeId }) => compareNodeIds(nodeId, JSON.parse(id)));

                if (tab) {
                    onRemoveTabRequest(tab);
                }
            }
        },
        [props.onAddTab, props.onChangeTab, props.onRemoveTabRequest, props.tabs],
    );

    const onChangeTab = useCallback(
        (key: string) => {
            const tab = props.tabs.find(({ nodeId }) => compareNodeIds(nodeId, JSON.parse(key)));
            if (tab && props.onChangeTab) {
                props.onChangeTab(tab);
            }
        },
        [props.tabs, props.onChangeTab],
    );

    const transformTabToJsx = useCallback(
        (tab: TWorkspaceTab) => {
            let component = '';
            if (tab.type) {
                component = tab.type === WorkSpaceTabTypes.ADMINTOOLS_TAB ? tab.nodeId.id : tab.type;
                if (tab.type === WorkSpaceTabTypes.MARTIX_EDITOR && isNewMatrix(tab.content as IMatrixNode)) {
                    component = WorkSpaceTabTypes.NEW_MARTIX_EDITOR;
                }
            } else {
                component = WorkSpaceTabTypes.EDITOR;
            }
            const TabComponent = props.components[component] as React.ComponentClass<TTabComponentProps, any>;
            return {
                label: <WorkspaceTabTitle tab={tab} activeKey={props.activeKey} />,
                key: convertNodeIdToString(tab.nodeId),
                children: <TabComponent tab={tab} />,
            };
        },
        [props.components, props.activeKey],
    );

    const jsxTabs = useMemo(() => {
        return props.tabs.map(transformTabToJsx);
    }, [props.tabs, transformTabToJsx, props.activeKey]);

    return (
        <div className={theme.container} data-test="workspace-tab-name_and_canvas" id={WORKSPACE_CONTAINER_ID}>
            <DnDTabs
                type="editable-card"
                onChange={onChangeTab}
                activeKey={convertNodeIdToString(props.activeKey)}
                onEdit={onTabsEdit}
                jsxTabs={jsxTabs}
                tabs={props.tabs}
            />
        </div>
    );
};
