import { Col, Row, Select, Button, Form } from 'antd';
import React, { useEffect } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useAttributeRules, useUnusedAttributeRules } from '../../../../../../../../hooks/useAttributeRules';
import {
    AttributeTypeStyleRule,
    AttributeTypeStyleRuleTypeEnum,
    AttributeTypeValueTypeEnum,
} from '../../../../../../../../serverapi/api';
import { FormGroup } from '../../../../../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { Icon } from '../../../../../../../UIKit/components/Icon/Icon.component';
import attributeRulesmessages from '../../../../../../../FloatingAttributes/components/AttributesEditor/AttributeRules/AttributeRules.messages';
import icDelete from '../../../../../../../../resources/icons/ic-delete-blue.svg';
import theme from './KanbanCardAttributesEditor.scss';
import { StyledAttributeType } from '../../../../../../../../models/bpm/bpm-model-impl';
import { AttributeRulesInputs } from '../../../../../../../FloatingAttributes/components/AttributesEditor/AttributeRules/AttributeRulesInputs/AttributeRulesInputs.component';
import messages from '../KanbanCardEditor.messages';

type TKanbanCardAttributeRulesSelectorProps = {
    currentAttributeValueType: AttributeTypeValueTypeEnum | undefined;
    rules: AttributeTypeStyleRule[];
    attributeId: string;
    changeAttributeRules: (rules: AttributeTypeStyleRule[]) => void;
    attributeForRulesHook: StyledAttributeType;
    presetId: string;
};

export const KanbanCardAttributeRulesSelector = ({
    currentAttributeValueType,
    rules,
    attributeId,
    changeAttributeRules,
    attributeForRulesHook,
    presetId,
}: TKanbanCardAttributeRulesSelectorProps) => {
    const intl: IntlShape = useIntl();
    const { availableRuleTypes, currentRules } = useAttributeRules(attributeForRulesHook, rules);
    const unusedRules = useUnusedAttributeRules(availableRuleTypes, currentRules);
    const [form] = Form.useForm();

    useEffect(() => {
        form.validateFields();
        const keys = Object.keys(form.getFieldsValue());
        const object = {};
        currentRules.forEach((rule) => {
            if (rule.type && keys.includes(rule.type)) Object.assign(object, { [rule.type]: rule.param });
        });
        form.setFieldsValue(object);
    }, [availableRuleTypes, currentRules, rules]);

    const handleAddRule = () => {
        changeAttributeRules([...currentRules, unusedRules[0]]);
    };

    const handleChangeRuleType = (
        oldRuleType: AttributeTypeStyleRuleTypeEnum | undefined,
        newRuleType: AttributeTypeStyleRuleTypeEnum,
    ) => {
        changeAttributeRules(currentRules.map((rule) => (rule.type === oldRuleType ? { type: newRuleType } : rule)));
    };

    const handleRuleParams = (
        ruleType: AttributeTypeStyleRuleTypeEnum | undefined,
        value1: string,
        value2?: string,
    ) => {
        changeAttributeRules(
            currentRules.map((rule) => {
                if (rule.type !== ruleType) return rule;
                if (!value2) return { type: ruleType, param: value1, param2: rule.param2 };

                return { type: ruleType, param2: value2, param: value1 };
            }),
        );
    };

    const handleRemove = (index: number) => {
        const changedRules = [...currentRules];
        changedRules.splice(index, 1);
        changeAttributeRules(changedRules);
    };

    return (
        <FormGroup>
            <h3>{intl.formatMessage(messages.specifyDisplayConditions)}</h3>
            <Col>
                <Form id={`${attributeId}`} name={`${attributeId}`} form={form}>
                    <Row>
                        {currentRules.map((rule, index) => (
                            <div className={theme.rulesContainer} key={`${rule.type}_${attributeId}`}>
                                <Select
                                    className={theme.ruleSelect}
                                    dropdownClassName={theme.attributeRuleSelectDropdown}
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    showArrow={!(unusedRules.length === 0)}
                                    value={rule.type}
                                    onChange={(newRuleType) => handleChangeRuleType(rule.type, newRuleType)}
                                >
                                    {[rule, ...unusedRules].map((rule) => (
                                        <Select.Option value={rule.type!} key={rule.type}>
                                            {intl.formatMessage(attributeRulesmessages[rule.type!])}
                                        </Select.Option>
                                    ))}
                                </Select>
                                <AttributeRulesInputs
                                    rule={rule}
                                    valueType={currentAttributeValueType}
                                    attributeStyleId={attributeId}
                                    handleRuleParams={handleRuleParams}
                                    presetId={presetId}
                                />
                                <div className={theme.deleteButton}>
                                    <Icon onClick={() => handleRemove(index)} spriteSymbol={icDelete} />
                                </div>
                            </div>
                        ))}
                    </Row>
                </Form>
                <Row>
                    <Button size="small" onClick={handleAddRule} disabled={unusedRules.length === 0}>
                        {intl.formatMessage(messages.addCondition)}
                    </Button>
                </Row>
            </Col>
        </FormGroup>
    );
};
