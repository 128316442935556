export const OPEN_COMMENTS_PANEL = 'OPEN_COMMENTS_PANEL';

export const CLOSE_COMMENTS_PANEL = 'CLOSE_COMMENTS_PANEL';

export const ADD_COMMENTS = 'ADD_COMMENTS';

export const EDIT_COMMENT = 'EDIT_COMMENT';

export const DELETE_COMMENT = 'DELETE_COMMENT';

export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';

export const SAVE_EDITING_COMMENT = 'SAVE_EDITING_COMMENT';

export const SAVE_COMMENT = 'SAVE_COMMENT';

export const LOAD_COMMENTS = 'LOAD_COMMENTS';

export const RELOAD_COMMENTS = 'RELOAD_COMMENTS';

export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';

export const CLEAR_STATE_COMMENTS = 'CLEAR_STATE_COMMENTS';

export const SHOW_COMMENT = 'SHOW_COMMENT';

export const DELETE_COMMENT_MARKERS_BY_GRAPH = 'DELETE_COMMENT_MARKERS_BY_GRAPH';

export const DELETE_COMMENT_MARKER = 'DELETE_COMMENT_MARKER';

export const DRAG_COMMENT_MARKER = 'DRAG_COMMENT_MARKER';

export const SET_DISPLAY_COMMENT_MARKERS = 'SET_DISPLAY_COMMENT_MARKERS';

export const CHANGE_COMMENT_STATUS = 'CHANGE_COMMENT_STATUS';

export const COMMENTS_PANEL_FOCUS = 'COMMENTS_PANEL_FOCUS';

export const PIN_COMMENT = 'PIN_COMMENT';

export const UNPIN_COMMENT = 'UNPIN_COMMENT';

export const PIN_COMMENT_SUCCESS = 'PIN_COMMENT_SUCCESS';

export const UNPIN_COMMENT_SUCCESS = 'UNPIN_COMMENT_SUCCESS';

export const START_EDIT_COMMENT = 'START_EDIT_COMMENT';

export const CHANGE_EDITING_COMMENT = 'CHANGE_EDITING_COMMENT';

export const DELETE_EDITING_COMMENT = 'DELETE_EDITING_COMMENT';

export const SET_CRITICAL_COMMENT = 'SET_CRITICAL_COMMENT';

export const SET_UNCRITICAL_COMMENT = 'SET_UNCRITICAL_COMMENT';

export const SAVE_UPLOADED_FILES = 'SAVE_UPLOADED_FILES';

export const SAVE_UPLOADED_FILES_SUCCESS = 'SAVE_UPLOADED_FILES_SUCCESS';

export const DELETED_UPLOADED_FILE_SUCCESS = 'DELETED_UPLOADED_FILE_SUCCESS';

export const CHANGE_IS_GRAPH_ELEMENT = 'CHANGE_IS_GRAPH_ELEMENT';
