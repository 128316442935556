import {
    EdgeInstance,
    EdgeType,
    ModelEdgeDefinition,
    ModelType,
    NodeId,
    ObjectInstance,
    Symbol,
} from '../../serverapi/api';
import { PictureSymbolConstants } from '../../models/pictureSymbolConstants';
import { UserProfileSelectors } from '../../selectors/userProfile.selectors';
import { getStore } from '../../store';
import { MxCell } from '../../mxgraph/mxgraph';
import { hasOnlyComments } from '../bll/BpmMxEditorBLLService';
import { ObjectDefinitionImpl } from '../../models/bpm/bpm-model-impl';
import { ObjectDefinitionSelectors } from '../../selectors/objectDefinition.selectors';
import { isComplexSymbolChangableStyle } from '@/mxgraph/ComplexSymbols/ComplexSymbol.utils';
import { BPMMxConstants } from '@/mxgraph/bpmgraph.constants';

export class NotationHelper {
    static getSymbolById(modelType: ModelType, id: string): Symbol | null {
        const res = modelType.symbols.find((e) => e.id === id);

        return res || null;
    }

    static getEdgeTypeByName(modelType: ModelType | undefined, id: string): EdgeType | null {
        if (!modelType) {
            return null;
        }
        const res = modelType.edgeTypes.find((e) => e.id === id);

        return res || null;
    }

    static getObjectTypeId(source: ObjectInstance, modelType: ModelType) {
        const symbol = modelType.symbols.find((s) => s.id === source.symbolId);

        return symbol?.objectType;
    }

    static isEdgeTypeCreateable = (serverId: string, presetId: string, edgeType: string): boolean => {
        const state = getStore().getState();
        const isEdgeTypeCreateable = UserProfileSelectors.isEdgeTypeCreateable(serverId, presetId, edgeType)(state);

        return isEdgeTypeCreateable;
    };

    static isEdgeTypeEditable = (serverId: string, presetId: string, edgeType: string): boolean => {
        const state = getStore().getState();
        const isEdgeTypeEditable = UserProfileSelectors.isEdgeTypeEditable(serverId, presetId, edgeType)(state);

        return isEdgeTypeEditable;
    };

    static isObjectDefinitionEditable(nodeId: NodeId): boolean {
        const state = getStore().getState();
        const objectDefinition: ObjectDefinitionImpl | undefined = ObjectDefinitionSelectors.byId(nodeId)(state);

        return UserProfileSelectors.isSymbolEditable(nodeId, objectDefinition?.idSymbol)(state);
    }

    static isEntityEditable(nodeId: NodeId, presetId: string, cells: MxCell[]) {
        const { serverId, repositoryId } = nodeId;

        return (
            !hasOnlyComments(cells) &&
            cells
                .filter((cell: MxCell) => !isComplexSymbolChangableStyle(cell.style))
                .some((cell: MxCell) => {
                    const value = cell.getValue();

                    if (!value) {
                        return false;
                    }

                    if (cell.isEdge()) {
                        return this.isEdgeTypeEditable(serverId, presetId, value.edgeTypeId);
                    }

                    if (cell.isVertex()) {
                        return this.isObjectDefinitionEditable({
                            id: value.objectDefinitionId,
                            repositoryId,
                            serverId,
                        });
                    }

                    return false;
                })
        );
    }

    static getEdgeTypeBySourceAndDestination(
        src: ObjectInstance,
        dst: ObjectInstance,
        serverId: string,
        modelType?: ModelType,
    ): (EdgeType | null)[] {
        if (!modelType) {
            return [];
        }
        const srcOTypeId = this.getObjectTypeId(src, modelType);
        const dstOTypeId = this.getObjectTypeId(dst, modelType);
        const forwardEdgeDefinitions = modelType.modelEdgeDefinitions
            .filter((modelEdgeDefinition) => {
                const correctDirection = this.checkEdgeTypeDirection(
                    modelEdgeDefinition,
                    src.symbolId,
                    dst.symbolId,
                    srcOTypeId,
                    dstOTypeId,
                );
                const isEdgeTypeCreateable = this.isEdgeTypeCreateable(
                    serverId,
                    modelType.presetId,
                    modelEdgeDefinition.edgeType,
                );

                return correctDirection && isEdgeTypeCreateable;
            })
            .map((e) => this.getEdgeTypeByName(modelType, e.edgeType));

        const reverseEdgeDefinitions = modelType.modelEdgeDefinitions
            .filter((e) => this.checkEdgeTypeDirection(e, dst.symbolId, src.symbolId, dstOTypeId, srcOTypeId))
            .map((e) => this.getEdgeTypeByName(modelType, e.edgeType))
            .filter((e) => e !== null && (e.direction === 'NO_DIRECTION' || e.direction === 'BIDIRECTIONAL'));

        return Array.from(new Set([...forwardEdgeDefinitions, ...reverseEdgeDefinitions]));
    }

    static getEdgeTypeBySource(src: ObjectInstance, serverId: string, modelType?: ModelType): EdgeType[] {
        if (!modelType) {
            return [];
        }
        const srcOTypeId = this.getObjectTypeId(src, modelType);
        const forwardEdgeDefinitions: EdgeType[] = [];
        modelType.modelEdgeDefinitions.forEach((modelEdgeDefinition) => {
            const isEdgeTypeCreateable = this.isEdgeTypeCreateable(
                serverId,
                modelType.presetId,
                modelEdgeDefinition.edgeType,
            );
            if (!isEdgeTypeCreateable) return;

            const edgeType = this.getEdgeTypeByName(modelType, modelEdgeDefinition.edgeType);

            const isEdgeTypeAvailble = this.isEdgeTypeAvailble(modelEdgeDefinition, src.symbolId, srcOTypeId, edgeType);

            if (edgeType && isEdgeTypeAvailble) forwardEdgeDefinitions.push(edgeType);
        });

        return Array.from(new Set([...forwardEdgeDefinitions]));
    }

    /**
     * @returns {string} with error message if connection between the given objects
     * is not legal or null if connection is possible.
     */
    static validateEdge(
        source: ObjectInstance,
        destination: ObjectInstance,
        modelType: ModelType,
        serverId: string,
        currentEdgeType?: EdgeType,
    ): string | null {
        if (!source || !destination || !modelType) {
            return `Connection between undefined object(s) is not supported. source
            is ${source}, destination is ${destination}, modelType is ${modelType}`;
        }

        if (source.type === 'edge' || destination.type === 'edge') {
            return `Source or target are not object. Connection supported only between two objects`;
        }

        if (!source.symbolId || !destination.symbolId) {
            return null;
        }
        const srcSymbol = this.getSymbolById(modelType, source.symbolId);
        const dstSymbol = this.getSymbolById(modelType, destination.symbolId);

        const srcObject = this.getObjectTypeId(source, modelType);
        const dstObject = this.getObjectTypeId(destination, modelType);

        const isPictureSymbolLink: boolean =
            source.symbolId === PictureSymbolConstants.PICTURE_SYMBOL_ID ||
            destination.symbolId === PictureSymbolConstants.PICTURE_SYMBOL_ID;
        if (isPictureSymbolLink) {
            return null;
        }

        const meds = modelType.modelEdgeDefinitions.filter((med) => {
            const isEdgeTypeCreateable = this.isEdgeTypeCreateable(serverId, modelType.presetId, med.edgeType);

            return isEdgeTypeCreateable;
        });

        if (!meds.length) {
            return 'You do not have rights to any type of connection that can be made between these objects';
        }

        if (currentEdgeType && currentEdgeType?.id !== BPMMxConstants.AUTO_EDGE_TYPE_ID) {
            const avalibleEdgeTypes = NotationHelper.getEdgeTypeBySourceAndDestination(
                source,
                destination,
                serverId,
                modelType,
            );
            const isAvalibleEdgeType = avalibleEdgeTypes.some((edgeType) => edgeType?.id === currentEdgeType.id);

            return isAvalibleEdgeType
                ? null
                : `Edge type ${currentEdgeType.name} not supported between ${srcSymbol && srcSymbol.name} and ${
                      dstSymbol && dstSymbol.name
                  } or between objects ${srcObject} and ${dstObject}`;
        }

        if (srcSymbol || (srcObject && dstSymbol) || dstObject) {
            const validEdgeType = meds.find((med) => {
                const edgeType = NotationHelper.getEdgeTypeByName(modelType, med.edgeType);

                if (!edgeType) {
                    return false;
                }

                if (this.checkEdgeTypeDirection(med, srcSymbol?.id, dstSymbol?.id, srcObject, dstObject)) {
                    return true;
                }

                if (edgeType.direction !== 'BIDIRECTIONAL' && edgeType.direction !== 'NO_DIRECTION') {
                    return false;
                }

                return this.checkEdgeTypeDirection(med, dstSymbol?.id, srcSymbol?.id, srcObject, dstObject);
            });

            return validEdgeType
                ? null
                : `Connection not supported between ${srcSymbol && srcSymbol.name} and ${
                      dstSymbol && dstSymbol.name
                  } or between objects ${srcObject} and ${dstObject}`;
        }

        return `Connection between undefined symbol(s) or object(s) is not supported. srcSymbol is ${srcSymbol}, dstSymbol is ${dstSymbol} srcObject is ${srcObject}, dstObject is ${dstObject}`;
    }

    static validateEdgeReconnect(edgeInstance: EdgeInstance) {
        return edgeInstance?.edgeDefinitionId ? 'Unavailable connection for edge with definition' : null;
    }

    static checkEdgeTypeDirection(
        modelEdgeDefinition: ModelEdgeDefinition,
        srcSymbol?: string,
        dstSymbol?: string,
        sourceObjectTypeId?: string,
        destObjectTypeId?: string,
    ): boolean {
        const src =
            modelEdgeDefinition.anySourceAllowed ||
            modelEdgeDefinition.source === srcSymbol ||
            modelEdgeDefinition.sourceObject === sourceObjectTypeId;
        const dst =
            modelEdgeDefinition.anyTargetAllowed ||
            modelEdgeDefinition.destination === dstSymbol ||
            modelEdgeDefinition.destinationObject === destObjectTypeId;

        return src && dst;
    }

    static isEdgeTypeAvailble(
        modelEdgeDefinition: ModelEdgeDefinition,
        srcSymbol?: string,
        sourceObjectTypeId?: string,
        edgeType?: EdgeType | null,
    ): boolean {
        return (
            modelEdgeDefinition.anySourceAllowed ||
            modelEdgeDefinition.source === srcSymbol ||
            modelEdgeDefinition.sourceObject === sourceObjectTypeId ||
            ((edgeType?.direction === 'NO_DIRECTION' || edgeType?.direction === 'BIDIRECTIONAL') &&
                (modelEdgeDefinition.destination === srcSymbol ||
                    modelEdgeDefinition.destinationObject === sourceObjectTypeId))
        );
    }
}
