import { TObjectTypesForLink } from '@/services/bll/ExternalLinkBLLService.types';
import { COPY_ITEM_LINK } from '../actionsTypes/modelLink.actionTypes';
import { NodeId } from '../serverapi/api';
import { TCopyLinkAction } from './copyLink.actions.types';

export const copyLinkAction = (nodeId: NodeId, type: TObjectTypesForLink, elementId?: string): TCopyLinkAction => ({
    type: COPY_ITEM_LINK,
    payload: {
        nodeId,
        type,
        elementId,
    },
});
