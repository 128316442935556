import { TReducer } from '../../utils/types';
import {
    OBJECT_DEFINITIONS_ADD,
    OBJECT_DEFINITIONS_ADD_ONLY_NEW,
    OBJECT_DEFINITION_DELETE,
    OBJECT_DEFINITION_UML_UPDATE,
} from '../../actionsTypes/entities/objectDefinition.actionTypes';
import { ObjectDefinitionImpl } from '../../models/bpm/bpm-model-impl';
import { TNodeState } from './TNodeState';
import { ModelAssignment, ObjectDefinitionNode } from '../../serverapi/api';
import { APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM } from '../../actionsTypes/app.actionTypes';
import { TObjectDefinitionState } from './objectDefinition.reducer.types';
import { getNodeWithChildrenFromNodes } from '../../services/utils/treeService.utils';
import { UML_OBJECT_TYPE } from '../../mxgraph/ComplexSymbols/symbols/UML/UMLSymbols.constants';

const INITIAL_OBJECT_STATE = new TNodeState() as TObjectDefinitionState;

const sortModelAssigment = (a: ModelAssignment, b: ModelAssignment) =>
    a.modelName && b.modelName && a.modelName.toLowerCase() !== b.modelName.toLowerCase()
        ? a.modelName > b.modelName
            ? 1
            : -1
        : 0;

export const objectDefinitionReducer: TReducer<TObjectDefinitionState> = (state = INITIAL_OBJECT_STATE, action) => {
    switch (action.type) {
        case OBJECT_DEFINITION_UML_UPDATE: {
            const { objectDefinitions } = action.payload;
            let newState = state.cloneByNodeId();

            const umlClassObject = objectDefinitions.find((o) => o.objectTypeId === UML_OBJECT_TYPE.CLASS);
            if (!umlClassObject) {
                return state;
            }
            const objectsToDelete = getNodeWithChildrenFromNodes(umlClassObject.nodeId, newState.values());
            objectsToDelete.forEach((object) => {
                newState = newState.delete(object.nodeId);
            });
            objectDefinitions.forEach((od) => {
                od.modelAssignments && od.modelAssignments.sort(sortModelAssigment);
                newState.byNodeId.set(od.nodeId, od);
            });

            return newState;
        }

        case OBJECT_DEFINITIONS_ADD: {
            const { objectDefinitions } = action.payload;
            const newState = state.cloneByNodeId();
            objectDefinitions.forEach((od) => {
                od.modelAssignments && od.modelAssignments.sort(sortModelAssigment);
                newState.byNodeId.set(od.nodeId, od);
            });

            return newState;
        }
        case OBJECT_DEFINITION_DELETE: {
            const { nodeId } = action.payload;

            return state.delete(nodeId);
        }
        case APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM: {
            const {
                payload: { definition },
            } = action;

            if (definition?.objects) {
                const objectDefinitions: ObjectDefinitionNode[] = definition.objects;
                const newState = state.cloneByNodeId();
                objectDefinitions.forEach((od) => newState.byNodeId.set(od.nodeId, od as ObjectDefinitionImpl));

                return newState;
            }

            return state;
        }
        case OBJECT_DEFINITIONS_ADD_ONLY_NEW: {
            const { objectDefinitions } = action.payload;
            const newState = state.cloneByNodeId();

            objectDefinitions.forEach((od) => {
                if (!state.get(od.nodeId)) {
                    newState.byNodeId.set(od.nodeId, od);
                }
            });

            return newState;
        }
        default:
            return state;
    }
};
