import React from 'react';
import { NavigatorPanelButton } from '../../NavigatorPanel/NavigatorPanelButton/NavigatorPanelButton.component';
import { SeachIcon } from '../../NavigatorPanel/icons/SearchIcon.component';
import { useDispatch, useSelector } from 'react-redux';
import { chamgeNavigatorTreeSeachStatus } from '../../../../../actions/navigatorTreeSearch.actions';
import { NavigatorTreeSearchSelector } from '../../../../../selectors/navigatorTreeSearch.selectors';

export const TreeSearch = () => {
    const dispatch = useDispatch();
    const isActive: boolean = useSelector(NavigatorTreeSearchSelector.getIsSearchActive);
    const handleClick = () => {
        dispatch(chamgeNavigatorTreeSeachStatus(isActive ? false : true));
    };

    return <NavigatorPanelButton IconComponent={SeachIcon} active={isActive} onClick={handleClick} />;
};
