import React, { useState, useEffect, useRef, useCallback } from 'react';
import theme from './CommentElement.scss';
import messages from '../messages/CommentsPanel.messages';
import circleCheck from '../../../resources/icons/circle-check.svg';
import ellipsis from '../../../resources/icons/ellipsis.svg';
import { Icon } from '../../UIKit';
import { Comment, NodeId } from '../../../serverapi/api';
import { TWorkspaceTab } from '../../../models/tab.types';
import { CommentFilterStatus, CommentMenuActions, CommentStatus } from '../../../models/commentMarkerConstants';
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { useIntl } from 'react-intl';
import { MenuInfo } from 'rc-menu/lib/interface';
import { getMenuItem } from '../../../utils/antdMenuItem.utils';
import { getAntdPopupContainer } from '../../../utils/getAntdPopupContainer';
import { CommentNameHeader } from './CommentNameHeader.component';
import { NewCommentArea } from './NewCommentArea/NewCommentArea.component';
import {
    changeCommentStatus,
    setCriticalCommentAction,
    setUncriticalCommentAction,
} from '../../../actions/comments.actions';
import { useDispatch, useSelector } from 'react-redux';
import { showNotificationByType } from '../../../actions/notification.actions';
import { NotificationType } from '../../../models/notificationType';
import { CommentElementUploadedFilesArea } from './CommentElementUploadedFilesArea/CommentElementUploadedFilesArea';
import { getUser } from '../../../selectors/authorization.selectors';
import { CommentsSelectors } from '../../../selectors/comments.selectors';
import { SizeModeEnum } from './Comments.types';
import { TComment } from '@/reducers/comments.reducer.types';
import { getActiveGraph } from '@/selectors/editor.selectors';
import { compareNodeIds } from '@/utils/nodeId.utils';
import cx from 'classnames';

type TCommentElementProps = {
    comment: Comment;
    scheme: TWorkspaceTab;
    answers?: Comment[];
    filterStatus?: CommentFilterStatus;
    isMarkersEnabled?: boolean;
    answerMargin?: number;
    threadId?: string;
    deleteComment: (modelId: NodeId, commentId: NodeId, isAnswer?: true) => void;
    createMarker?: (comment: Comment, isMarkerDragging?: boolean) => void;
    onChangeMarkersShow?: (isShow: boolean) => void;
    pinComment?: (modelId: NodeId, comment: Comment) => void;
    unpinComment?: (modelId: NodeId, comment: Comment) => void;
};

export const CommentElement = (props: TCommentElementProps) => {
    const {
        comment,
        answers,
        scheme,
        filterStatus,
        isMarkersEnabled,
        answerMargin,
        deleteComment,
        createMarker,
        onChangeMarkersShow,
        pinComment,
        unpinComment,
    } = props;

    const editingComment: TComment | undefined = useSelector(CommentsSelectors.getEditingComment(scheme.nodeId));
    const username: string | undefined = useSelector(getUser)?.login;
    const isAuthor: boolean = comment.author === username;
    const activeGraphId: NodeId | undefined = useSelector(getActiveGraph);
    const [focus, setFocus] = useState<boolean>(false);
    const [commentMenuOpen, setCommentMenuOpen] = useState<boolean>(false);
    const [fullSizeMode, setFullSizeMode] = useState<SizeModeEnum>(SizeModeEnum.DEFAULT);
    const [filesFullSizeMode, setFilesFullSizeMode] = useState<SizeModeEnum>(SizeModeEnum.DEFAULT);
    const [isShowAnswerArea, setIsShowAnswerArea] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const tooltip = useSelector(CommentsSelectors.getTooltip(scheme.nodeId));

    const commentsContainerRef: React.RefObject<any> = useRef();
    const dispatch = useDispatch();
    const filesContainerRef: React.RefObject<any> = useRef();
    const intl = useIntl();

    const toggleCommentMenuOpen = useCallback(() => {
        setCommentMenuOpen((prevOpen) => !prevOpen);
    }, []);

    useEffect(() => {
        const height: number = commentsContainerRef.current?.clientHeight;
        const childHeight: number = commentsContainerRef.current?.childNodes[0].offsetHeight;

        if (childHeight > height) {
            setFullSizeMode(SizeModeEnum.COLLAPSED);
        } else if (fullSizeMode !== SizeModeEnum.DEFAULT || childHeight === height) {
            setFullSizeMode(SizeModeEnum.DEFAULT);
        }
    }, [comment, isEditMode]);

    useEffect(() => {
        if (editingComment?.commentId === comment?.commentId.id) {
            setIsEditMode(!!editingComment);
        }
    }, []);

    useEffect(() => {
        if (
            !answers?.find((a) => a.commentId.id === editingComment?.commentId) &&
            comment?.commentId.id === editingComment?.parentId &&
            comment?.commentId.id !== editingComment?.commentId
        ) {
            setIsShowAnswerArea(true);
        }
    }, []);

    useEffect(() => {
        if (compareNodeIds(activeGraphId, scheme.nodeId) && tooltip) {
            setIsEditMode(false);
            setIsShowAnswerArea(false);
        }
    }, [activeGraphId]);

    useEffect(() => {
        const height: number = filesContainerRef.current?.clientHeight;
        let sum = 0;

        filesContainerRef.current?.childNodes.forEach((c: HTMLElement) => {
            sum += c.offsetHeight;
        });
        if (sum - height > 5) {
            // Свернуть список с файлами и показать ссылку "Смотреть все файлы"
            setFilesFullSizeMode(SizeModeEnum.COLLAPSED);
        } else if (filesFullSizeMode !== SizeModeEnum.DEFAULT) {
            // Развернуть список с файлами и показать ссылку "Свернуть"
            setFilesFullSizeMode(SizeModeEnum.FULL_SIZE);
        }
        if (!comment.commentFiles || comment.commentFiles.length <= 2) {
            // Если файлов <= 2 убрать ссылку
            setFilesFullSizeMode(SizeModeEnum.DEFAULT);
        }
    }, [comment.commentFiles, isEditMode]);

    const changeWriteAnswerStatus = (isWriting: boolean) => {
        setIsShowAnswerArea(isWriting);
    };

    const answerArea = (
        <div>
            {!editingComment && !isShowAnswerArea && (
                <div
                    data-test="user-comment-answer"
                    className={theme.answerContainer}
                    onClick={() => changeWriteAnswerStatus(true)}
                >
                    {intl.formatMessage(messages.answerLink)}
                </div>
            )}
            {isShowAnswerArea && (
                <div style={{ marginLeft: `-${20 + (answerMargin || 0)}px` }} className={theme.answerArea}>
                    <NewCommentArea
                        isAnswer
                        onlyFullSizeMode
                        comment={comment}
                        modelId={scheme.nodeId}
                        onChangeVisible={() => changeWriteAnswerStatus(false)}
                    />
                </div>
            )}
        </div>
    );

    const onChangeCommentStatus = () => {
        const newCommentStatus: CommentStatus =
            comment.commentStatus === CommentStatus.open ? CommentStatus.close : CommentStatus.open;
        dispatch(changeCommentStatus(scheme.nodeId, comment.commentId, newCommentStatus));
    };

    const onChangeEditMode = (newStatus: boolean) => {
        setIsEditMode(newStatus);
    };

    const addMarker = () => {
        if (!isAuthor) dispatch(showNotificationByType(NotificationType.NO_AUTHOR_ADD_MARKER));
        if (scheme.type !== 'Editor' || !isAuthor) return;
        if (onChangeMarkersShow && !isMarkersEnabled) {
            onChangeMarkersShow(true);
        }
        if (createMarker) {
            createMarker(comment, true);
        }
    };

    const moveToMarker = () => {
        if (createMarker) {
            createMarker(comment);
        }
    };

    const handleChangeAddStatus = () => {
        if (comment.commentAddStatus === 'CRITICAL') {
            dispatch(setUncriticalCommentAction(scheme.nodeId, comment.commentId));
        } else {
            dispatch(setCriticalCommentAction(scheme.nodeId, comment.commentId));
        }
    };

    const commentMenuAction = (param: MenuInfo) => {
        switch (param.key) {
            case CommentMenuActions.commentMarkerAdd:
                addMarker();
                break;
            case CommentMenuActions.commentEdit:
                onChangeEditMode(true);
                break;
            case CommentMenuActions.commentDelete:
                deleteComment(scheme.nodeId, comment.commentId);
                break;
            case CommentMenuActions.commentPin:
                pinComment?.(scheme.nodeId, comment);
                break;
            case CommentMenuActions.commentUnpin:
                unpinComment?.(scheme.nodeId, comment);
                break;
            case CommentMenuActions.critical:
                handleChangeAddStatus();
                break;
            // no default
        }

        setCommentMenuOpen(false);
    };

    const pinMenuItem = comment.pinDate
        ? getMenuItem(
              <div data-test="unpin-comment_btn" className={theme.popupItem}>
                  {intl.formatMessage(messages.unpinComment)}
              </div>,
              CommentMenuActions.commentUnpin,
          )
        : getMenuItem(
              <div data-test="pin-comment_btn" className={theme.popupItem}>
                  {' '}
                  {intl.formatMessage(messages.pinComment)}
              </div>,
              CommentMenuActions.commentPin,
          );
    const pinStatusCommentButton = !comment.parentId ? pinMenuItem : null;

    const setCommentAddStatus =
        comment.commentAddStatus === 'CRITICAL'
            ? getMenuItem(
                  <div data-test="uncritical-comment_btn" className={theme.popupItem}>
                      {intl.formatMessage(messages.uncritical)}
                  </div>,
                  CommentMenuActions.critical,
                  !isAuthor,
              )
            : getMenuItem(
                  <div data-test="critical-comment_btn" className={theme.popupItem}>
                      {intl.formatMessage(messages.critical)}
                  </div>,
                  CommentMenuActions.critical,
                  !isAuthor,
              );

    const items: MenuProps['items'] = [
        pinStatusCommentButton,
        getMenuItem(
            <div data-test="add-comment-marker_btn" className={theme.popupItem}>
                {comment.isGraphElement
                    ? intl.formatMessage(messages.showMarker)
                    : intl.formatMessage(messages.addMarker)}
            </div>,
            CommentMenuActions.commentMarkerAdd,
            !isAuthor,
        ),
        setCommentAddStatus,
        getMenuItem(
            <div data-test="edit-comment_btn" className={theme.popupItem}>
                {intl.formatMessage(messages.edit)}
            </div>,
            CommentMenuActions.commentEdit,
            !isAuthor || !!editingComment,
        ),
        getMenuItem(
            <div data-test="delete-comment_btn" className={theme.popupItem}>
                {intl.formatMessage(messages.delete)}
            </div>,
            CommentMenuActions.commentDelete,
        ),
    ];

    const deleteAnswerComment = (modelId: NodeId, commentId: NodeId) => deleteComment(modelId, commentId, true);

    return (
        <div
            key={comment.commentId.id}
            className={`${theme.commentContainer} ${comment.commentStatus === 'CLOSE' ? theme.closeComment : ''}`}
            data-test={`user-comment-container_${username}`}
        >
            <div id={comment.commentId.id}>
                {isEditMode ? (
                    <div
                        style={{
                            paddingLeft: `${answerMargin ? answerMargin - 16 : 0}px`,
                        }}
                        className={cx({ [theme.dashBorder]: !!comment.parentId }, theme.answerArea)}
                    >
                        <NewCommentArea
                            onlyFullSizeMode
                            modelId={scheme.nodeId}
                            comment={comment}
                            onFinishEditing={() => onChangeEditMode(false)}
                            onChangeVisible={() => onChangeEditMode(false)}
                        />
                    </div>
                ) : (
                    <div
                        style={{
                            paddingLeft: `${answerMargin || 16}px`,
                            paddingTop: '16px',
                            backgroundColor: 'inherit',
                        }}
                        data-test="user-comment-body"
                        onMouseEnter={() => setFocus(true)}
                        onMouseLeave={() => setFocus(false)}
                        className={cx({ [theme.dashBorder]: !!comment.parentId })}
                    >
                        <div className={theme.commentHeaderContainer} data-test="user-comment_header">
                            <CommentNameHeader
                                className={theme.commentNameHeaderContainer}
                                modelId={scheme.nodeId}
                                commentId={comment.commentId.id}
                                withDate
                                addMarker={addMarker}
                                moveToMarker={moveToMarker}
                            />
                            {(focus || commentMenuOpen) && (
                                <div className={theme.checkIconContainer}>
                                    <div className={theme.iconContainer}>
                                        <Icon
                                            spriteSymbol={circleCheck}
                                            className={theme.circleCheckIcon}
                                            onClick={onChangeCommentStatus}
                                            dataTest="user-comment_check"
                                        />
                                    </div>
                                    <Dropdown
                                        menu={{ onClick: commentMenuAction, items }}
                                        trigger={['click']}
                                        getPopupContainer={getAntdPopupContainer}
                                        overlayClassName={theme.ellipsisOverlay}
                                        open={commentMenuOpen}
                                        onOpenChange={toggleCommentMenuOpen}
                                        destroyPopupOnHide
                                    >
                                        <div
                                            className={`${theme.iconContainer} ${
                                                commentMenuOpen ? theme.ellipsisIconActive : ''
                                            }`}
                                        >
                                            <Icon
                                                spriteSymbol={ellipsis}
                                                className={theme.ellipsisIcon}
                                                dataTest="user-comment_options"
                                            />
                                        </div>
                                    </Dropdown>
                                </div>
                            )}
                        </div>
                        <div
                            ref={commentsContainerRef}
                            className={
                                fullSizeMode === SizeModeEnum.FULL_SIZE || isEditMode
                                    ? theme.textCommentContainerFullSize
                                    : theme.textCommentContainer
                            }
                            data-test="user-comment_text"
                        >
                            <div className={theme.textComment}>{comment.text}</div>
                        </div>
                        {fullSizeMode === SizeModeEnum.COLLAPSED && (
                            <div className={theme.moreButton} onClick={() => setFullSizeMode(SizeModeEnum.FULL_SIZE)}>
                                {intl.formatMessage(messages.openText)}
                            </div>
                        )}
                        {fullSizeMode === SizeModeEnum.FULL_SIZE && (
                            <div className={theme.moreButton} onClick={() => setFullSizeMode(SizeModeEnum.COLLAPSED)}>
                                {intl.formatMessage(messages.closeText)}
                            </div>
                        )}
                        <CommentElementUploadedFilesArea
                            filesContainerRef={filesContainerRef}
                            filesFullSizeMode={filesFullSizeMode}
                            isEditMode={isEditMode}
                            comment={comment}
                            setFilesFullSizeMode={setFilesFullSizeMode}
                        />
                        {answerArea}
                    </div>
                )}
            </div>

            <div
                className={!comment.parentId && answers?.length ? theme.answersContainer : ''}
                data-test="comment-element_answers-container"
            >
                {answers
                    ?.filter((answer: Comment) => answer.parentId === comment.commentId.id)
                    ?.map((answer: Comment) => (
                        <CommentElement
                            key={`comment-element_${answer.threadId}_${answer.commentId.id}`}
                            comment={{ ...answer, commentStatus: answer.commentStatus || comment.commentStatus }}
                            scheme={scheme}
                            answers={answers}
                            filterStatus={filterStatus}
                            isMarkersEnabled={isMarkersEnabled}
                            answerMargin={(answerMargin || 0) + 16}
                            deleteComment={deleteAnswerComment}
                            createMarker={createMarker}
                            onChangeMarkersShow={onChangeMarkersShow}
                        />
                    ))}
            </div>
        </div>
    );
};
