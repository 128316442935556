import React, { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { TPreset } from '../../../../../models/preset.types';
import { NodeId } from '../../../../../serverapi/api';
import messages from '../../messages/MethodologySetting.messages';
import theme from './MethodologySetting.scss';
import { getCurrentLocale } from '../../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../../services/LocalesService';
import { MethodologySettingActionsButton } from './MethodologySettingActionsButton.component';
import { TreeNode } from '../../../../../models/tree.types';
import {
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    Column,
    SortDirectionType,
    Table,
    TableCellProps,
} from 'react-virtualized';
import { useCloseDropdownOnScroll } from './useCloseDropdownOnScroll';
import { EllipsisText } from './EllipsisText/EllipsisText.component';
import { SortIndicator } from './SortIndicator.component';
import { Icon } from '../../../../UIKit';
import noData from '../../../../../resources/icons/noData.svg';
import { sortAlphabet } from './MethodologySettingsTable.utils';

type TMethodologySettingTableProps = {
    presets: TPreset[];
    serverNode: TreeNode;
    nodeId: NodeId;
};

export const MethodologySettingTable = memo((props: TMethodologySettingTableProps) => {
    const { presets, serverNode, nodeId } = props;
    const currentLocale = useSelector(getCurrentLocale);
    const intl = useIntl();
    const { closedAllMenu } = useCloseDropdownOnScroll();
    const [sortDirectionState, setSortDirectionState] = useState<SortDirectionType>('DESC');
    const [sortByState, setSortByState] = useState<string>('updatedAt');

    const sortByUpdatedAt = () =>
        presets.sort((a, b) => {
            if (sortDirectionState === 'ASC') {
                return (a?.updatedAt || 0) > (b?.updatedAt || 0) ? 1 : -1;
            }

            return (a?.updatedAt || 0) < (b?.updatedAt || 0) ? 1 : -1;
        });

    const sortedList = useMemo(() => {
        if (sortByState === 'updatedAt') {
            return sortByUpdatedAt();
        }

        if (sortByState === 'id' || sortByState === 'description') {
            return presets.sort((a, b) => {
                const firstStr = sortDirectionState === 'ASC' ? a?.[sortByState] || '' : b?.[sortByState] || '';
                const secondStr = sortDirectionState === 'ASC' ? b?.[sortByState] || '' : a?.[sortByState] || '';

                return firstStr.localeCompare(secondStr, currentLocale);
            });
        }

        if (sortByState === 'repositories') {
            return presets.sort((a, b) => {
                const firstMultilingualName =
                    sortDirectionState === 'ASC'
                        ? a?.repositories[0]?.multilingualName
                        : b?.repositories[0]?.multilingualName;
                const secondMultilingualName =
                    sortDirectionState === 'ASC'
                        ? b?.repositories[0]?.multilingualName
                        : a?.repositories[0]?.multilingualName;

                return LocalesService.internationalStringToString(firstMultilingualName, currentLocale).localeCompare(
                    LocalesService.internationalStringToString(secondMultilingualName),
                    currentLocale,
                );
            });
        }

        if (sortByState === 'multilingualName') {
            return presets.sort((a, b) => {
                const firstMultilingualName = sortDirectionState === 'ASC' ? a?.multilingualName : b?.multilingualName;
                const secondMultilingualName = sortDirectionState === 'ASC' ? b?.multilingualName : a?.multilingualName;

                return LocalesService.internationalStringToString(firstMultilingualName, currentLocale).localeCompare(
                    LocalesService.internationalStringToString(secondMultilingualName),
                    currentLocale,
                );
            });
        }

        return presets;
    }, [presets, sortDirectionState, sortByState]);

    const sort = ({ sortBy, sortDirection }) => {
        if (!sortBy) return;

        setSortByState(sortBy);
        setSortDirectionState(sortDirection);
    };

    const cellHeightCache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 64,
    });

    const multilingualNameColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const multilingualName = presets[rowIndex][dataKey];

        return (
            <CellMeasurer columnIndex={0} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <EllipsisText text={LocalesService.internationalStringToString(multilingualName, currentLocale)} />
            </CellMeasurer>
        );
    };

    const repositoriesColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const repositories = presets[rowIndex][dataKey];
        const sortedRepositories = repositories ? repositories.sort(sortAlphabet) : [];

        return (
            <CellMeasurer columnIndex={1} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <EllipsisText text={LocalesService.internationalStringArrayToString(sortedRepositories)} />
            </CellMeasurer>
        );
    };

    const descriptionColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const description = presets[rowIndex][dataKey];

        return (
            <CellMeasurer columnIndex={2} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <EllipsisText text={description} />
            </CellMeasurer>
        );
    };

    const idColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const presetId = presets[rowIndex][dataKey];

        return (
            <CellMeasurer columnIndex={3} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                {presetId}
            </CellMeasurer>
        );
    };

    const updatedAtColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const updatedAt = presets[rowIndex][dataKey];

        return (
            <CellMeasurer columnIndex={4} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                {updatedAt ? dayjs(new Date(updatedAt)).format('DD.MM.YYYY HH:mm') : ''}
            </CellMeasurer>
        );
    };

    const buttonsColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const repositories = presets[rowIndex][dataKey];

        return (
            <CellMeasurer columnIndex={5} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <MethodologySettingActionsButton
                    repositories={repositories}
                    record={presets[rowIndex]}
                    serverNode={serverNode}
                    nodeId={nodeId}
                    isClosedAllMenu={closedAllMenu}
                />
            </CellMeasurer>
        );
    };

    const headerRenderer = ({ label, dataKey, sortBy, sortDirection }) => {
        return (
            <div>
                {label}
                {sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
            </div>
        );
    };

    return (
        <div className={theme.tableWrapper}>
            <div className={sortedList.length ? theme.tableContainer : theme.emptyTableContainer}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            width={width}
                            height={height}
                            headerHeight={48}
                            rowHeight={cellHeightCache.rowHeight}
                            rowCount={sortedList.length}
                            rowGetter={({ index }) => sortedList[index]}
                            rowClassName={theme.tableRow}
                            sort={sort}
                            sortBy={sortByState}
                            sortDirection={sortDirectionState}
                        >
                            <Column
                                label={intl.formatMessage(messages.methodologyName)}
                                dataKey="multilingualName"
                                width={width}
                                minWidth={250}
                                className={theme.cellContent}
                                headerRenderer={headerRenderer}
                                cellRenderer={multilingualNameColumnRenderer}
                                headerClassName={theme.columnHeader}
                            />
                            <Column
                                label={intl.formatMessage(messages.db)}
                                width={width}
                                minWidth={250}
                                dataKey="repositories"
                                className={theme.cellContent}
                                headerRenderer={headerRenderer}
                                cellRenderer={repositoriesColumnRenderer}
                                headerClassName={theme.columnHeader}
                            />
                            <Column
                                label={intl.formatMessage(messages.methodologyDescription)}
                                width={width}
                                minWidth={250}
                                dataKey="description"
                                className={theme.cellContent}
                                headerRenderer={headerRenderer}
                                cellRenderer={descriptionColumnRenderer}
                                headerClassName={theme.columnHeader}
                            />
                            <Column
                                label={intl.formatMessage(messages.id)}
                                width={width}
                                minWidth={150}
                                maxWidth={300}
                                dataKey="id"
                                className={theme.cellContent}
                                headerRenderer={headerRenderer}
                                cellRenderer={idColumnRenderer}
                                headerClassName={theme.columnHeader}
                            />
                            <Column
                                label={intl.formatMessage(messages.modDate)}
                                width={150}
                                minWidth={150}
                                maxWidth={150}
                                dataKey="updatedAt"
                                className={theme.cellContent}
                                headerRenderer={headerRenderer}
                                cellRenderer={updatedAtColumnRenderer}
                                headerClassName={theme.columnHeader}
                            />
                            <Column
                                width={20}
                                minWidth={20}
                                maxWidth={20}
                                dataKey=""
                                className={theme.cellContent}
                                cellRenderer={buttonsColumnRenderer}
                            />
                        </Table>
                    )}
                </AutoSizer>
            </div>
            {sortedList.length === 0 ? (
                <div className={theme.NoDataWrapper}>
                    <Icon spriteSymbol={noData} className={theme.noDataIcon} />
                    <div>{intl.formatMessage(messages.noData)}</div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
});
