import { MxConstants, MxClient } from '../mxgraph/mxgraph';
import {
    copyAction,
    pasteAction,
    selectAllAction,
    moveObjectAction,
    zoomOutAction,
    zoomInAction,
    handleDeleteSelectedCellsFromActiveGraphAction,
    cutAction,
    setFocusAndStartEditAction,
    openSearchAction,
    openElementPropertiesAction,
    openBdSearchAction,
} from '../actions/editor.actions';
import { LabelStyle } from '../models/labelStyle';
import { generalMenuLabelStyleChangeAction } from '../actions/generalMenu.actions';
import { BPMMxKeyHandler } from '../mxgraph/handler/BPMMxKeyHandler';
import { KeyCodes } from './keys';
import { printModel } from '../actions/image.actions';
import { isGraphOnEditMode } from './bpm.mxgraph.utils';
import { deleteCommentMarkersByActiveGraph } from '../actions/comments.actions';

// tslint:disable-next-line:no-any
export const bindHotKeysOnMxGraph = (keyHandler: BPMMxKeyHandler, emitter: any): void => {
    const { graph } = keyHandler;
    // CTRL + X
    keyHandler.bindControlKey(KeyCodes.X, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if (e.ctrlKey || e.metaKey) {
            emitter(cutAction());
        }
    });
    // CTRL + C
    keyHandler.bindControlKey(KeyCodes.C, (e: KeyboardEvent) => {
        if (e.ctrlKey || e.metaKey) {
            emitter(copyAction());
        }
    });
    // CTRL + V
    keyHandler.bindControlKey(KeyCodes.V, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if (e.ctrlKey || e.metaKey) {
            emitter(pasteAction());
        }
    });
    // CTRL + A
    keyHandler.bindControlKey(KeyCodes.A, (e: KeyboardEvent) => {
        if (e.ctrlKey || e.metaKey) {
            emitter(selectAllAction());
        }
    });
    // ALT + ENTER
    keyHandler.bindKey(KeyCodes.ENTER, (e: KeyboardEvent) => {
        if (e.altKey || e.metaKey) {
            emitter(openElementPropertiesAction());
        }
    });
    // DELETE
    keyHandler.bindKey(KeyCodes.DELETE, (e: KeyboardEvent) => {
        emitter(deleteCommentMarkersByActiveGraph());
        if (!isGraphOnEditMode(graph)) return;
        emitter(handleDeleteSelectedCellsFromActiveGraphAction());
        keyHandler.graph.connectionHandler.removeLastWaypoint();
        if (keyHandler.graph.popupMenuHandler && keyHandler.graph.popupMenuHandler.isMenuShowing()) {
            keyHandler.graph.popupMenuHandler.hideMenu();
        }
    });
    // CTRL + ALT + F
    // CTRL + Shift + F
    keyHandler.bindControlKey(KeyCodes.F, (e: KeyboardEvent) => {
        if ((e.ctrlKey && e.altKey) || e.metaKey) {
            emitter(openBdSearchAction());
        }
        if (isGraphOnEditMode(graph)) return;

        if ((e.ctrlKey && e.shiftKey) || e.metaKey) {
            emitter(openSearchAction());
        }
     });
    // arrow UP
    keyHandler.bindKey(KeyCodes.UP, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        emitter(moveObjectAction(KeyCodes.UP));
    });
    // arrow DOWN
    keyHandler.bindKey(KeyCodes.DOWN, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        emitter(moveObjectAction(KeyCodes.DOWN));
    });
    // arrow LEFT
    keyHandler.bindKey(KeyCodes.LEFT, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        emitter(moveObjectAction(KeyCodes.LEFT));
    });
    // arrow RIGHT
    keyHandler.bindKey(KeyCodes.RIGHT, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        emitter(moveObjectAction(KeyCodes.RIGHT));
    });
    // CTRL + B
    keyHandler.bindControlKey(KeyCodes.B, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if (e.ctrlKey || e.metaKey) {
            emitter(generalMenuLabelStyleChangeAction(LabelStyle.decorationStyle, MxConstants.FONT_BOLD));
        }
    });
    // CTRL + I
    keyHandler.bindControlKey(KeyCodes.I, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if (e.ctrlKey || e.metaKey) {
            emitter(generalMenuLabelStyleChangeAction(LabelStyle.decorationStyle, MxConstants.FONT_ITALIC));
        }
    });
    // CTRL + U
    keyHandler.bindControlKey(KeyCodes.U, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if (e.ctrlKey || e.metaKey) {
            emitter(generalMenuLabelStyleChangeAction(LabelStyle.decorationStyle, MxConstants.FONT_UNDERLINE));
        }
    });
    // CTRL + L
    keyHandler.bindControlKey(KeyCodes.L, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if (e.ctrlKey || e.metaKey) {
            emitter(generalMenuLabelStyleChangeAction(LabelStyle.alignmentStyle, MxConstants.ALIGN_LEFT));
        }
    });
    // CTRL + P
    keyHandler.bindControlKey(KeyCodes.P, (e: KeyboardEvent) => {
        if (e.ctrlKey || e.metaKey) {
            emitter(printModel(graph.id));
        }
    });
    // CTRL + R
    keyHandler.bindControlKey(KeyCodes.R, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if (e.ctrlKey || e.metaKey) {
            emitter(generalMenuLabelStyleChangeAction(LabelStyle.alignmentStyle, MxConstants.ALIGN_RIGHT));
        }
    });
    // CTRL + E
    keyHandler.bindControlKey(KeyCodes.E, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if (e.ctrlKey || e.metaKey) {
            emitter(generalMenuLabelStyleChangeAction(LabelStyle.alignmentStyle, MxConstants.ALIGN_CENTER));
        }
    });
    // CTRL + Z
    keyHandler.bindControlKey(KeyCodes.Z, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if ((!MxClient.IS_MAC && e.ctrlKey) || (MxClient.IS_MAC && e.metaKey)) {
            if (e.shiftKey) {
                keyHandler.graph.undoManager.redo();
            } else {
                keyHandler.graph.undoManager.undo();
            }
        }
    });
    // CTRL + Y
    keyHandler.bindControlKey(KeyCodes.Y, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if ((!MxClient.IS_MAC && e.ctrlKey) || (MxClient.IS_MAC && e.metaKey)) {
            keyHandler.graph.undoManager.redo();
        }
    });
    // BACKSPACE
    keyHandler.bindKey(KeyCodes.BACKSPACE, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        emitter(handleDeleteSelectedCellsFromActiveGraphAction());
        keyHandler.graph.connectionHandler.removeLastWaypoint();
    });
    // F2
    keyHandler.bindKey(KeyCodes.F2, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;

        const cell = graph.getSelectionCell();

        if (graph.isEnabled()) {
            emitter(setFocusAndStartEditAction(cell.getId()));
        }
    });
    // CTRL + Left
    keyHandler.bindControlKey(KeyCodes.LEFT, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if (e.ctrlKey || e.metaKey) {
            keyHandler?.graph?.selectPreviousCell();
        }
    });
    // CTRL + Right
    keyHandler.bindControlKey(KeyCodes.RIGHT, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if (e.ctrlKey || e.metaKey) {
            keyHandler?.graph?.selectNextCell();
        }
    });
    // CTRL + Up
    keyHandler.bindControlKey(KeyCodes.UP, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if (e.ctrlKey || e.metaKey) {
            keyHandler?.graph?.selectParentCell();
        }
    });
    // CTRL + DOWN
    keyHandler.bindControlKey(KeyCodes.DOWN, (e: KeyboardEvent) => {
        if (!isGraphOnEditMode(graph)) return;
        if (e.ctrlKey || e.metaKey) {
            keyHandler?.graph?.selectChildCell();
        }
    });
    // CTRL + PLUS
    keyHandler.bindControlKey(KeyCodes.PLUS, (e: KeyboardEvent) => {
        if (e.ctrlKey || e.metaKey) {
            emitter(zoomInAction());
        }
    });
    // CTRL + MINUS
    keyHandler.bindControlKey(KeyCodes.MINUS, (e: KeyboardEvent) => {
        if (e.ctrlKey || e.metaKey) {
            emitter(zoomOutAction());
        }
    });
    // CTRL + PLUS (Mozilla)
    keyHandler.bindControlKey(KeyCodes.PLUS_MOZILLA, (e: KeyboardEvent) => {
        if (e.ctrlKey || e.metaKey) {
            emitter(zoomInAction());
        }
    });
    // CTRL + MINUS (Mozilla)
    keyHandler.bindControlKey(KeyCodes.MINUS_MOZILLA, (e: KeyboardEvent) => {
        if (e.ctrlKey || e.metaKey) {
            emitter(zoomOutAction());
        }
    });
    // CTRL + NUM PLUS
    keyHandler.bindControlKey(KeyCodes.NUM_PLUS, (e: KeyboardEvent) => {
        if (e.ctrlKey || e.metaKey) {
            emitter(zoomInAction());
        }
    });
    // CTRL + NUM MINUS
    keyHandler.bindControlKey(KeyCodes.NUM_MINUS, (e: KeyboardEvent) => {
        if (e.ctrlKey || e.metaKey) {
            emitter(zoomOutAction());
        }
    });
};
