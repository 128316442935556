import React from 'react';
import messages from './ApprovalDialog.messages';
import { useIntl } from 'react-intl';
import { Form, Radio, RadioChangeEvent } from 'antd';
import { ApprovalStageDTOStatus, ApprovalStageDTOType } from './ApprovalDialog.types';
import { StagesBllService } from '@/services/bll/ApprovalDialogStagesBLLService';
import { TApprovalTypeComponentProps } from './StageSettingsTab.types';

export const ApprovalTypeComponent = ({
    selectedStage,
    isTemplate,
    isEditingDisabled,
    isCreateMode,
    onChangeStages,
    stages,
    stageId,
}: TApprovalTypeComponentProps) => {
    const intl = useIntl();

    const onTypeChange = (e: RadioChangeEvent) => {
        const stageList = StagesBllService.changeType(stages, stageId, e.target.value);

        onChangeStages(stageList);
    };

    return (
        <Form.Item
            label={intl.formatMessage(messages.approvalType)}
            initialValue={selectedStage.type || ApprovalStageDTOType.PARALLEL}
            name="ApprovalType"
        >
            <Radio.Group
                onChange={onTypeChange}
                value={selectedStage.type || ApprovalStageDTOType.PARALLEL}
                disabled={
                    !isTemplate &&
                    (isEditingDisabled || (selectedStage.status === ApprovalStageDTOStatus.IN_PROCESS && !isCreateMode))
                }
            >
                <Radio value={ApprovalStageDTOType.FOLLOWING}>{intl.formatMessage(messages.following)}</Radio>
                <Radio value={ApprovalStageDTOType.SUCCESSIVE}>
                    {intl.formatMessage(messages.followingSuccessive)}
                </Radio>
                <Radio value={ApprovalStageDTOType.PARALLEL}>{intl.formatMessage(messages.parallel)}</Radio>
            </Radio.Group>
        </Form.Item>
    );
};
