import React, { useEffect, useState } from 'react';
import { Button, List } from 'antd';
import style from './DbSearch.component.scss';
import { SearchPath } from '../SearchPath/SearchPath.component';
import { DbSearchListItem } from '../DbSearchListItem/DbSearchListItem.component';
import { useDispatch, useSelector } from 'react-redux';
import { SearchSelectors } from '../../../selectors/dbSearch.selector';
import { TSearchDataListItem } from '../../../reducers/search.reducer.types';
import { SearchField } from '../SearchField/SearchField.component';
import { SearchFilter } from '../SearchFilter/SearchFilter.component';
import { resetSearchData } from '../../../actions/search.actions';
import { AttributeType, NodeId } from '../../../serverapi/api';
import { presetMetaDataRequestWithPresetId } from '../../../actions/notation.actions';
import { SearchElementTypeSelectors } from '../selectors/searchElementType.selectors';
import icFilterActive from '../../../resources/icons/icFilterActive.svg';
import cx from 'classnames';
import { Icon } from '@/modules/UIKit';
import icFilter from '../../../resources/icons/icFilter.svg';
import { AttributeFilter } from '../AttributeFilter/AttributeFilter.component';
import { ISearchRuleWithId } from '../AttributeFilter/AttributeFilter.types';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { AttributeTypeSelectors } from '@/selectors/attributeType.selectors';
import icDelete from '../../../resources/icons/Deleted.svg';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import messages from './DbSearch.messages';
import { useIntl } from 'react-intl';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import { TCurrentUserProfile } from '@/reducers/userProfile.reducer.types';
import { ProfileBllService } from '@/services/bll/ProfileBllService';

export const DbSearch = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const isLoading: boolean = useSelector(SearchSelectors.getLoadingStatus);
    const nodeId: NodeId = useSelector(SearchSelectors.getNodeId);
    const filterSearchResult: TSearchDataListItem[] = useSelector(SearchElementTypeSelectors.getSearchResult(nodeId));
    const [isAttributeFilterOpen, setIsAttributeFilterOpen] = useState<boolean>(false);
    const [searchRules, setSearchRules] = useState<ISearchRuleWithId[]>([]);
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const attributeTypes: AttributeType[] = useSelector(
        AttributeTypeSelectors.allInPresetSorted(nodeId?.serverId, presetId),
    );
    const profile: TCurrentUserProfile | undefined = useSelector(
        UserProfileSelectors.selectUserProfileByNodeId(nodeId),
    );
    const accessibleAttributeTypes = attributeTypes.filter((attrType) =>
        ProfileBllService.isAttributeViewable(profile, attrType.id),
    );

    useEffect(() => {
        if (nodeId) {
            dispatch(presetMetaDataRequestWithPresetId(nodeId));
        }

        return () => {
            dispatch(resetSearchData(nodeId));
        };
    }, []);

    const handleAttributeFilterToggle = () => setIsAttributeFilterOpen(!isAttributeFilterOpen);

    const onDeleteAllConditions = () => {
        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => {
                    setSearchRules([]);
                },
                title: intl.formatMessage(messages.deleteConfirmTitle),
                question: intl.formatMessage(messages.deleteConfirmQuestion),
                OKButtonText: intl.formatMessage(messages.delete),
            }),
        );
    };

    return (
        <div className={style.container}>
            <SearchPath />
            <div className={style.flexCenter}>
                <SearchField searchRules={searchRules} />
                <Button
                    className={cx(style.filterBtn, { [style.filterBtnActive]: isAttributeFilterOpen })}
                    onClick={handleAttributeFilterToggle}
                >
                    <Icon spriteSymbol={isAttributeFilterOpen ? icFilterActive : icFilter} />
                </Button>
                {searchRules.length ? (
                    <>
                        <div>{intl.formatMessage(messages.filters, { number: searchRules.length })}</div>
                        <button className={style.deleteConditionBtn} onClick={onDeleteAllConditions}>
                            <Icon spriteSymbol={icDelete} />
                        </button>
                    </>
                ) : null}
            </div>

            <AttributeFilter
                searchRules={searchRules}
                setSearchRules={setSearchRules}
                attributeTypes={accessibleAttributeTypes}
                nodeId={nodeId}
                isHidden={!isAttributeFilterOpen}
            />

            {!!filterSearchResult?.length && <SearchFilter />}
            <div className={style.listContainer}>
                <List
                    itemLayout="vertical"
                    dataSource={filterSearchResult}
                    loading={isLoading}
                    renderItem={(item, index) => (
                        <List.Item>
                            <DbSearchListItem
                                index={index + 1}
                                path={item.path}
                                type={item.type}
                                multilingualName={item.multilingualName}
                                nodeId={item.nodeId}
                                elementType={item.elementType}
                                itemTypeName={item.itemTypeName}
                                deleted={item.deleted}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};
