import React from 'react';
import { Alert } from 'antd';
import { ServerForm } from '../../ServerForm/components/ServerForm.component';
import theme from './StartScreen.scss';
import messages from './StartScreen.messages';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { GIT_BRANCH, SERVER_REVISION_VERSION, SERVER_VERSION } from '../../../ServerInfo';
import { AppStatus } from '../../../models/app';
import { TStartScreenProps } from '../StartScreen.types';
import electron from '../../../electron';
import { useSelector } from 'react-redux';
import { LocalesService } from '@/services/LocalesService';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalStorageDaoService } from '@/services/dao/LocalStorageDaoService';

const StartScreen = (props: TStartScreenProps) => {
    const {
        appStatus,
        error,
        availableServers,
        intl,
        dialogRoot,
        header,
        pending,
        onServerEdit,
        onServerAdd,
        onSubmit,
        setErrorMessage,
        keycloakSettings,
        handleReRender,
    } = props;

    const currentLocale = useSelector(getCurrentLocale);
    const greetingText = LocalStorageDaoService.getGreetingText();
    const defaultTitle = intl.formatMessage(messages.title);
    const title = greetingText
        ? LocalesService.internationalStringToString(greetingText, currentLocale) || defaultTitle
        : defaultTitle;

    const waitScreenClassNames = classnames(theme.waitScreen, pending ? theme.displayWaitScreen : '');

    const onKeycloakLogin = () => {
        const url = new URL(window.location.href);
        url.searchParams.set('auth', 'keycloak');
        window.history.replaceState(null, '', url);
        handleReRender();
    };

    return (
        <div className={theme.container}>
            <div className={waitScreenClassNames} />
            <div className={theme.header}>{header}</div>
            <div className={theme.body}>
                <div className={theme.titleWrapper}>
                    <div className={theme.title}>{title}</div>
                </div>
                <div className={theme.wrapper}>
                    <div className={theme.header}>
                        {electron && (
                            <div className={theme.subTitle}>
                                <div>{intl.formatMessage(messages.chooseYourServer)}</div>
                                <div>{intl.formatMessage(messages.addNewOne)}</div>
                            </div>
                        )}
                    </div>
                    <div className={theme.form}>
                        <ServerForm
                            onClickServerEdit={onServerEdit}
                            onClickServerAdd={onServerAdd}
                            onLogin={onSubmit}
                            availableServers={availableServers}
                            setErrorMessage={setErrorMessage}
                        />
                        {keycloakSettings ? (
                            <button
                                data-test="login-page_keycloak-login-button"
                                className={theme.keycloakBtn}
                                onClick={onKeycloakLogin}
                            >
                                {intl.formatMessage(messages.loginKeycloak)}
                            </button>
                        ) : null}
                        {error && <Alert className={theme.alert} type="error" message={error} showIcon />}
                        {appStatus === AppStatus.Kicked && (
                            <Alert
                                className={theme.alert}
                                type="error"
                                message={intl.formatMessage(messages.kicked)}
                                showIcon
                            />
                        )}
                    </div>
                </div>
            </div>
            {dialogRoot}
            <div style={{ margin: '20px' }}>
                <ul>
                    <li>
                        {intl.formatMessage(messages.serverVersion, {
                            SERVER_VERSION: `${SERVER_VERSION}.${SERVER_REVISION_VERSION}`,
                        })}
                    </li>
                    <li>{intl.formatMessage(messages.release, { GIT_BRANCH })} </li>
                </ul>
            </div>
        </div>
    );
};

const IntlComponent = injectIntl(StartScreen);

export { IntlComponent as StartScreen };
