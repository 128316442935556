import { call, put, select, takeEvery } from 'redux-saga/effects';
import { closeDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { getActiveGraph } from '../selectors/editor.selectors';
import { TreeSelectors } from '../selectors/tree.selectors';
import { EdgeInstance, EdgeType, NodeId } from '../serverapi/api';
import { SelectedStrategy } from '../models/selectObjectDialog.types';
import {
    TSelectEdgeDialogPrepareDataRequestAction,
    TSelectEdgeDialogSubmitData,
} from '../actions/selectEdgeDefinitionDialog.actions.types';
import { EdgeTypeSelectors } from '../selectors/edgeType.selectors';
import {
    SELECT_EDGE_DEFINITION_DIALOG_PREPARE_DATA_REQUEST,
    SELECT_EDGE_DIALOG_SUBMIT_DATA,
} from '../actionsTypes/selectEdgeDefinitionDialog.actionTypes';
import { selectEdgeDialogPrepareDataSuccess } from '../actions/selectEdgeDefinitionDialog.actions';
import { SelectEdgeDefinitionDialogSelectors } from '../selectors/selectEdgeDefinitionDialog.selectors';
import { TSelectEdgeDefinitionDialogState } from '../reducers/selectEdgeDefinitionDialog.reducer.types';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { BPMMxGraph } from '../mxgraph/bpmgraph';
import { MxCell } from '../mxgraph/mxgraph';
import { createEdgeDefinition } from '../actions/entities/edgeDefinition.actions';
import { ObjectDefinitionSelectors } from '../selectors/objectDefinition.selectors';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { updateGraph } from './tree.saga';
import { getExits } from '../mxgraph/util/BpmMxEditorUtils';
import { updateCellsOverlays } from '../actions/overlay.actions';

function* hanldlePrepareData({
    payload: { instances, sourceObjectDefinitionId, targetObjectDefinitionId },
}: TSelectEdgeDialogPrepareDataRequestAction) {
    if (!instances.length) return;

    const activeGraphId: NodeId = yield select(getActiveGraph);
    const presetId: string = yield select(TreeSelectors.presetById(activeGraphId));
    const currentState: TSelectEdgeDefinitionDialogState = yield select(
        SelectEdgeDefinitionDialogSelectors.selectEdgeDefinitionDialogState,
    );
    const sourceObject: ObjectDefinitionImpl | undefined = yield select(
        ObjectDefinitionSelectors.byId({ ...activeGraphId, id: sourceObjectDefinitionId }),
    );
    const targetObject: ObjectDefinitionImpl | undefined = yield select(
        ObjectDefinitionSelectors.byId({ ...activeGraphId, id: targetObjectDefinitionId }),
    );

    const newState = { ...currentState };

    if (sourceObject) newState.sourceObjectName = sourceObject.name || '';
    if (targetObject) newState.targetObjectName = targetObject.name || '';

    const edgeTypeNameMap = new Map<string, string>();

    for (const instance of instances) {
        if (instance.edgeTypeId) {
            const edgeType: EdgeType | undefined = yield select(
                EdgeTypeSelectors.byId({ edgeTypeId: instance.edgeTypeId, presetId, serverId: activeGraphId.serverId }),
            );

            if (edgeType) edgeTypeNameMap.set(instance.nodeId.id, edgeType.name);
        }
    }

    newState.edgeTypeNameMap = edgeTypeNameMap;

    yield put(selectEdgeDialogPrepareDataSuccess(newState));
}

function* handleSubmit({ payload: { edgeDefinition, strategy, cellId } }: TSelectEdgeDialogSubmitData) {
    const activeGraphId: NodeId = yield select(getActiveGraph);
    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph) return;

    const cell: MxCell | undefined = graph.getModel().getCell(cellId);

    switch (strategy) {
        case SelectedStrategy.useExisting:
            if (cell) {
                const presetId: string = yield select(TreeSelectors.presetById(graph.id));
                const edgeType: EdgeType | undefined = yield select(
                    EdgeTypeSelectors.byId({
                        edgeTypeId: edgeDefinition.edgeTypeId || '',
                        presetId,
                        serverId: graph.id.serverId,
                    }),
                );

                const newValue: EdgeInstance = {
                    ...cell.getValue(),
                    name: edgeDefinition.name,
                    multilingualName: edgeDefinition.multilingualName,
                    edgeTypeId: edgeDefinition.edgeTypeId || '',
                    edgeDefinitionId: edgeDefinition.nodeId.id,
                };

                if (edgeType) {
                    const edgeTypeStyle = graph.createEdgeStyle(edgeType, getExits(graph, cell));
                    if (edgeTypeStyle) {
                        newValue.style = edgeTypeStyle;
                        graph.setCellStyle(edgeTypeStyle, [cell]);
                    }
                }
                cell.setValue(newValue);
                yield call(updateGraph, edgeDefinition, undefined);
            }
            break;
        case SelectedStrategy.createNew:
            yield put(createEdgeDefinition({ graphId: graph.id, cell }));
            break;
        default:
            break;
    }

    yield put(updateCellsOverlays({ graphId: graph.id, cells: [cell] }));
    yield put(closeDialog(DialogType.SELECT_EDGE_DIALOG));
}

export function* selectEdgeSagaInit() {
    yield takeEvery(SELECT_EDGE_DIALOG_SUBMIT_DATA, handleSubmit);
    yield takeEvery(SELECT_EDGE_DEFINITION_DIALOG_PREPARE_DATA_REQUEST, hanldlePrepareData);
}
