import React from 'react';
import { TChangedProperties, TChangedProperty } from '../../../../actions/changedProperties.types';
import { TNavigatorPropertiesData } from '../../../../models/navigatorPropertiesSelectorState.types';
import { FormGroup } from '../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { Checkbox, Form } from 'antd';
import { useIntl } from 'react-intl';
import {
    PROP_SCRIPT_ENGINE_EDIT_DISABLED,
    PROP_USER_EDIT_DISABLED,
} from '../../../../models/properties/accessible-properties';
import messages from '../../messages/Settings.messages';
import { TEnumPropertyValue } from '../../../../models/properties/TEnumPropertyDescriptor.types';
import { TPropertyValue } from '../../../../models/properties/property-descriptor.types';
import { useSelector } from 'react-redux';
import { isUserHasAccess } from '../../../../selectors/authorization.selectors';

type TPropertySettingsProps = {
    changedProperties: TChangedProperties;
    propertiesData: TNavigatorPropertiesData;
    onChangeProperties: (changedProperties: TChangedProperties) => void;
};

export const PropertySettings = (props: TPropertySettingsProps) => {
    const { changedProperties, propertiesData, onChangeProperties } = props;
    const intl = useIntl();
    const hasAccessToChange: boolean = useSelector(isUserHasAccess('LOCK_ADMIN'));
    const scriptEngineEditDisabledChangedProperty: TChangedProperty =
        changedProperties[PROP_SCRIPT_ENGINE_EDIT_DISABLED.key];
    const userEditDisabledChangedProperty: TChangedProperty = changedProperties[PROP_USER_EDIT_DISABLED.key];
    const scriptEngineEditDisabledProperty: TPropertyValue | TEnumPropertyValue =
        propertiesData[PROP_SCRIPT_ENGINE_EDIT_DISABLED.key];
    const userEditDisabledProperty: TPropertyValue | TEnumPropertyValue = propertiesData[PROP_USER_EDIT_DISABLED.key];

    const updateProperties = (key: string, value: TChangedProperty) => {
        onChangeProperties({
            ...props.changedProperties,
            [key]: value,
        });
    };

    return (
        <div>
            <FormGroup>
                <Form.Item>
                    <Checkbox
                        checked={
                            (userEditDisabledChangedProperty
                                ? userEditDisabledChangedProperty.value?.value
                                : userEditDisabledProperty.value?.value) === 'true'
                        }
                        disabled={!hasAccessToChange}
                        onChange={(value) => {
                            updateProperties(PROP_USER_EDIT_DISABLED.key, {
                                ...userEditDisabledProperty,
                                value: {
                                    ...userEditDisabledProperty.value,
                                    value: String(value.target.checked),
                                },
                            } as TChangedProperty);
                        }}
                    >
                        {intl.formatMessage(messages.userEditDisabled)}
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Checkbox
                        checked={
                            (scriptEngineEditDisabledChangedProperty
                                ? scriptEngineEditDisabledChangedProperty.value?.value
                                : scriptEngineEditDisabledProperty.value?.value) === 'true'
                        }
                        disabled={!hasAccessToChange}
                        onChange={(value) => {
                            updateProperties(PROP_SCRIPT_ENGINE_EDIT_DISABLED.key, {
                                ...scriptEngineEditDisabledProperty,
                                value: {
                                    ...scriptEngineEditDisabledProperty.value,
                                    value: String(value.target.checked),
                                },
                            } as TChangedProperty);
                        }}
                    >
                        {intl.formatMessage(messages.scriptEngineEditDisabled)}
                    </Checkbox>
                </Form.Item>
            </FormGroup>
        </div>
    );
};
