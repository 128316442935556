import React, { useState } from 'react';
import theme from './approvalTable.scss';
import messages from '../messages/approval.messages';
import { Button } from 'antd';
import { useIntl } from 'react-intl';

type TElementNamesProps = {
    elementNames: string[];
    setNeedToRender: (needToRender: boolean) => void;
};

export const ElementNames = (props: TElementNamesProps): JSX.Element => {
    const intl = useIntl();
    const [showList, setShowList] = useState<boolean>(false);
    const { elementNames, setNeedToRender } = props;

    const [firstElementName, ...elementNodesInHiddenList] = elementNames;

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setShowList(true);
        setNeedToRender(true);
    };

    return (
        <div className={theme.elementNamesContainer}>
            <div title={firstElementName} className={theme.displayEllipsis}>
                {firstElementName}
            </div>
            {elementNodesInHiddenList.length > 0 && !showList && (
                <div>
                    <Button type="link" className={theme.moreButton} onClick={handleClick}>
                        {`${intl.formatMessage(messages.more)} ${elementNodesInHiddenList.length}`}
                    </Button>
                </div>
            )}
            {showList &&
                elementNodesInHiddenList.map((name) => {
                    return (
                        <div key={name} title={name} className={theme.displayEllipsis}>
                            {' '}
                            {name}
                        </div>
                    );
                })}
        </div>
    );
};
