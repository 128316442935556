import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { actionTypesKeys } from '../../../actions/audit.actions.types';
import actionTypesMessages from './actionTypes.messages';
import style from './ActionsAudit.scss';
import { setAuditFilteredTypes } from '../../../actions/audit.actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionAuditSelectors } from '../../../selectors/actionAudit.selector';
import messages from './audit.messages';
import { Select } from '../../UIKit/components/Select/Select.component';

export const ActionAuditDropdown = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [searchInFilterTypes, setSearchTextInFilterTypes] = useState('');

    const handleSearchTypeInput = (value: string) => {
        setSearchTextInFilterTypes(value);
    };

    const filteredTypes = useSelector(ActionAuditSelectors.filteredTypes);

    const sortAlphabeticalAndByChecked = (keys: string[]) => {
        return keys
            .sort((t1, t2) =>
                Intl.Collator().compare(
                    intl.formatMessage(actionTypesMessages[t1]),
                    intl.formatMessage(actionTypesMessages[t2]),
                ),
            )
            .sort((tt1, tt2) => Number(filteredTypes.includes(tt2)) - Number(filteredTypes.includes(tt1)));
    };

    const onChangeByType = (types: string[]) => dispatch(setAuditFilteredTypes(types));

    return (
        <div className={style.dropdownContainer} data-test="security_audit_type-filtration">
            <Select
                value={intl.formatMessage(messages.byType)}
                showSearch
                onSearch={handleSearchTypeInput}
                isMultiSelect
                onChange={onChangeByType}
                dropdownClassName={style.ulContainer}
                searchValue={searchInFilterTypes}
                dropdownWidth={400}
            >
                {sortAlphabeticalAndByChecked(actionTypesKeys)
                    .filter((type) => {
                        const formatType = intl.formatMessage(actionTypesMessages[type]);

                        return formatType.toLowerCase().includes(searchInFilterTypes.toLowerCase().trim());
                    })
                    .map((type) => (
                        <Select.Option
                            key={type}
                            checked={filteredTypes?.includes(type)}
                            value={type}
                            label={intl.formatMessage(actionTypesMessages[type])}
                        />
                    ))}
            </Select>
        </div>
    );
};
