import electron from '../electron';
import { BPMMxGraph, SvgImage } from '../mxgraph/bpmgraph';
import { toClipboard } from '../utils/clipboardUtils';
import { TServerEntity } from '../models/entities.types';
import { getImageMimeType } from '../utils/image.utils';
import { ModelNode, NodeId } from '../serverapi/api';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { MxCell } from '../mxgraph/mxgraph';

interface ISvgService {
    svgToClipboard(svg?: SvgImage): void;
    convertSvg(
        svg: SvgImage,
        callback: (canvas: HTMLCanvasElement) => (blob: Blob | null) => void,
        mimeType?: string,
    ): void;
    parseFromString(svgString: string): XMLDocument;
    serializeToImage(svg: XMLDocument): SvgImage;
    convertSvgImagePathsToBase64Images(svgString: string, server: TServerEntity): Promise<string>;
    getSvg(
        activeSchemeId: NodeId | undefined,
        model: ModelNode,
        server: TServerEntity,
        onlySelected?: boolean,
        withoutNegativeCoordinates?: boolean,
        withoutDecompositionIcons?: boolean,
        isPrint?: boolean,
    );
}

const removeNegativeOffset = (offset: number): number => (offset > 0 ? offset : 0);

type TReaderIterator = {
    done: boolean;
    value: Uint8Array;
};

// пример преобразования в png
/* if (svg && svg.svgString) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (ctx) {
        ctx.canvas.width  = svg.width;
        ctx.canvas.height = svg.height;
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, svg.width, svg.height);
        this.drawInlineSVG(ctx, svg.svgString, () => {
            canvas.toBlob((blob) => {
                const copied = toClipboard(blob);
                if (!copied && electron){
                    const dataURI = canvas.toDataURL();
                    const clipboard = electron!.clipboard;
                    const nativeImage = electron!.nativeImage;
                    const image = nativeImage.createFromDataURL(dataURI);
                    clipboard.writeImage(image, 'png');
                }
            });
        });
    }
} */

class SvgServiceImpl implements ISvgService {
    svgToClipboard(svg?: SvgImage): void {
        const save = (canvas: HTMLCanvasElement) => (blob: Blob | null) => {
            const copied = toClipboard(blob);
            if (!copied && electron) {
                const dataURI = canvas.toDataURL();
                const clipboard = electron!.clipboard;
                const nativeImage = electron!.nativeImage;
                const image = nativeImage.createFromDataURL(dataURI);
                clipboard.writeImage(image, 'png');
            }
        };
        if (svg && svg.svgString) {
            this.convertSvg(svg, save, 'image/png');
        }
    }

    convertSvg(svg: SvgImage, callback: (canvas: HTMLCanvasElement) => (blob: Blob | null) => void, mimeType?: string) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.canvas.width = svg.width;
            ctx.canvas.height = svg.height;
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, svg.width, svg.height);
            this.drawInlineSVG(ctx, svg.svgString, () => {
                canvas.toBlob(callback(canvas), mimeType);
            });
        }
    }

    drawInlineSVG(ctx: CanvasRenderingContext2D, rawSVG: string, callback: any) {
        const img = new Image();
        img.onload = function () {
            ctx.drawImage(img, 0, 0);
            callback();
        };
        img.src = `data:image/svg+xml;base64,${Base64.encode(rawSVG)}`;
    }

    parseFromString(svgString: string): XMLDocument {
        const parser = new DOMParser();

        return parser.parseFromString(svgString, 'image/svg+xml');
    }

    serializeToImage(svg: XMLDocument): SvgImage {
        const serializer = new XMLSerializer();

        const svgString = serializer.serializeToString(svg);
        const svgWidth = parseInt(svg.documentElement.getAttribute('width') || '0', 10);
        const svgHeight = parseInt(svg.documentElement.getAttribute('height') || '0', 10);

        return {
            svgString,
            width: svgWidth,
            height: svgHeight,
        };
    }

    async convertSvgImagePathsToBase64Images(svgString, server) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgString, 'image/svg+xml');
        const imageCollection: HTMLCollectionOf<SVGImageElement> = doc.getElementsByTagName('image');

        const urlList = Array.from(imageCollection).map((item) => {
            const url = item.href.baseVal;

            if (url.charAt(url.length - 1) === '/') {
                return url.substring(0, url.length - 1);
            }

            return url;
        });

        const uniqueUrlList = Array.from(new Set(urlList));

        const responseList = await Promise.allSettled<Response[]>(
            uniqueUrlList
                .filter((url) => !url.startsWith('data')) // проверка что ссылка - это урл, а не данные. Если урл - будем делать запрос на сервер
                .filter((url) => url.includes('api/')) // BPM-4556, в урл должено быть что-то про api иначе не ясно откуда картинка скачивается
                .map((url) => {
                    const parts = url.split('/');

                    return server.api.file
                        .downloadFile({
                            fileId: parts[parts.length - 1],
                            repositoryId: parts[parts.length - 2],
                        }) // последний - id, предпоследний - repositoryId
                        .catch(() => undefined);
                }),
        );

        const files = {};
        const filteredResponseList = responseList
            .filter(Boolean)
            .filter((res) => res?.status === 'fulfilled' && res.value?.body);

        for (const response of filteredResponseList) {
            // @ts-ignore
            const { value: responseValue } = response;
            const reader = responseValue.body!.getReader();
            const chunks: Uint8Array[] = [];

            while (true) {
                // eslint-disable-next-line no-await-in-loop
                const { done, value }: TReaderIterator = await reader.read();
                if (done) {
                    break;
                }

                chunks.push(value);
            }

            const extension = responseValue.url.split('.').pop() || '';

            const blobFile = new Blob(chunks, { type: `${getImageMimeType(extension)}` });

            const blobToBase64 = (blob) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(blob);

                return new Promise((resolve) => {
                    fileReader.onloadend = () => {
                        resolve(fileReader.result);
                    };
                });
            };

            // eslint-disable-next-line no-await-in-loop
            const result = await blobToBase64(blobFile);

            files[responseValue.url] = {
                file: result,
            };
        }

        // replaceAll не понимает старый Electron
        const replaceAll = (string: string, search: string, replace: string): string =>
            string.split(search).join(replace);

        Object.keys(files).forEach((url) => {
            // svgString = svgString.replaceAll(`${url}/`, files[url].file);
            svgString = replaceAll(svgString, `${url}/`, files[url].file);
        });

        return svgString;
    }

    async getSvg(
        activeSchemeId: NodeId | undefined,
        model,
        server,
        onlySelected,
        withoutNegativeCoordinates,
        withoutDecompositionIcons?: boolean,
    ) {
        if (!activeSchemeId) return undefined;

        const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeSchemeId);
        if (!graph) return undefined;

        const cells: MxCell[] = onlySelected ? graph.getSelectionCells() : Object.values(graph.model.cells);
        const graphOffset = graph.getBoundingBoxWithOverlayOffsets(cells);
        const image = onlySelected
            ? graph.getSvgStrSelectedCells(withoutNegativeCoordinates, withoutDecompositionIcons)
            : graph.getSvgStrAllGraph(withoutNegativeCoordinates, withoutDecompositionIcons);
        const svgString = await this.convertSvgImagePathsToBase64Images(image.svgString, server);

        return {
            ...image,
            svgString,
            modelName: model.name,
            offset: {
                top: removeNegativeOffset(graphOffset.overlaysBounds.y),
                left: removeNegativeOffset(graphOffset.overlaysBounds.x),
            },
        };
    }
}

let svgServiceInstance: ISvgService;

export function svgService(): ISvgService {
    if (!svgServiceInstance) {
        svgServiceInstance = new SvgServiceImpl();
    }

    return svgServiceInstance;
}

export default svgService();
