import { MxCell } from '../mxgraph/mxgraph.d';
import { v4 as uuid } from 'uuid';
import { put, select, take, takeEvery } from 'redux-saga/effects';
import {
    MODEL_DIALOG_INIT,
    MODEL_DIALOG_PARENT_NODE_ID_CHANGED,
    MODEL_DIALOG_SUBMIT_DATA,
} from '../actionsTypes/modelDialog.actionTypes';
import { modelDialogInit, modelDialogSetState, modelDialogSubmitResult } from '../actions/modelDialog.actions';
import { TModelDialogInitAction, TModelDialogSubmitData } from '../actions/modelDialog.actions.types';
import { workspaceAddTab } from '../actions/tabs.actions';
import { getTreeItems, TreeSelectors } from '../selectors/tree.selectors';
import { ModelDialogSelectors } from '../selectors/modelDialog.selectors';
import { TTreeEntityState, TreeNode } from '../models/tree.types';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { treeItemChildAdd, treeItemCollapseAll, treeItemsExpandWithoutLoad } from '../actions/tree.actions';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import { isUndefined } from 'is-what';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { ModelNode, ModelType, NodeId, KanbanBoardType, MatrixType } from '../serverapi/api';
import { ModelTypeSelectors } from '../selectors/modelType.selectors';
import { IWorkspaceTabItemModelParams, TWorkspaceTab } from '../models/tab.types';
import { EDITOR_CREATED } from '../actionsTypes/editor.actionTypes';
import { modelRequestSuccess } from '../actions/model.actions';
import { EditorMode } from '../models/editorMode';
import { IWikiNode, IMatrixNode, INode } from '../models/bpm/bpm-model-impl.types';
import { wikiCreate } from '../actions/entities/wiki.actions';
import { matrixCreate } from '../actions/entities/matrix.actions';
import { getChainFromChildToParent } from '../services/utils/treeService.utils';
import { setServerIdToNodeOriginal } from '../utils/nodeId.utils';
import { saveModelSuccess, saveModelFail } from '../actions/save.actions';
import { recentAddModel } from '../actions/recent.actions';
import { presetMetaDataRequest } from '../actions/notation.actions';
import { modelService } from '../services/ModelService';
import { editorInit } from '../actions/editor.actions';
import { KanbanModelTypeSelectors } from '../selectors/kanbanModelType.selectors';
import { kanbanCreate } from '../actions/entities/kanban.actions';
import { MatrixModelTypeSelectors } from '@/selectors/matrixModelType.selectors';

function* handleOpenDialog({ payload: { nodeId, type, action } }: TTreeItemContextMenuAction) {
    if (
        (type === TreeItemType.Folder || type === TreeItemType.Repository || type === TreeItemType.Model) &&
        action === TreeItemContextMenuAction.ADD_MODEL
    ) {
        yield put(
            modelDialogInit({
                parentNodeId: nodeId,
            }),
        );
    }
}

function* handleModelDialogInit({
    payload: {
        parentNodeId,
        parentNodeId: { serverId },
        openedSelectNode,
    },
}: TModelDialogInitAction) {
    yield put(treeItemCollapseAll(DialogType.MODEL_DIALOG));
    if (parentNodeId && parentNodeId.id) {
        const nodes: { [id: string]: TreeNode } = yield select(
            getTreeItems(parentNodeId.serverId, parentNodeId.repositoryId),
        );
        const ids: NodeId[] = getChainFromChildToParent(nodes, parentNodeId.id)
            .map((n) => n.nodeId)
            .splice(1);
        ids.push({ id: serverId, repositoryId: serverId, serverId } as NodeId);
        yield put(treeItemsExpandWithoutLoad(ids, DialogType.MODEL_DIALOG));
    }

    const treeItem: TTreeEntityState = yield select(TreeSelectors.itemById(parentNodeId));

    if (treeItem && treeItem.presetId) {
        yield put(presetMetaDataRequest([treeItem.presetId]));
    }

    yield put(openDialog(DialogType.MODEL_DIALOG, { serverId, openedSelectNode }));
}

function* handleModelDialogParentNodeIdChanged({
    payload: {
        parentNodeId,
        parentNodeId: { serverId, repositoryId },
    },
}: TModelDialogInitAction) {
    if (parentNodeId) {
        const nodes = yield select(getTreeItems(serverId, repositoryId));
        const previousInitialDataId = yield select(ModelDialogSelectors.getFormInitData);
        let isLoadModelTypeRequired = true;

        // if parentNode changed in context one server no need reload model types
        if (!isUndefined(previousInitialDataId)) {
            const previousContext = nodes[previousInitialDataId.parentNodeId];
            isLoadModelTypeRequired = previousContext ? serverId !== previousContext.serverId : true;
        }

        if (isLoadModelTypeRequired) {
            yield put(modelDialogSetState({ parentNodeId }));
        }
    }
}

export function* handleModelDialogSubmit({ payload }: TModelDialogSubmitData) {
    const {
        parentNodeId,
        parentNodeId: { repositoryId, serverId },
        modelName,
        modelTypeId,
    } = payload;

    const presetId: string = yield select(TreeSelectors.presetById(parentNodeId));
    const kanbanType: KanbanBoardType | undefined = yield select(KanbanModelTypeSelectors.byId(modelTypeId, presetId));
    const matrixType: MatrixType | undefined = yield select(MatrixModelTypeSelectors.byId(modelTypeId, presetId));

    try {
        if (matrixType) {
            yield put(
                matrixCreate({
                    name: modelName,
                    parentNodeId,
                    nodeId: {
                        id: '',
                        repositoryId,
                        serverId,
                    },
                    serverId,
                    type: TreeItemType.Matrix,
                    data: undefined,
                } as IMatrixNode),
            );
        } else if (kanbanType) {
            yield put(
                kanbanCreate({
                    nodeId: {
                        id: uuid(),
                        repositoryId,
                        serverId,
                    },
                    parentNodeId,
                    name: modelName,
                    type: TreeItemType.Kanban,
                    modelTypeId,
                }),
            );
        } else {
            switch (modelTypeId) {
                case 'wiki': {
                    yield put(
                        wikiCreate({
                            name: modelName,
                            parentNodeId,
                            nodeId: {
                                id: '',
                                repositoryId,
                                serverId,
                            },
                            serverId,
                            type: TreeItemType.Wiki,
                        } as IWikiNode),
                    );
                    break;
                }
                case 'matrix': {
                    yield put(
                        matrixCreate({
                            name: modelName,
                            parentNodeId,
                            nodeId: {
                                id: '',
                                repositoryId,
                                serverId,
                            },
                            serverId,
                            type: TreeItemType.Matrix,
                            data: undefined,
                        } as IMatrixNode),
                    );
                    break;
                }
                default: {
                    let model: ModelNode = {
                        nodeId: {
                            id: uuid(),
                            repositoryId,
                            serverId,
                        },
                        parentNodeId,
                        name: modelName,
                        modelTypeId,
                        type: TreeItemType.Model,
                    };

                    model = yield modelService().saveModel(model);
                    if (!model) {
                        yield put(modelDialogSubmitResult('error'));

                        return;
                    }
                    const newModel = model;
                    setServerIdToNodeOriginal(newModel as INode, serverId);
                    const modelType: ModelType | undefined = yield select(
                        ModelTypeSelectors.byId({ modelTypeId, serverId }, presetId),
                    );
                    const workspaceTab: TWorkspaceTab = <TWorkspaceTab>{
                        title: newModel.name,
                        type: 'Editor',
                        nodeId: newModel.nodeId,
                        content: newModel,
                        mode: EditorMode.Edit,
                        params: {
                            closable: true,
                            serverId,
                            modelType,
                            symbols: modelType?.symbols || [],
                            filters: {},
                            graph: [] as MxCell[],
                        } as IWorkspaceTabItemModelParams,
                    };
                    yield put(
                        treeItemChildAdd({
                            parentNodeId: newModel.parentNodeId!,
                            child: [newModel as TreeNode],
                        }),
                    );
                    yield put(modelDialogSubmitResult('success'));
                    yield put(workspaceAddTab(workspaceTab));
                    yield take(EDITOR_CREATED);
                    yield put(modelRequestSuccess(serverId, newModel));
                    yield put(editorInit({ nodeId: newModel.nodeId }));
                    yield put(
                        recentAddModel({
                            nodeId: model.nodeId,
                            type: model.type as TreeItemType,
                            parentId: parentNodeId,
                            createdAt: new Date().toISOString(),
                            title: model.name,
                            modelTypeId: model.modelTypeId,
                            modelTypeName: (modelType && (modelType.description || modelType.name)) || '',
                        }),
                    );
                    yield put(saveModelSuccess(newModel));
                }
            }
        }
        yield put(closeDialog(DialogType.MODEL_DIALOG));
    } catch (error) {
        yield put(saveModelFail());
        throw error;
    }
}

export function* modelDialogSagaInit() {
    yield takeEvery(MODEL_DIALOG_INIT, handleModelDialogInit);
    yield takeEvery(MODEL_DIALOG_PARENT_NODE_ID_CHANGED, handleModelDialogParentNodeIdChanged);
    yield takeEvery(MODEL_DIALOG_SUBMIT_DATA, handleModelDialogSubmit);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleOpenDialog);
}
