import { Button, Input, Table } from 'antd';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { GroupDTO } from '../../../../serverapi/api';
import adminToolTheme from '../../style/AdminTools.scss';
import tabHeaderTheme from '../../Methodology/components/Presets/Header/TabHeader.scss';
import { DeleteButton, EditButton, RowButtons } from '../../Button/RowButtons';
import { ALLUSERS_GROUPID } from '../../data/admintool';
import messages from '../../messages/groups.messages';
import { SearchOutlined } from '@ant-design/icons';
import { sortByAlpha } from '../../../../utils/sortByAlpha';
import { TUsersGroupsListActionProps, TUsersGroupsListProps } from '../UsersGroupsList.types';

type TUsersGroupsListFullProps = TUsersGroupsListProps & TUsersGroupsListActionProps & WrappedComponentProps;

class UsersGroupsList extends Component<TUsersGroupsListFullProps> {
    state = {
        searchInput: '',
    };

    componentDidMount() {
        this.props.fetchAllGroups(this.props.serverId);
    }

    handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== this.state.searchInput) {
            this.setState({
                searchInput: e.target.value.trim().toLowerCase(),
            });
        }
    };

    renderList(srcData: GroupDTO[]) {
        const { intl } = this.props;

        const columns = [
            {
                title: intl.formatMessage(messages.groupId),
                dataIndex: 'id',
                key: 'id',
                width: 50,
                sorter: (a: any, b: any) => a.id - b.id,
            },
            {
                title: intl.formatMessage(messages.groupName),
                dataIndex: 'groupName',
                key: 'groupName',
                width: 120,
                sorter: (a: any, b: any) => sortByAlpha(a.groupName, b.groupName),
            },
            {
                title: intl.formatMessage(messages.description),
                dataIndex: 'description',
                key: 'description',
                width: 120,
            },
            {
                title: intl.formatMessage(messages.usersCount),
                dataIndex: 'usersCount',
                key: 'usersCount',
                width: 105,
                sorter: (a: any, b: any) => a.usersCount - b.usersCount,
            },
            {
                title: '',
                dataIndex: 'manageBtn',
                key: 'manageBtn',
                width: 40,
                render: (text = '', record: any) =>
                    record.key !== ALLUSERS_GROUPID && (
                        <RowButtons
                            className={adminToolTheme.groupRowButtons}
                            buttons={[
                                EditButton.build(() => this.props.onEditClicked(record), undefined, undefined, undefined, true),
                                DeleteButton.build(
                                    () => {
                                        this.props.onDeleteClicked(record.id);
                                    },
                                    undefined,
                                    undefined,
                                    undefined,
                                    `${intl.formatMessage(messages.deleteGroupQuestion)}?`,
                                    <>{record.groupName}</>
                                ),
                            ]}
                        />
                    ),
            },
        ];

        const data = ((srcData: GroupDTO[]) => {
            let data: GroupDTO[];
            data =
                this.state.searchInput === ''
                    ? srcData
                    : srcData.filter(
                        (item) =>
                            [item.groupName, item.id]
                                .filter((s) => s)
                                .map((s) => `${s}`.toLowerCase())
                                .filter((s) => s.includes(this.state.searchInput)).length > 0,
                    );

            return data.map((item) => ({
                key: item.id,
                id: item.id || '',
                groupName: item.groupName || '',
                description: item.description || '',
                usersCount: item.usersCount || 0,
                accesses: item.accesses || undefined,
                licenses: item.licenses || undefined,
            }));
        })(srcData);

        return (
            <>
                <div className={tabHeaderTheme.searchDiv}>
                    <div className={tabHeaderTheme.searchInput}>
                        <Input data-test="user-group_search-input" suffix={<SearchOutlined />} onChange={this.handleSearch} />
                    </div>
                    <div className={tabHeaderTheme.actionButtons}>
                        <Button
                            data-test="user-managment-group_add-new-group"
                            key="ok"
                            size="large"
                            type="primary"
                            onClick={this.props.onAddClicked}
                        >
                            {intl.formatMessage(messages.addBtnLabel)}
                        </Button>
                    </div>
                </div>
                <Table
                    className={adminToolTheme.dataTable}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    scroll={{
                        y: 'max-content',
                    }}
                />
            </>
        );
    }

    render() {
        const { groups } = this.props;

        return groups.length > 0 ? this.renderList(groups) : null;
    }
}

export default injectIntl(UsersGroupsList);
