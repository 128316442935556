export const CREATE_EDGE_DEFINITION = 'CREATE_EDGE_DEFINITION';

export const EDGE_DEFINITIONS_ADD = 'EDGE_DEFINITIONS_ADD';

export const EDGE_DEFINITIONS_UPDATE = 'EDGE_DEFINITIONS_UPDATE';

export const UPDATE_EDGE_DEFINITION_NAME = 'UPDATE_EDGE_DEFINITION_NAME';

export const INIT_EDGE_DEFINITION_CREATION = 'INIT_EDGE_DEFINITION_CREATION';

export const EDGE_DEFINITIONS_ADD_ONLY_NEW = 'EDGE_DEFINITIONS_ADD_ONLY_NEW';
