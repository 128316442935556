import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { NodeId, Comment, PrincipalDescriptor } from '../serverapi/api';
import { CommentState } from '../reducers/comments.reducer.state';
import { TComment } from '../reducers/comments.reducer.types';
import { PrincipalsSelectors } from './principals.selectors';
import { getUserLogin } from './authorization.selectors';
import { sortCommentFilesByName, sortCommentsByCreatedDate } from '../modules/Comments/utils/commentsUtils';
import { getUserInitials } from '../sagas/utils';
import { getCircleBackgroundColor } from '../modules/Comments/utils/colorsAndLetterMatching';
import dayjs from 'dayjs';
import { LocalesService } from '../services/LocalesService';
import messages from '../../src/modules/Comments/messages/CommentsPanel.messages';

export const getALLAnswerByParentId = (comments: Comment[], parentId): Comment[] => {
    const children: Comment[] = comments.filter((comment) => comment.parentId === parentId);

    return [...children, ...children.map((child) => getALLAnswerByParentId(comments, child.commentId.id))].flat();
};

export namespace CommentsSelectors {
    const getState = (state: TRootState) => state.comments;

    export const commentsEnabledSchemesIds = () => createSelector(getState, (state) => state.commentsEnabledSchemesIds);

    export const getCommentsByModelId = (modelId: NodeId) =>
        createSelector(getState, (state) => {
            const comments =
                state.byModelId.get(modelId)?.map((comment) => {
                    if (!comment.commentFiles) return comment;

                    return {
                        ...comment,
                        commentFiles: sortCommentFilesByName(comment.commentFiles),
                    };
                }) || [];

            return comments;
        });

    export const getTooltip = (modelId: NodeId) =>
        createSelector(
            getState,
            (state) => state.editingComments.get(modelId)?.tooltipRenderedOnGraphProps?.openForEditing,
        );

    export const getTooltipStyles = (modelId: NodeId) =>
        createSelector(getState, (state) => state.editingComments.get(modelId)?.tooltipRenderedOnGraphProps?.styles);

    export const getCommentById = (modelId: NodeId, commentId?: string) =>
        createSelector<TRootState, Comment[], Comment | undefined>(getCommentsByModelId(modelId), (comments) => {
            return comments.find((comment) => comment.commentId.id === commentId);
        });

    // если в комменте нет автора, то это новый коммент, автор текущий пользователь
    export const getCommentAuthor = (modelId: NodeId, commentId?: string) =>
        createSelector<TRootState, Comment | undefined, string, PrincipalDescriptor[], PrincipalDescriptor | undefined>(
            getCommentById(modelId, commentId),
            getUserLogin,
            PrincipalsSelectors.getUsers,
            (comment, login, users) => {
                return users?.find((d: PrincipalDescriptor) =>
                    comment?.author ? d.login === comment.author : d.login === login,
                );
            },
        );

    export const getCommentAuthorName = (modelId: NodeId, commentId?: string) =>
        createSelector<TRootState, PrincipalDescriptor | undefined, Comment | undefined, string>(
            getCommentAuthor(modelId, commentId),
            getCommentById(modelId, commentId),
            (author, comment) => {
                return author
                    ? `${author.name || ''} ${author.lastname || ''}`.trim() || author.login
                    : comment?.author || '';
            },
        );

    export const getAuthorInitials = (modelId: NodeId, commentId?: string) =>
        createSelector<TRootState, PrincipalDescriptor | undefined, string>(
            getCommentAuthor(modelId, commentId),
            (author) => {
                return getUserInitials(author);
            },
        );

    export const getCommentCircleBackgroundColor = (modelId: NodeId, commentId?: string) =>
        createSelector<TRootState, Comment | undefined, string>(getCommentById(modelId, commentId), (comment) => {
            return getCircleBackgroundColor(comment?.author || '', comment?.commentStatus);
        });

    export const getCreateDate = (modelId: NodeId, commentId?: string) =>
        createSelector<TRootState, Comment | undefined, string>(getCommentById(modelId, commentId), (comment) => {
            const intl = LocalesService.useIntl();
            const createdDate: Date = new Date(comment?.createdDate || new Date());

            return dayjs(new Date(createdDate)).format(`DD.MM.YYYY ${intl.formatMessage(messages.at)} HH:mm`);
        });

    export const checkIsOpenCriticalComment = (modelId: NodeId, commentId?: string) =>
        createSelector<TRootState, Comment | undefined, boolean>(getCommentById(modelId, commentId), (comment) => {
            return comment?.commentAddStatus === 'CRITICAL' && comment.commentStatus !== 'CLOSE';
        });

    export const getSortedActualComments = (modelId: NodeId) =>
        createSelector<TRootState, Comment[], Comment[]>(getCommentsByModelId(modelId), (comments) => {
            return sortCommentsByCreatedDate(comments.filter((e) => !e.parentId));
        });

    export const getCommentsInGraph = (modelId: NodeId) =>
        createSelector<TRootState, Comment[], Comment[]>(getCommentsByModelId(modelId), (comments) => {
            return comments.filter((comment) => comment.isGraphElement);
        });

    export const getPinnedComments = (modelId: NodeId) =>
        createSelector<TRootState, Comment[], Comment[]>(getSortedActualComments(modelId), (comments) => {
            return comments.filter((c) => !!c.pinDate);
        });

    export const getLastIndexOfPinnedComment = (modelId: NodeId) =>
        createSelector<TRootState, Comment[], number>(getCommentsByModelId(modelId), (comments) => {
            const pinnedComments = comments.filter((c) => !!c.pinDate);
            if (pinnedComments.length > 0) {
                return pinnedComments.length - 1;
            }

            return 0;
        });

    export const getEditingComment = (nodeId: NodeId) =>
        createSelector<TRootState, CommentState, TComment | undefined>(getState, (state) => {
            return state.editingComments.get(nodeId);
        });

    export const isEditedComment = (modelId: NodeId, commentId?: string) =>
        createSelector<TRootState, Comment | undefined, TComment | undefined, boolean>(
            getCommentById(modelId, commentId),
            getEditingComment(modelId),
            (comment, editingComment) => {
                // Eсли это новый коммент или редактируемый, проверяем, есть ли в нем текст
                if (!comment || !editingComment?.text) return !!editingComment?.text;
                // Если в редактируемом комменте были изменены файлы
                if (
                    editingComment.files?.newFiles?.length ||
                    editingComment.files?.commentFiles?.length !== comment.commentFiles?.length
                )
                    return true;

                // Eсли редактируем старый коммент, проверяем, что текст был изменен
                return comment.text !== editingComment.text;
            },
        );

    export const getCommentsByParentId = (modelId: NodeId, parentId: string) =>
        createSelector<TRootState, Comment[], Comment[]>(getCommentsByModelId(modelId), (comments) => {
            return getALLAnswerByParentId(comments, parentId);
        });

    export const isAuthor = (modelId: NodeId, commentId: string) =>
        createSelector<TRootState, Comment | undefined, string, boolean>(
            getCommentById(modelId, commentId),
            getUserLogin,
            (comment, login) => {
                if (!login) return false;

                return comment?.author === login;
            },
        );
}
