export const SAVE_TIMEOUT: number = 10 * 1000;

export const LOCK_TIMEOUT: number = 30 * 1000;

export const HASH_LINK = '/#link/';

export const NAVIGATOR_STRUCTURE = 'NavigatorStructure';

export const MAX_GUID_LENGTH = 100;

export const UNKNOWN_ATTRIBUTE_TYPE = {
    id: 'unknown',
    presetId: 'unknownPresetId',
};
