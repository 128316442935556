import { defineMessages } from 'react-intl';

export default defineMessages({
    copyLinkTitle: {
        id: 'copyDialog.copyLinkTitle',
        defaultMessage: 'Ссылка на элемент',
    },
    copyIdTitle: {
        id: 'copyDialog.copyIdTitle',
        defaultMessage: 'Ссылка на ID элемента',
    }
});
