import { SequenceSymbolTypeId } from '../mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';
import {
    ShapeInstance,
    NodeId,
    UserDTO,
    ObjectInstance,
    Symbol,
    ModelType,
    ObjectDefinitionNode,
    EdgeInstance,
    DiagramElement,
    EdgeType,
    ModelNode,
} from '../serverapi/api';
import { cancelled, put, select, take, takeEvery } from 'redux-saga/effects';
import { EDITOR_INIT } from '../actionsTypes/editor.actionTypes';
import { TEditorInitAction } from '../actions/editor.actions.types';
import {
    addBpmnTableRow,
    addTableColumn,
    addTableRow,
    copyAction,
    handleDeleteSelectedCellsFromActiveGraphAction,
    pasteAction,
    removeBpmnTableRow,
    changeSymbolForCell,
    changeEdgeType,
    moveToAction,
    processSpaceAction,
} from '../actions/editor.actions';
import { copyToClipboard } from '../actions/tabs.actions';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { MxCell, MxPopupMenu } from '../mxgraph/mxgraph';
import { BPMMxGraph } from '../mxgraph/bpmgraph';
import { eventChannel } from 'redux-saga';
import { TAction } from '../actions/index.actions.types';
import messages from '../modules/Editor/messages/EditorPopupMenu.messages';
import { objectPropertyView } from '../actions/objectProperty.actions';
import { objectDecompositionDialogInit } from '../actions/entities/objectDecomposition.actions';
import { BPMMxPopupMenuHandler } from '../mxgraph/BPMGraphClasses';
import { CommentMarker, LayoutInstanceImpl, EdgeInstanceImpl, ObjectInstanceImpl } from '../models/bpm/bpm-model-impl';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { openDialog } from '../actions/dialogs.actions';
import { psdCellType } from '../mxgraph/psdDiagram/psdTable';
import { modelContextByGraphId } from './utils';
import { IModelContext } from './utils.types';
import { scriptSelectDialogInit } from '../actions/scriptSelectDialog.actions';
import { EditorMode } from '../models/editorMode';
import { NotificationType } from '../models/notificationType';
import { showNotification } from '../actions/notification.actions';
import { v4 as uuid } from 'uuid';
import { deleteCommentMarker } from '../actions/comments.actions';
import { TreeItemType } from '../modules/Tree/models/tree';
import { getUser, hasModelEditorLicense } from '../selectors/authorization.selectors';
import { getGeneralAvailableEdgeTypeSelector } from '../selectors/generalMenu.selectors';
import { noop } from 'lodash-es';
import { ACTIVE_CONTEXT_MENU_CHANGE } from '../actionsTypes/contextMenu.actionTypes';
import { TContextMenuChangeAction } from '../actions/contextMenu.actions.types';
import { getActiveGraph, getCopiedElements } from '../selectors/editor.selectors';
import { SymbolType } from '../models/Symbols.constants';
import { treeItemSelect } from '../actions/tree.actions';
import { SpaceAction } from '../models/insertionSpace';
import { initHistoryDialogAction } from '../actions/historyDialog.actions';
import { viewModelProperties } from '../actions/modelProperty.actions';
import { openEdgeManagementDialog } from '../actions/edgeManagement.actions';
import {
    imageToClipboard,
    saveImage,
    saveImageSvg,
    savePdf,
    printModel,
    openImageDownloadSettings,
} from '../actions/image.actions';
import { getStore } from '../store';
import { ObjectDefinitionSelectors } from '../selectors/objectDefinition.selectors';
import { ModelTypes } from '../models/ModelTypes';
import { ComplexSymbolManager } from '../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { UserProfileSelectors } from '../selectors/userProfile.selectors';
import { TreeSelectors } from '../selectors/tree.selectors';
import { isNotAccessSymbolStyle, isNotReadableSymbolStyle, isSymbolHasNotDefinition } from '../services/SymbolsService';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { Locale } from '../modules/Header/components/Header/header.types';
import { copyLinkAction } from '../actions/copyLinkDialog.actions';
import { MoveTo } from '../models/move-to';
import { createDiagramElement } from '../actions/navigatorSymbol.actions';
import { AvailableConnectionForSymbolsBLLService } from '../services/bll/AvailableConnectionForSymbolsBLLService';
import { TCurrentUserProfile } from '../reducers/userProfile.reducer.types';
import { initEdgeDefinitionCreation } from '../actions/entities/edgeDefinition.actions';
import { TScriptSelectDialogInitPayload } from '../actions/scriptSelectDialog.actions.types';
import { upsertAttributeValue } from '../actions/attribute.actions';
import { getSymbolsFromModelType } from '../utils/symbol.utils';
import { SequenceEdgeTypesId } from '../mxgraph/SequenceGraph/SequenceConstants';
import { ModelSelectors } from '../selectors/model.selectors';
import SequenceUtils from '@/mxgraph/ComplexSymbols/symbols/Sequence/sequence.utils';
import LifeLineUtils from '@/mxgraph/ComplexSymbols/symbols/LifeLine/LifeLine.utils';
import { LifelineSymbolMainClass } from '@/mxgraph/ComplexSymbols/symbols/LifeLine/LifelineSymbolMain.class';
import { currentTreeDepth } from '@/mxgraph/ComplexSymbols/utils';
import { UML_DESTROY_ATTRIBUTE_TYPE_ID } from '@/mxgraph/ComplexSymbols/symbols/Sequence/sequence.constants';
import { getCopyableCells } from '@/services/bll/BpmMxEditorBLLService';
import { MxClipboard } from '@/mxgraph/mxgraph';
import { addMenuEdgeTypesForSequence, isAllowForkConnection } from './editorPopupMenu.saga.utils';
import { BPMMxConstants } from '@/mxgraph/bpmgraph.constants';
import { LocalesService } from '@/services/LocalesService';
import { SequenceGraph } from '@/mxgraph/SequenceGraph/SequenceGraph';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { EdgesForChangeSelectors } from '../selectors/edgesForChangeType.selectors';
import { sortByEdgeTypeName } from '@/modules/AdminTools/Methodology/components/Presets/ModelType/utils/modelTypes.utils';

type TPopupMenuParams = {
    graph: BPMMxGraph;
    user: UserDTO;
    emitter: any; // tslint:disable-line:no-any
    modelType: ModelType;
    getObjectNode: (nodeId: NodeId) => ObjectDefinitionNode | undefined;
    locale: Locale;
    parentNodeId?: NodeId;
    printable?: boolean;
};

function createPopupMenuHandler({
    graph,
    user,
    emitter,
    modelType,
    getObjectNode,
    locale,
    parentNodeId,
    printable,
}: TPopupMenuParams): BPMMxPopupMenuHandler {
    const createPopupMenu = (graph: BPMMxGraph | SequenceGraph, menu: MxPopupMenu, cell: MxCell) => {
        const mode = graph.mode || EditorMode.Read;
        const isWhiteBoard: boolean = graph?.modelType?.id === ModelTypes.MIND_MAP;

        graph.hidePopupMenuHandler(menu);

        if (graph.connectionHandler?.edgeState) {
            const { connectionHandler } = graph;
            sortByEdgeTypeName(connectionHandler.availableEdgeTypes).forEach((edgeType: EdgeType, index: number) => {
                const title =
                    edgeType.id === BPMMxConstants.AUTO_EDGE_TYPE_ID
                        ? graph.intl.formatMessage(messages.edgeTypeAuto)
                        : LocalesService.internationalStringToString(edgeType.multilingualName, locale);

                menu.addItem(
                    title,
                    null,
                    () => {
                        connectionHandler.changeEdgeType(edgeType);
                    },
                    null,
                    '',
                    undefined,
                    undefined,
                    undefined,
                    `${index}`,
                );
            });

            return;
        }

        const generatePasteMenu = () => {
            const state = getStore().getState();
            const hasCopiedElements = getCopiedElements(state).length > 0 || (isWhiteBoard && !MxClipboard.isEmpty());

            menu.autoExpand = true;
            menu.addItem(
                graph.intl.formatMessage(messages.paste),
                null,
                () => emitter(pasteAction()),
                null,
                '',
                mode === EditorMode.Edit && hasCopiedElements,
                undefined,
                'graph-element_context-menu_paste',
            );

            const subMenu = menu.addItem(
                graph.intl.formatMessage(messages.pasteAs),
                null,
                () => noop,
                null,
                '',
                mode === EditorMode.Edit && hasCopiedElements,
            );
            menu.addItem(
                graph.intl.formatMessage(messages.pasteAsInstanceCopy),
                null,
                () => emitter(pasteAction()),
                subMenu,
                '',
                mode === EditorMode.Edit && hasCopiedElements,
            );
            menu.addItem(
                graph.intl.formatMessage(messages.pasteAsDefinitionCopy),
                null,
                () => emitter(pasteAction(true)),
                subMenu,
                null,
                mode === EditorMode.Edit && hasCopiedElements && !isWhiteBoard,
            );

            const subSpaceMenu = menu.addItem(
                graph.intl.formatMessage(messages.space),
                null,
                () => noop,
                null,
                '',
                mode === EditorMode.Edit,
            );
            menu.addItem(
                graph.intl.formatMessage(messages.insertSpaceHorizontally),
                null,
                () => emitter(processSpaceAction(SpaceAction.InsertHorizontal)),
                subSpaceMenu,
                '',
                mode === EditorMode.Edit,
            );
            menu.addItem(
                graph.intl.formatMessage(messages.insertSpaceVertical),
                null,
                () => emitter(processSpaceAction(SpaceAction.InsertVervical)),
                subSpaceMenu,
                null,
                mode === EditorMode.Edit,
            );
            menu.addItem(
                graph.intl.formatMessage(messages.deleteSpaceHorizontally),
                null,
                () => emitter(processSpaceAction(SpaceAction.DeleteHorizontal)),
                subSpaceMenu,
                null,
                mode === EditorMode.Edit,
            );
            menu.addItem(
                graph.intl.formatMessage(messages.deleteSpaceVertical),
                null,
                () => emitter(processSpaceAction(SpaceAction.DeleteVertical)),
                subSpaceMenu,
                null,
                mode === EditorMode.Edit,
            );
            menu.addSeparator(null, true);
            menu.addItem(
                graph.intl.formatMessage(messages.copyLink),
                null,
                () => emitter(copyLinkAction(graph.id, TreeItemType.Model)),
                null,
                '',
                true,
                undefined,
                'graph-element_context-menu_copy-link',
            );
            menu.addItem(
                graph.intl.formatMessage(messages.historyLog),
                null,
                () => emitter(initHistoryDialogAction(graph.id)),
                null,
                '',
                true,
            );
            const imgSubSpaceMenu = menu.addItem(
                graph.intl.formatMessage(messages.getImage),
                null,
                () => noop,
                null,
                '',
                printable,
            );
            menu.addItem(
                graph.intl.formatMessage(messages.imagePng),
                null,
                () => emitter(saveImage('png', graph.id)),
                imgSubSpaceMenu,
                '',
                printable,
            );
            menu.addItem(
                graph.intl.formatMessage(messages.imageJpeg),
                null,
                () => emitter(saveImage('jpeg', graph.id)),
                imgSubSpaceMenu,
                '',
                printable,
            );
            menu.addItem(
                graph.intl.formatMessage(messages.imageSvg),
                null,
                () => emitter(saveImageSvg(graph.id)),
                imgSubSpaceMenu,
                '',
                printable,
            );
            menu.addItem(
                graph.intl.formatMessage(messages.imagePdf),
                null,
                () => emitter(savePdf(graph.id)),
                imgSubSpaceMenu,
                '',
                printable,
            );
            menu.addItem(
                graph.intl.formatMessage(messages.imagePrint),
                null,
                () => emitter(printModel(graph.id)),
                imgSubSpaceMenu,
                '',
                printable,
            );
            menu.addItem(
                graph.intl.formatMessage(messages.imageToClipboard),
                null,
                () => emitter(imageToClipboard(graph.id)),
                imgSubSpaceMenu,
                '',
                printable,
            );
            menu.addItem(
                graph.intl.formatMessage(messages.getImageSettings),
                null,
                () => emitter(openImageDownloadSettings()),
                imgSubSpaceMenu,
                '',
                printable,
            );
            // Меню скриптов при клике ПКМ на холсте
            const scriptSubSpaceMenu = menu.addItem(
                graph.intl.formatMessage(messages.scriptMenuName),
                null,
                () => noop,
                null,
                '',
                true,
                undefined,
                'graph-element_context-menu_EXECUTE_SCRIPT',
            );
            menu.addItem(
                graph.intl.formatMessage(messages.executeScript),
                null,
                () =>
                    emitter(
                        scriptSelectDialogInit({
                            serverId: graph.bpmMxGraphContext.serverId,
                            nodeId: graph.id,
                            nodesIdsList: [graph.id],
                            modelId: graph.id,
                            element: undefined,
                            rootType: TreeItemType.ScriptFolder,
                            scriptType: TreeItemType.Script,
                        }),
                    ),
                scriptSubSpaceMenu,
                '',
                true,
                undefined,
                'graph-element_context-menu_options_EXECUTE_SCRIPT',
            );
            menu.addItem(
                graph.intl.formatMessage(messages.scheduleScript),
                null,
                () =>
                    emitter(
                        openDialog(DialogType.SCHEDULE_SCRIPT_DIALOG, {
                            serverId: graph.bpmMxGraphContext.serverId,
                            nodeId: graph.id,
                            nodesIdsList: [graph.id],
                            modelId: graph.id,
                            element: undefined,
                            rootType: TreeItemType.ScriptFolder,
                            scriptType: TreeItemType.Script,
                        }),
                    ),
                scriptSubSpaceMenu,
                '',
                true,
                undefined,
                'graph-element_context-menu_options_SCHEDULE_SCRIPT',
            );
            menu.addItem(
                graph.intl.formatMessage(messages.edgeManagement),
                null,
                () => emitter(openEdgeManagementDialog()),
                null,
                '',
                mode === EditorMode.Edit,
            );
            menu.addSeparator(null, true);
            menu.addItem(
                `${graph.intl.formatMessage(messages.properties)} ${graph.intl.formatMessage(messages.hotKeys)}`,
                null,
                () => emitter(viewModelProperties(graph.id)),
                null,
                '',
                true,
                undefined,
                'properties_graph_context-menu',
            );
        };

        if (cell != null) {
            const isNotAccessSymbol: boolean = isNotAccessSymbolStyle(cell.style);
            const isNotReadableSymbol: boolean = isNotReadableSymbolStyle(cell.style);

            if (isNotAccessSymbol || isNotReadableSymbol) return;

            const { serverId } = graph.bpmMxGraphContext;
            const state = getStore().getState();
            const presetId: string | undefined = TreeSelectors.presetById(graph.id)(state);
            const value: DiagramElement | undefined = cell.getValue();
            const edgeDefinitionId: string | undefined = (value as EdgeInstance | undefined)?.edgeDefinitionId;
            const objectDefinitionId: string | undefined = (value as ObjectInstance | undefined)?.objectDefinitionId;
            const objectDefinitionNodeId: NodeId | undefined = objectDefinitionId
                ? ({ ...parentNodeId, id: objectDefinitionId } as NodeId)
                : undefined;

            const isShape: boolean = !value?.type || !objectDefinitionId || value?.type === SymbolType.SHAPE;
            const isStyleEditableCell = ComplexSymbolManager.isCellStyleEditable(cell);
            const hasCopyableCells = getCopyableCells(graph, graph.getSelectionCells()).length > 0;
            const isSequenceExecutionSymbol: boolean = SequenceUtils.isSequenceExecutionSymbol(cell);
            const isSequenceDiagramCell: boolean = SequenceUtils.isSequenceDiagramCell(cell);
            const isUmlMessage: boolean = SequenceUtils.isUmlMessage(cell);
            const destroySymbol: MxCell | undefined = LifeLineUtils.getDestroySymbol(cell);
            const isDestroySymbol = LifeLineUtils.getDestroySymbolId(cell.parent) === cell.id;
            const isDefaultSymbol: boolean = isSymbolHasNotDefinition(cell.style);
            const isSymbolEditable: boolean = UserProfileSelectors.isSymbolEditable(
                objectDefinitionNodeId,
                (value as ObjectInstance)?.symbolId,
            )(state);
            const isEditableNotDefaultSymbol: boolean = isSymbolEditable && !isDefaultSymbol;
            const complexSymbol = ComplexSymbolManager.getComplexSymbolInstance(cell) as LifelineSymbolMainClass | null;
            const objectInstance = cell.value as ObjectInstance;
            const profile: TCurrentUserProfile | undefined =
                UserProfileSelectors.selectUserProfileByNodeId(objectDefinitionNodeId)(state);
            const isModelEditor: boolean = hasModelEditorLicense(state);
            let symbols: Symbol[] = [];
            let availableSymbols: Symbol[] = [];

            const selectedElementsIds: string[] = [];
            const selectedElementsDefinitionsIds: NodeId[] = [];
            graph.getSelectionCells().forEach((selectedCell) => {
                const cellValue = selectedCell.getValue();
                if (cellValue && cellValue.id) {
                    selectedElementsIds.push(cellValue.id);
                    if (selectedCell.isEdge() && (cellValue as EdgeInstance)?.edgeDefinitionId) {
                        selectedElementsDefinitionsIds.push({
                            ...parentNodeId,
                            id: cellValue.edgeDefinitionId,
                        } as NodeId);
                    }
                    if (!selectedCell.isEdge() && (cellValue as ObjectInstance)?.objectDefinitionId) {
                        selectedElementsDefinitionsIds.push({
                            ...parentNodeId,
                            id: cellValue.objectDefinitionId,
                        } as NodeId);
                    }
                }
            });

            if (objectDefinitionNodeId) {
                const obj = getObjectNode(objectDefinitionNodeId);
                if (obj) {
                    const objectInstanceValue: ObjectInstanceImpl = value as ObjectInstanceImpl;
                    symbols = getSymbolsFromModelType(modelType)
                        .filter((sym) => sym.objectType && sym.objectType === obj.objectTypeId)
                        .filter((sym) => !objectInstanceValue || sym.id !== objectInstanceValue.symbolId);
                    availableSymbols = AvailableConnectionForSymbolsBLLService.getSymbolsForConnection(
                        modelType,
                        objectInstanceValue?.symbolId,
                        obj.objectTypeId,
                        profile?.symbolAcls,
                        profile?.edgeTypeAcls,
                    );
                }
            }

            if (!cell.isEdge()) {
                if (!((value instanceof LayoutInstanceImpl && value.isPSDCell) || value instanceof CommentMarker)) {
                    if (!isShape && !isSequenceDiagramCell && !isUmlMessage && availableSymbols.length > 0) {
                        const connectedElementMenu = menu.addItem(
                            graph.intl.formatMessage(messages.createConnectedElement),
                            null,
                            () => {},
                            null,
                            '',
                            mode === EditorMode.Edit && availableSymbols.length > 0 && isSymbolEditable,
                            undefined,
                            'graph-element_context-menu_create-connected-element',
                        );
                        availableSymbols.forEach((sym) => {
                            menu.addItem(
                                sym.name,
                                sym.icon,
                                () => {
                                    emitter(createDiagramElement(sym, cell));
                                },
                                connectedElementMenu,
                                '',
                            );
                        });
                    }

                    if (!isShape && isStyleEditableCell) {
                        const symbolsAvailableToReplace = symbols.filter(ComplexSymbolManager.isSymbolWithPreview);
                        const symbolChangeSubMenu = menu.addItem(
                            graph.intl.formatMessage(messages.replaceSymbol),
                            null,
                            () => {},
                            null,
                            '',
                            mode === EditorMode.Edit && symbolsAvailableToReplace.length > 0 && !isDefaultSymbol,
                        );
                        if (symbolsAvailableToReplace.length > 0) {
                            symbolsAvailableToReplace.forEach((sym) => {
                                menu.addItem(
                                    sym.name,
                                    sym.icon,
                                    () => {
                                        emitter(changeSymbolForCell(cell.getId(), graph.id, sym));
                                    },
                                    symbolChangeSubMenu,
                                    '',
                                    isSymbolEditable,
                                );
                            });
                        }
                    }
                    if (isUmlMessage) {
                        addMenuEdgeTypesForSequence(menu, graph, cell, locale, emitter, mode);
                    }

                    if (isSequenceDiagramCell) {
                        const createElement = menu.addItem(
                            graph.intl.formatMessage(messages.createElement),
                            null,
                            () => {},
                            null,
                            '',
                            mode === EditorMode.Edit,
                        );

                        addMenuEdgeTypesForSequence(menu, graph, cell, locale, emitter, mode);

                        if (!isSequenceExecutionSymbol)
                            menu.addItem(
                                graph.intl.formatMessage(messages.createExecutionSymbol),
                                null,
                                () => complexSymbol?.addExecutionSymbol && complexSymbol.addExecutionSymbol(cell),
                                createElement,
                                '',
                            );

                        const { attributes } = objectInstance;
                        const destructionAttribute = attributes?.find(
                            (attr) => attr.typeId === UML_DESTROY_ATTRIBUTE_TYPE_ID,
                        );
                        menu.addItem(
                            graph.intl.formatMessage(messages.decomposition),
                            null,
                            () => {
                                if (objectDefinitionNodeId) {
                                    emitter(
                                        objectDecompositionDialogInit({
                                            objectDefinitionId: objectDefinitionNodeId,
                                            graphId: graph.id,
                                        }),
                                    );
                                }
                            },
                            null,
                            '',
                            isEditableNotDefaultSymbol && isModelEditor,
                            undefined,
                            'graph-element_context-menu_decomposition',
                        );

                        menu.addSeparator(null, true);

                        if (cell.complexSymbolTypeId !== SequenceSymbolTypeId.LIFE_LINE_ACTOR && complexSymbol) {
                            if (destroySymbol) {
                                menu.addItem(
                                    graph.intl.formatMessage(messages.deleteDestroySymbol),
                                    null,
                                    () => {
                                        if (complexSymbol.deleteDestroySymbol) {
                                            complexSymbol.deleteDestroySymbol(cell);
                                        }
                                        if (destructionAttribute) {
                                            emitter(
                                                upsertAttributeValue({
                                                    graphId: graph.id,
                                                    cellId: cell.id,
                                                    presetId,
                                                    newAttributeValue: {
                                                        ...destructionAttribute,
                                                        value: 'false',
                                                    },
                                                }),
                                            );
                                        }
                                    },
                                    null,
                                    '',
                                    mode === EditorMode.Edit,
                                );
                            } else {
                                menu.addItem(
                                    graph.intl.formatMessage(messages.createDestroySymbol),
                                    null,
                                    () => {
                                        // eslint-disable-next-line no-unused-expressions
                                        complexSymbol.createDestroySymbol && complexSymbol.createDestroySymbol(cell);

                                        if (destructionAttribute) {
                                            emitter(
                                                upsertAttributeValue({
                                                    graphId: graph.id,
                                                    cellId: cell.id,
                                                    presetId,
                                                    newAttributeValue: {
                                                        ...destructionAttribute,
                                                        value: 'true',
                                                    },
                                                }),
                                            );
                                        }
                                    },
                                    null,
                                    '',
                                    mode === EditorMode.Edit,
                                );
                            }
                            menu.addSeparator(null, true);
                        }
                    } else if (!isShape) {
                        menu.addItem(
                            graph.intl.formatMessage(messages.decomposition),
                            null,
                            () => {
                                if (objectDefinitionNodeId) {
                                    emitter(
                                        objectDecompositionDialogInit({
                                            objectDefinitionId: objectDefinitionNodeId,
                                            graphId: graph.id,
                                        }),
                                    );
                                }
                            },
                            null,
                            '',
                            isEditableNotDefaultSymbol && isModelEditor,
                            undefined,
                            'graph-element_context-menu_decomposition',
                        );
                        menu.addSeparator(null, true);
                    }

                    graph.loadPopupMenu(menu, cell, mode !== EditorMode.Edit);

                    if (
                        cell.complexSymbolTypeId &&
                        Object.values(SequenceSymbolTypeId).includes(cell.complexSymbolTypeId as SequenceSymbolTypeId)
                    ) {
                        menu.addItem(
                            graph.intl.formatMessage(messages.renameObject),
                            null,
                            () => {
                                emitter(openDialog(DialogType.RENAME_OBJECT_DIALOG, { graph, cell }));
                            },
                            null,
                            '',
                            true,
                        );
                    }

                    menu.addItem(
                        graph.intl.formatMessage(messages.objectInTree),
                        null,
                        () => {
                            emitter(moveToAction(MoveTo.ObjectInTree));
                        },
                        null,
                        '',
                        !isShape || !!(cell?.value as ShapeInstance)?.imageId,
                        undefined,
                        'graph-element_context-menu_find-in-tree',
                    );

                    menu.addItem(
                        graph.intl.formatMessage(messages.copy),
                        null,
                        () => {
                            emitter(copyAction());
                        },
                        null,
                        '',
                        hasCopyableCells,
                        undefined,
                        'graph-element_context-menu_copy',
                    );

                    menu.addItem(
                        graph.intl.formatMessage(messages.copyLink),
                        null,
                        () => {
                            emitter(copyLinkAction(graph.id, TreeItemType.Model, value?.id));
                        },
                        null,
                        '',
                        true,
                        undefined,
                        'graph-element_context-menu_copy-link',
                    );

                    if (!isWhiteBoard && !isShape) {
                        menu.addItem(
                            graph.intl.formatMessage(messages.copyID),
                            null,
                            () => {
                                emitter(
                                    copyToClipboard({
                                        modelId: graph.id,
                                        objectDiagramElement: value as ObjectInstanceImpl,
                                    }),
                                );
                            },
                            null,
                            '',
                            !isDefaultSymbol,
                            undefined,
                            'graph-element_context-menu_copy-ID',
                        );
                    }

                    const scriptSubSpaceMenu = menu.addItem(
                        graph.intl.formatMessage(messages.scriptMenuName),
                        null,
                        () => noop,
                        null,
                        '',
                        true,
                        undefined,
                        'graph-element_context-menu_EXECUTE_SCRIPT',
                    );

                    if (isShape) {
                        menu.addItem(
                            // запуск скрипта для Shape
                            graph.intl.formatMessage(messages.executeScript),
                            null,
                            () => {
                                emitter(
                                    scriptSelectDialogInit({
                                        serverId,
                                        nodeId: undefined,
                                        modelId: graph.id,
                                        element: value,
                                        elementsIdsList: selectedElementsIds,
                                        nodesIdsList: selectedElementsDefinitionsIds,
                                        rootType: TreeItemType.ScriptFolder,
                                        scriptType: TreeItemType.Script,
                                    }),
                                );
                            },
                            scriptSubSpaceMenu,
                            undefined,
                            undefined,
                            undefined,
                            'graph-element_context-menu_options_EXECUTE_SCRIPT',
                        );

                        menu.addItem(
                            // планирование скрипта для Shape
                            graph.intl.formatMessage(messages.scheduleScript),
                            null,
                            () =>
                                emitter(
                                    openDialog(DialogType.SCHEDULE_SCRIPT_DIALOG, {
                                        serverId,
                                        nodeId: undefined,
                                        modelId: graph.id,
                                        element: value,
                                        elementsIdsList: selectedElementsIds,
                                        nodesIdsList: selectedElementsDefinitionsIds,
                                        rootType: TreeItemType.ScriptFolder,
                                        scriptType: TreeItemType.Script,
                                    }),
                                ),
                            scriptSubSpaceMenu,
                            undefined,
                            undefined,
                            undefined,
                            'graph-element_context-menu_options_SCHEDULE_SCRIPT',
                        );
                    }

                    if (!isShape) {
                        menu.addItem(
                            // запуск скрипта для объекта
                            graph.intl.formatMessage(messages.executeScript),
                            null,
                            () => {
                                if (objectDefinitionNodeId) {
                                    emitter(
                                        scriptSelectDialogInit({
                                            serverId,
                                            nodeId: objectDefinitionNodeId,
                                            modelId: graph.id,
                                            element: value,
                                            elementsIdsList: selectedElementsIds,
                                            nodesIdsList: selectedElementsDefinitionsIds,
                                            rootType: TreeItemType.ScriptFolder,
                                            scriptType: TreeItemType.Script,
                                        }),
                                    );
                                }
                            },
                            scriptSubSpaceMenu,
                            undefined,
                            undefined,
                            undefined,
                            'graph-element_context-menu_options_EXECUTE_SCRIPT',
                        );

                        menu.addItem(
                            // запуск скрипта для объекта
                            graph.intl.formatMessage(messages.scheduleScript),
                            null,
                            () => {
                                if (objectDefinitionNodeId) {
                                    emitter(
                                        openDialog(DialogType.SCHEDULE_SCRIPT_DIALOG, {
                                            serverId,
                                            nodeId: objectDefinitionNodeId,
                                            modelId: graph.id,
                                            element: value,
                                            elementsIdsList: selectedElementsIds,
                                            nodesIdsList: selectedElementsDefinitionsIds,
                                            rootType: TreeItemType.ScriptFolder,
                                            scriptType: TreeItemType.Script,
                                        }),
                                    );
                                }
                            },
                            scriptSubSpaceMenu,
                            undefined,
                            undefined,
                            undefined,
                            'graph-element_context-menu_options_SCHEDULE_SCRIPT',
                        );
                    }

                    if (isSequenceExecutionSymbol && !isDestroySymbol && complexSymbol) {
                        menu.addSeparator(null, true);
                        if (currentTreeDepth(cell) < 3) {
                            const createElement = menu.addItem(
                                graph.intl.formatMessage(messages.createElement),
                                null,
                                () => {},
                                null,
                                '',
                                mode === EditorMode.Edit,
                            );

                            menu.addItem(
                                graph.intl.formatMessage(messages.createExecutionSymbol),
                                null,
                                () => complexSymbol.addExecutionSymbol && complexSymbol.addExecutionSymbol(cell),
                                createElement,
                                '',
                            );
                        }

                        addMenuEdgeTypesForSequence(menu, graph, cell, locale, emitter, mode);

                        menu.addItem(
                            graph.intl.formatMessage(messages.splitExecutionSymbol),
                            null,
                            () => complexSymbol.splitExecutionSymbol && complexSymbol.splitExecutionSymbol(cell),
                            null,
                            '',
                        );
                    }

                    menu.addSeparator(null, true);

                    const isSymbolDeletable = UserProfileSelectors.isSymbolDeletable(
                        objectDefinitionNodeId,
                        (value as ObjectInstance)?.symbolId,
                    )(state);

                    menu.addItem(
                        graph.intl.formatMessage(messages.delete),
                        null,
                        () => {
                            emitter(handleDeleteSelectedCellsFromActiveGraphAction());
                        },
                        null,
                        '',
                        mode === EditorMode.Edit && isSymbolDeletable && isSymbolEditable && !isDestroySymbol,
                        undefined,
                        `graph-element_context-menu_delete`,
                        'delete',
                    );

                    if (!isShape) {
                        menu.addSeparator(null, true);

                        const isSymbolReadable = UserProfileSelectors.isSymbolReadable(
                            objectDefinitionNodeId,
                            (value as ObjectInstance)?.symbolId,
                        )(state);

                        menu.addItem(
                            `${graph.intl.formatMessage(messages.properties)} ${graph.intl.formatMessage(
                                messages.hotKeys,
                            )}`,
                            null,
                            () => {
                                emitter(objectPropertyView(cell.id));
                            },
                            null,
                            '',
                            isSymbolReadable,
                            undefined,
                            'properties_graph-element_context-menu',
                        );
                    }
                }
            }

            if (cell.isEdge()) {
                const isEdgeTypeEditable =
                    !!presetId &&
                    UserProfileSelectors.isEdgeTypeEditable(
                        serverId,
                        presetId,
                        (value as EdgeInstanceImpl).edgeTypeId,
                    )(state);
                const isEdgeTypeCreateable =
                    !!presetId &&
                    UserProfileSelectors.isEdgeTypeCreateable(
                        serverId,
                        presetId,
                        (value as EdgeInstanceImpl).edgeTypeId,
                    )(state);

                if (edgeDefinitionId) {
                    menu.addItem(
                        graph.intl.formatMessage(messages.decomposition),
                        null,
                        () => {
                            emitter(
                                objectDecompositionDialogInit({
                                    graphId: graph.id,
                                    edgeDefinitionId: {
                                        id: edgeDefinitionId!,
                                        serverId: graph.id.serverId,
                                        repositoryId: graph.id.repositoryId,
                                    },
                                }),
                            );
                        },
                        null,
                        '',
                        isEdgeTypeEditable && isModelEditor && !!(value as EdgeInstanceImpl)?.edgeTypeId,
                        undefined,
                        'graph-element_context-menu_decomposition',
                    );
                    menu.addSeparator(null, true);
                }

                menu.addItem(
                    graph.intl.formatMessage(messages.objectInTree),
                    null,
                    () => {
                        emitter(moveToAction(MoveTo.ObjectInTree));
                    },
                    null,
                    '',
                    !!edgeDefinitionId,
                    undefined,
                    'graph-element_context-menu_find-in-tree',
                );

                const isEdgeTypeDeletable =
                    !!presetId &&
                    UserProfileSelectors.isEdgeTypeDeletable(
                        serverId,
                        presetId,
                        (value as EdgeInstanceImpl)?.edgeTypeId,
                    )(state);
                menu.addSeparator(null, true);

                const scriptSubSpaceMenu = menu.addItem(
                    graph.intl.formatMessage(messages.scriptMenuName),
                    null,
                    () => noop,
                    null,
                    '',
                    true,
                    undefined,
                    'graph-element_context-menu_EXECUTE_SCRIPT',
                );

                menu.addItem(
                    // запуск скрипта для связи
                    graph.intl.formatMessage(messages.executeScript),
                    null,
                    () => {
                        emitter(
                            scriptSelectDialogInit({
                                nodeId: edgeDefinitionId
                                    ? {
                                          ...graph.id,
                                          id: edgeDefinitionId,
                                      }
                                    : undefined,
                                serverId,
                                modelId: graph.id,
                                element: value,
                                elementsIdsList: selectedElementsIds,
                                nodesIdsList: selectedElementsDefinitionsIds,
                                rootType: TreeItemType.ScriptFolder,
                                scriptType: TreeItemType.Script,
                            }),
                        );
                    },
                    scriptSubSpaceMenu,
                    undefined,
                    undefined,
                    undefined,
                    'graph-element_context-menu_options_EXECUTE_SCRIPT',
                );

                menu.addItem(
                    // запуск скрипта для связи
                    graph.intl.formatMessage(messages.scheduleScript),
                    null,
                    () => {
                        const payload: TScriptSelectDialogInitPayload = {
                            serverId,
                            modelId: graph.id,
                            element: value,
                            elementsIdsList: selectedElementsIds,
                            nodesIdsList: selectedElementsDefinitionsIds,
                            rootType: TreeItemType.ScriptFolder,
                            scriptType: TreeItemType.Script,
                        };

                        if (edgeDefinitionId)
                            payload.nodeId = {
                                ...graph.id,
                                id: edgeDefinitionId,
                            };

                        emitter(openDialog(DialogType.SCHEDULE_SCRIPT_DIALOG, payload));
                    },
                    scriptSubSpaceMenu,
                    undefined,
                    undefined,
                    undefined,
                    'graph-element_context-menu_options_SCHEDULE_SCRIPT',
                );

                menu.addSeparator(null, true);

                menu.addItem(
                    // cоздание определения связи
                    graph.intl.formatMessage(messages.createEdgeDefinition),
                    null,
                    () => {
                        emitter(initEdgeDefinitionCreation({ graphId: graph.id, cell }));
                    },
                    null,
                    '',
                    mode === EditorMode.Edit &&
                        !edgeDefinitionId &&
                        isEdgeTypeEditable &&
                        isEdgeTypeCreateable &&
                        value instanceof EdgeInstanceImpl,
                    () => {
                        let tooltip = '';
                        if (mode !== EditorMode.Edit) {
                            tooltip = graph.intl.formatMessage(messages.onlyInEditModeAvailable);
                        } else if (edgeDefinitionId) {
                            tooltip = graph.intl.formatMessage(messages.edgeDefinitionExist);
                        } else if (!(isEdgeTypeEditable && isEdgeTypeCreateable)) {
                            tooltip = graph.intl.formatMessage(messages.deniedByProfile);
                        } else if (!(cell.value instanceof EdgeInstanceImpl)) {
                            tooltip = graph.intl.formatMessage(messages.deniedByEdgeType);
                        }

                        return tooltip;
                    },
                    `graph-element_context-menu_create-definition`,
                );
                menu.addItem(
                    graph.intl.formatMessage(messages.copyLink),
                    null,
                    () => {
                        emitter(copyLinkAction(graph.id, TreeItemType.Model, value?.id));
                    },
                    null,
                    '',
                    true,
                    undefined,
                    'graph-element_context-menu_copy-link',
                );
                menu.addItem(
                    graph.intl.formatMessage(messages.delete),
                    null,
                    () => {
                        emitter(handleDeleteSelectedCellsFromActiveGraphAction());
                    },
                    null,
                    '',
                    mode === EditorMode.Edit && isEdgeTypeDeletable && !isDestroySymbol,
                    undefined,
                    `graph-element_context-menu_delete`,
                );
                menu.addSeparator(null, true);
                const { source } = cell;
                const sourceComplexSymbol = ComplexSymbolManager.getComplexSymbolInstance(source);
                menu.addItem(
                    graph.intl.formatMessage(messages.edgeFork),
                    null,
                    () => {
                        (sourceComplexSymbol as LifelineSymbolMainClass).startForkConnection(cell);
                    },
                    null,
                    '',
                    mode === EditorMode.Edit && isAllowForkConnection(cell),
                    undefined,
                    `graph-element_context-menu_edge-fork`,
                );

                const availableTypes = sortByEdgeTypeName(
                    getGeneralAvailableEdgeTypeSelector(getStore().getState()).filter(
                        (type): type is EdgeType => !!type,
                    ),
                );

                const selectedEdges: EdgeInstanceImpl[] = graph
                    .getSelectionModel()
                    .cells.filter((cell) => cell.isEdge())
                    .map((edge) => edge.getValue())
                    .filter((edgeValue) => edgeValue);
                const connectedWithUmlMessage =
                    SequenceUtils.isUmlMessage(cell?.target) || SequenceUtils.isUmlMessage(cell?.source);
                const tooltipMessage: string = EdgesForChangeSelectors.getTooltipMessage(
                    selectedEdges,
                    availableTypes.length >= 1,
                    mode,
                )(state);
                // если tooltipMessage не пустая строка, то значит получили подсказку по заблокированной кнопке
                // ниже используется как флаг - меню заблокировано если есть подсказка
                const isEdgesBlocked: boolean = !!tooltipMessage;
                const enabled: boolean = mode === EditorMode.Edit && !isEdgesBlocked && !connectedWithUmlMessage;
                const Submenu = menu.addItem(
                    graph.intl.formatMessage(messages.typeEdge),
                    null,
                    () => {},
                    null,
                    '',
                    enabled,
                    () => tooltipMessage,
                    `graph-element_context-menu_edge-type`,
                );

                if (enabled)
                    availableTypes.forEach((el) => {
                        if (el.id !== SequenceEdgeTypesId.RECURSIVE_MESSAGE)
                            menu.addItem(
                                el.localizableName,
                                null,
                                () => {
                                    emitter(changeEdgeType(el.id));
                                },
                                Submenu,
                                '',
                                true,
                                undefined,
                                `graph-element_context-menu_edge-type_option`,
                            );
                    });

                menu.addSeparator(null, true);

                const isEdgeTypeReadable =
                    !!presetId &&
                    UserProfileSelectors.isEdgeTypeReadable(
                        serverId,
                        presetId,
                        (value as EdgeInstanceImpl)?.edgeTypeId,
                    )(state);

                menu.addItem(
                    graph.intl.formatMessage(messages.properties),
                    null,
                    () => {
                        emitter(objectPropertyView(cell.id));
                    },
                    null,
                    '',
                    // для whiteboard не работают свойства связи если добавить к связи текст BPM-2544
                    !isWhiteBoard && isEdgeTypeReadable,
                    undefined,
                    'properties_graph-element_context-menu',
                );
            }

            if (value instanceof CommentMarker) {
                const isAuthor = value.comment.author === user.login;
                menu.addItem(
                    graph.intl.formatMessage(messages.deleteCommentMarker),
                    null,
                    () => {
                        emitter(deleteCommentMarker(value.comment.commentId));
                    },
                    null,
                    '',
                    isAuthor,
                    undefined,
                    'graph-element_context-menu_delete-marker',
                );
            }

            if (value instanceof LayoutInstanceImpl) {
                if (
                    value.isPSDCell &&
                    value.psdCellMetaInfo!.type !== psdCellType.MAIN_TABLE &&
                    value.psdCellMetaInfo!.type !== psdCellType.BPMN_POOL &&
                    value.psdCellMetaInfo!.type !== psdCellType.BPMN_LANE
                ) {
                    menu.addSeparator(null, true);
                    menu.addItem(
                        graph.intl.formatMessage(messages.psdAddColumn),
                        null,
                        () => {
                            emitter(addTableColumn(cell.getId()));
                        },
                        null,
                        '',
                        mode === EditorMode.Edit,
                    );
                    menu.addItem(
                        graph.intl.formatMessage(messages.psdAddRow),
                        null,
                        () => {
                            emitter(addTableRow(cell.getId()));
                        },
                        null,
                        '',
                        mode === EditorMode.Edit,
                    );
                    menu.addItem(
                        graph.intl.formatMessage(messages.psdRemoveColumn),
                        null,
                        () => {
                            const isChildren = graph.psdDiagramHandler.isChildElementsInVector(cell, false);
                            const index = graph.psdDiagramHandler.findIndexForCell(cell, false);
                            if (index > 0) {
                                emitter(
                                    openDialog(DialogType.PSD_TABLE_DELETE_CONFIRMATION_DIALOG, {
                                        isChildren,
                                        cellId: cell.getId(),
                                        isRow: false,
                                    }),
                                );
                            } else {
                                emitter(
                                    showNotification({
                                        id: uuid(),
                                        type: NotificationType.PSD_DELETE_FIRST_COLUMN_ERROR,
                                    }),
                                );
                            }
                        },
                        null,
                        '',
                        mode === EditorMode.Edit,
                    );
                    menu.addItem(
                        graph.intl.formatMessage(messages.psdRemoveRow),
                        null,
                        () => {
                            const isChildren = graph.psdDiagramHandler.isChildElementsInVector(cell, true);
                            const index = graph.psdDiagramHandler.findIndexForCell(cell, true);
                            if (index > 0) {
                                emitter(
                                    openDialog(DialogType.PSD_TABLE_DELETE_CONFIRMATION_DIALOG, {
                                        isChildren,
                                        cellId: cell.getId(),
                                        isRow: true,
                                    }),
                                );
                            } else {
                                emitter(
                                    showNotification({
                                        id: uuid(),
                                        type: NotificationType.PSD_DELETE_FIRST_ROW_ERROR,
                                    }),
                                );
                            }
                        },
                        null,
                        '',
                        mode === EditorMode.Edit,
                    );
                }
                if (
                    value.isPSDCell &&
                    (value.psdCellMetaInfo!.type === psdCellType.BPMN_POOL ||
                        value.psdCellMetaInfo!.type === psdCellType.BPMN_LANE)
                ) {
                    menu.addItem(
                        graph.intl.formatMessage(messages.bpmnAddRow),
                        null,
                        () => {
                            emitter(addBpmnTableRow(cell.getId()));
                        },
                        null,
                        '',
                        mode === EditorMode.Edit,
                    );
                }
                if (value.isPSDCell && value.psdCellMetaInfo!.type === psdCellType.BPMN_LANE) {
                    menu.addItem(
                        graph.intl.formatMessage(messages.bpmnRemoveRow),
                        null,
                        () => {
                            emitter(removeBpmnTableRow(cell.getId()));
                        },
                        null,
                        '',
                        mode === EditorMode.Edit,
                    );
                }
            }
        } else if (graph.bpmMxGraphContext.objectDefinitionCopyPasteContext) {
            generatePasteMenu();
        }
    };

    return graph.createPopupMenuHandler(createPopupMenu);
}

function* handleEditorPopupMenuInit({ payload: { nodeId } }: TEditorInitAction) {
    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(nodeId);
    if (!graph) {
        console.error(`Graph doesn't exist`);

        return;
    }
    const modelContext: IModelContext = yield modelContextByGraphId(nodeId);
    const parentNodeId = modelContext.schema.content && modelContext.schema.content.parentNodeId;
    const user: UserDTO | undefined = yield select(getUser);

    if (!user) return;

    const model: ModelNode | undefined = yield select(ModelSelectors.byId(nodeId));
    const getNode = (objectNodeId: NodeId) => ObjectDefinitionSelectors.byId(objectNodeId)(getStore().getState());
    const locale: Locale = yield select(getCurrentLocale);

    const channel = eventChannel<TAction>((emitter) => {
        const popupMenuHandler = createPopupMenuHandler({
            graph,
            user,
            emitter,
            modelType: graph.modelType!,
            getObjectNode: getNode,
            locale,
            parentNodeId,
            printable: model?.printable,
        });

        return () => {
            popupMenuHandler.destroy();
        };
    });
    try {
        while (true) {
            const action = yield take(channel);
            yield put(action);
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

function* handleActiveContextMenuChange({ payload: { selectedNode } }: TContextMenuChangeAction) {
    const isNodeSelected: boolean = yield select(SelectedNodesSelector.isNodeSelected(selectedNode?.nodeId));

    if (selectedNode && selectedNode.nodeId) {
        if (!isNodeSelected) {
            yield put(treeItemSelect(selectedNode));
        }
    }

    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (graph && graph.popupMenuHandler && graph.popupMenuHandler.isMenuShowing()) {
        graph.popupMenuHandler.hideMenu();
    }
}

export function* editorPopupMenuSaga() {
    yield takeEvery(EDITOR_INIT, handleEditorPopupMenuInit);
    yield takeEvery(ACTIVE_CONTEXT_MENU_CHANGE, handleActiveContextMenuChange);
}
