import {
    OPEN_COMMENTS_PANEL,
    CLOSE_COMMENTS_PANEL,
    ADD_COMMENTS,
    EDIT_COMMENT,
    DELETE_COMMENT,
    SAVE_COMMENT,
    LOAD_COMMENTS,
    CLEAR_COMMENTS,
    CLEAR_STATE_COMMENTS,
    DELETE_COMMENT_MARKER,
    DRAG_COMMENT_MARKER,
    SET_DISPLAY_COMMENT_MARKERS,
    CHANGE_COMMENT_STATUS,
    COMMENTS_PANEL_FOCUS,
    DELETE_COMMENT_SUCCESS,
    PIN_COMMENT,
    UNPIN_COMMENT,
    PIN_COMMENT_SUCCESS,
    UNPIN_COMMENT_SUCCESS,
    CHANGE_EDITING_COMMENT,
    DELETE_EDITING_COMMENT,
    SET_CRITICAL_COMMENT,
    SET_UNCRITICAL_COMMENT,
    DELETE_COMMENT_MARKERS_BY_GRAPH,
    SAVE_UPLOADED_FILES_SUCCESS,
    SAVE_UPLOADED_FILES,
    DELETED_UPLOADED_FILE_SUCCESS,
    RELOAD_COMMENTS,
    START_EDIT_COMMENT,
    SAVE_EDITING_COMMENT,
    CHANGE_IS_GRAPH_ELEMENT,
} from '../actionsTypes/comments.actionTypes';
import { CommentStatus } from '../models/commentMarkerConstants';
import { TEditingCommentFiles } from '../reducers/comments.reducer.types';
import { Comment, CommentFileDTO, NodeId } from '../serverapi/api';
import {
    TCloseCommentsPanelPayload,
    TOpenCommentsPanePayload,
    TAddCommentAction,
    TChangeCommentStatus,
    TClearCommentsAction,
    TCloseCommentsPanelAction,
    TCommentsPanelFocusAction,
    TDeleteCommentAction,
    TDeleteCommentMarkerAction,
    TDragCommentMarkerAction,
    TEditCommentAction,
    TLoadCommentsAction,
    TOpenCommentsPanelAction,
    TSaveCommentAction,
    TSetDisplayCommentMarkersAction,
    TDeleteCommentSuccessAction,
    TPinComment,
    TUnpinComment,
    TPinCommentSuccess,
    TUnpinCommentSuccess,
    TChangeEditingCommentAction,
    TDeleteEditingCommentAction,
    TSetCriticalCommentAction,
    TSetUncriticalCommentAction,
    TDeleteCommentMarkersByActiveGraphAction,
    TClearStateCommentsAction,
    TSaveUploadedFilesSuccessAction,
    TDeleteUploadedFileSuccessAction,
    TSaveUploadedFilesAction,
    TReloadCommentsAction,
    TStartEditCommentAction,
    TSaveEditingCommentAction,
    TChangeIsGraphElementAction,
} from './comments.actions.types';

export const commentsPanelFocus = (): TCommentsPanelFocusAction => ({
    type: COMMENTS_PANEL_FOCUS,
});

export const openCommentsPanel = (payload: TOpenCommentsPanePayload): TOpenCommentsPanelAction => ({
    type: OPEN_COMMENTS_PANEL,
    payload,
});

export const closeCommentsPanel = (payload: TCloseCommentsPanelPayload): TCloseCommentsPanelAction => ({
    type: CLOSE_COMMENTS_PANEL,
    payload,
});

export const addComments = (modelId: NodeId, comments: Comment[]): TAddCommentAction => ({
    type: ADD_COMMENTS,
    payload: {
        modelId,
        comments,
    },
});

export const editComment = (comment: Comment): TEditCommentAction => ({
    type: EDIT_COMMENT,
    payload: {
        comment,
    },
});

export const deleteComment = (modelId: NodeId, commentId: NodeId): TDeleteCommentAction => ({
    type: DELETE_COMMENT,
    payload: {
        modelId,
        commentId,
    },
});

export const deleteCommentSuccess = (modelId: NodeId, commentId: NodeId): TDeleteCommentSuccessAction => ({
    type: DELETE_COMMENT_SUCCESS,
    payload: {
        modelId,
        commentId,
    },
});

export const saveEditingComment = (modelId: NodeId): TSaveEditingCommentAction => ({
    type: SAVE_EDITING_COMMENT,
    payload: {
        modelId
    }
});

export const saveComment = (comment: Comment): TSaveCommentAction => ({
    type: SAVE_COMMENT,
    payload: {
        comment,
    },
});

export const loadComments = (modelId: NodeId): TLoadCommentsAction => ({
    type: LOAD_COMMENTS,
    payload: {
        modelId,
    },
});

export const reloadComments = (modelId: NodeId): TReloadCommentsAction => ({
    type: RELOAD_COMMENTS,
    payload: {
        modelId,
    },
});

export const clearComments = (modelId: NodeId): TClearCommentsAction => ({
    type: CLEAR_COMMENTS,
    payload: {
        modelId,
    },
});

export const clearStateComments = (modelId: NodeId): TClearStateCommentsAction => ({
    type: CLEAR_STATE_COMMENTS,
    payload: {
        modelId,
    },
});

export const deleteCommentMarkersByActiveGraph = (): TDeleteCommentMarkersByActiveGraphAction => ({
    type: DELETE_COMMENT_MARKERS_BY_GRAPH,
});

export const deleteCommentMarker = (commentId: NodeId): TDeleteCommentMarkerAction => ({
    type: DELETE_COMMENT_MARKER,
    payload: {
        commentId,
    },
});

export const dragCommentMarker = (comment: Comment, isMarkerDragging?: boolean): TDragCommentMarkerAction => ({
    type: DRAG_COMMENT_MARKER,
    payload: {
        comment,
        isMarkerDragging
    },
});

export const setDisplayCommentMarkers = (
    commentIds: string[],
    newDisplayStatus: boolean,
): TSetDisplayCommentMarkersAction => ({
    type: SET_DISPLAY_COMMENT_MARKERS,
    payload: {
        commentIds,
        newDisplayStatus,
    },
});

export const changeCommentStatus = (modelId: NodeId, commentId: NodeId, commentStatus: CommentStatus): TChangeCommentStatus => ({
    type: CHANGE_COMMENT_STATUS,
    payload: {
        modelId,
        commentId,
        commentStatus,
    },
});

export const pinComment = (modelId: NodeId, comment: Comment): TPinComment => ({
    type: PIN_COMMENT,
    payload: {
        modelId,
        comment,
    },
});

export const unpinComment = (modelId: NodeId, comment: Comment): TUnpinComment => ({
    type: UNPIN_COMMENT,
    payload: {
        modelId,
        comment,
    },
});

export const pinCommentSuccess = (modelId: NodeId, comment: Comment): TPinCommentSuccess => ({
    type: PIN_COMMENT_SUCCESS,
    payload: {
        modelId,
        comment,
    },
});

export const unpinCommentSuccess = (modelId: NodeId, comment: Comment): TUnpinCommentSuccess => ({
    type: UNPIN_COMMENT_SUCCESS,
    payload: {
        modelId,
        comment,
    },
});

export const startEditComment = (modelId: NodeId, parentId?: string, commentId?: string, threadId?: string, showInTooltip?: boolean, popupStyles?: CSSStyleDeclaration): TStartEditCommentAction => ({
    type: START_EDIT_COMMENT,
    payload: {
        modelId,
        parentId,
        commentId,
        threadId,
        showInTooltip,
        popupStyles
    }
});

export const changeEditingComment = (modelId: NodeId, text?: string, files?: TEditingCommentFiles, threadId?: string): TChangeEditingCommentAction => ({
    type: CHANGE_EDITING_COMMENT,
    payload: {
        modelId,
        text,
        files,
        threadId
    }
});

export const deleteEditingComment = (modelId: NodeId): TDeleteEditingCommentAction => ({
    type: DELETE_EDITING_COMMENT,
    payload: {
        modelId
    }
});

export const setCriticalCommentAction = (modelId: NodeId, commentId: NodeId): TSetCriticalCommentAction => ({
    type: SET_CRITICAL_COMMENT,
    payload: {
        modelId,
        commentId,
    }
});

export const setUncriticalCommentAction = (modelId: NodeId, commentId: NodeId): TSetUncriticalCommentAction => ({
    type: SET_UNCRITICAL_COMMENT,
    payload: {
        modelId,
        commentId,
    }
});

export const saveUploadedFiles = (files: File[], comment: Comment): TSaveUploadedFilesAction=> ({
    type: SAVE_UPLOADED_FILES,
    payload: {
        files,
        comment,
    },
});

export const saveUploadedFilesSuccess = (comment: Comment, files: CommentFileDTO[]): TSaveUploadedFilesSuccessAction => ({
    type: SAVE_UPLOADED_FILES_SUCCESS,
    payload: {
        comment,
        files,
    },
});

export const deleteUploadedFilesSuccess = (fileId: string, commentId: NodeId, modelId: NodeId): TDeleteUploadedFileSuccessAction => ({
    type: DELETED_UPLOADED_FILE_SUCCESS,
    payload: {
        fileId,
        commentId,
        modelId
    },
});

export const changeIsGraphElement = (commentId: NodeId, modelId: NodeId, isGraphElement: boolean): TChangeIsGraphElementAction => ({
    type: CHANGE_IS_GRAPH_ELEMENT,
    payload: {
        isGraphElement,
        commentId,
        modelId
    },
});
