import * as React from 'react';
import { Dialog } from '../UIKit/components/Dialog/Dialog.component';
import theme from './CopyDialog.scss';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import messages from './CopyDialog.messages';
import TextArea from 'antd/es/input/TextArea';
import { TCopyKey, TCopyContent } from './CopyDialog.types';

type TCopyDialogProps = {
    open: boolean;
    content: TCopyContent;
};

type TCopyTitleMessages = {
    [copyKey in TCopyKey]: {
        id: string;
        defaultMessage: string;
    };
};

const copyTitleMessages: TCopyTitleMessages = {
    link: messages.copyLinkTitle,
    id: messages.copyIdTitle,
};

export const CopyDialog: React.FC<TCopyDialogProps> = ({ content, open }) => {
    const [copyKey, copyValue] = content;
    const intl = useIntl();
    const dispatch = useDispatch();

    return (
        <Dialog
            onCancel={() => dispatch(closeDialog(DialogType.COPY_ITEM_DIALOG))}
            open={open}
            footer={false}
            closable={false}
        >
            <div className={theme.about}>
                <h3 className={theme.title}>{intl.formatMessage(copyTitleMessages[copyKey])}</h3>
                <TextArea value={copyValue} autoSize={{ minRows: 3, maxRows: 6 }} />
            </div>
        </Dialog>
    );
};
