import React, { FC } from 'react';
import theme from './AttributeFilter.scss';
import cx from 'classnames';
import messages from './AttributeFilter.messages';
import icDelete from '../../../resources/icons/Deleted.svg';
import { Icon, NUIButton } from '@/modules/UIKit';
import { v4 as uuid } from 'uuid';
import icAdd from '../../../resources/icons/ic-kanban-add.svg';
import { SearchRuleAttributeType, ISearchRuleWithId, TSystemAttributeEntityOptions } from './AttributeFilter.types';
import { AttributeType, AttributeTypeValueTypeEnum, NodeId, SearchRuleQueryRuleEnum } from '@/serverapi/api';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { AttributeValueType, RuleType } from '@/modules/FloatingAttributes/components/AttributesEditor/Attribute.types';
import {
    SYSTEM_ENTITY_ATTRIBUTE_TYPE_IDS,
    getQueryRulesByAttributeType,
    systemAttributeTypes,
} from './AttributeFilter.utils';
import { useIntl } from 'react-intl';
import { AttributeValueInput } from './AttributeValueInput.component';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { ModelTypeSelectors } from '@/selectors/modelType.selectors';
import { ObjectTypeSelectors } from '@/selectors/objectType.selectors';
import { EdgeTypeSelectors } from '@/selectors/edgeType.selectors';
import { SymbolSelectors } from '@/selectors/symbol.selectors';
import { FolderTypeSelectors } from '@/selectors/folderType.selectors';
import { LocalesService } from '@/services/LocalesService';

type TAttributeFilterProps = {
    searchRules: ISearchRuleWithId[];
    setSearchRules: (searchRules: ISearchRuleWithId[]) => void;
    attributeTypes: AttributeType[];
    nodeId: NodeId;
    isHidden: boolean;
};

export const AttributeFilter: FC<TAttributeFilterProps> = (props) => {
    const { searchRules, setSearchRules, attributeTypes, nodeId, isHidden } = props;
    const currentLocale = useSelector(getCurrentLocale);
    const serverId = nodeId?.serverId || '';
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId)) || '';
    const modelTypeOptions: TSystemAttributeEntityOptions[] = (
        useSelector(ModelTypeSelectors.byServerIdPresetIdArr(serverId, presetId)) || []
    ).map((modelType) => ({
        id: modelType.id,
        name: LocalesService.internationalStringToString(modelType.multilingualName) || modelType.name,
    }));
    const objectTypeOptions: TSystemAttributeEntityOptions[] = (
        useSelector(ObjectTypeSelectors.listAllByPreset(serverId, presetId)) || []
    ).map((objectType) => ({
        id: objectType.id,
        name: LocalesService.internationalStringToString(objectType.multilingualName) || objectType.name,
    }));
    const edgeTypeOptions: TSystemAttributeEntityOptions[] = (
        useSelector(EdgeTypeSelectors.listByPresetId(serverId, presetId)) || []
    ).map((edgeType) => ({
        id: edgeType.id,
        name: LocalesService.internationalStringToString(edgeType.multilingualName) || edgeType.name,
    }));
    const symbolOptions: TSystemAttributeEntityOptions[] = (
        useSelector(SymbolSelectors.byServerIdPresetId(serverId, presetId)) || []
    ).map((symbol) => ({
        id: symbol.id,
        name: LocalesService.internationalStringToString(symbol.multilingualName) || symbol.name,
    }));
    const folderTypeOptions: TSystemAttributeEntityOptions[] = (
        useSelector(FolderTypeSelectors.listByPresetId({ serverId, presetId })) || []
    ).map((folderType) => ({
        id: folderType.id,
        name: LocalesService.internationalStringToString(folderType.multilingualName),
    }));

    const intl = useIntl();

    const onAddCondition = () => {
        const hasAttributes = !!attributeTypes.length;
        const attributeTypeId = hasAttributes ? attributeTypes[0]?.id : 'nodeId';
        const attributeType = hasAttributes ? SearchRuleAttributeType.USER : SearchRuleAttributeType.SYSTEM;

        setSearchRules([
            ...searchRules,
            {
                id: uuid(),
                attributeTypeId,
                attributeType,
                queryRule: RuleType.HAS_VALUE,
                values: [],
            },
        ]);
    };

    const onDeleteCondition = (id: string) => {
        setSearchRules(searchRules.filter((rule) => rule.id !== id));
    };

    const onAttributeTypeIdChange = (ruleId: string, attributeTypeId: string) => {
        setSearchRules(
            searchRules.map((rule) => {
                if (rule.id !== ruleId) return rule;

                const attributeType = systemAttributeTypes.some((attr) => attr.id === attributeTypeId)
                    ? SearchRuleAttributeType.SYSTEM
                    : SearchRuleAttributeType.USER;

                const queryRule: RuleType =
                    attributeType === SearchRuleAttributeType.SYSTEM &&
                    SYSTEM_ENTITY_ATTRIBUTE_TYPE_IDS.includes(attributeTypeId)
                        ? RuleType.EQUALS
                        : RuleType.HAS_VALUE;

                return {
                    ...rule,
                    attributeTypeId,
                    attributeType,
                    queryRule,
                    values: [],
                };
            }),
        );
    };

    const onQueryRuleChange = (
        ruleId: string,
        queryRule: SearchRuleQueryRuleEnum,
        valueType: AttributeTypeValueTypeEnum | undefined,
    ) => {
        setSearchRules(
            searchRules.map((rule) => {
                if (rule.id !== ruleId) return rule;
                if (
                    queryRule === RuleType.EQUALS &&
                    valueType === AttributeValueType.BOOLEAN &&
                    rule.values.length === 0
                )
                    return {
                        ...rule,
                        queryRule,
                        values: ['false'],
                    };
                if (queryRule !== RuleType.HAS_VALUE) {
                    return {
                        ...rule,
                        queryRule,
                    };
                }

                return {
                    ...rule,
                    queryRule,
                    values: [],
                };
            }),
        );
    };

    const onAttributeValueChange = (ruleId: string, values: string[]) => {
        setSearchRules(
            searchRules.map((rule) => {
                if (rule.id !== ruleId) return rule;

                return {
                    ...rule,
                    values,
                };
            }),
        );
    };

    const getSystemAttributeEntityOptions = (attributeTypeId: string): TSystemAttributeEntityOptions[] => {
        let result: TSystemAttributeEntityOptions[] = [];

        switch (attributeTypeId) {
            case 'modelTypeId':
                result = modelTypeOptions;
                break;
            case 'objectTypeId':
                result = objectTypeOptions;
                break;
            case 'folderTypeId':
                result = folderTypeOptions;
                break;
            case 'edgeTypeId':
                result = edgeTypeOptions;
                break;
            case 'symbolId':
                result = symbolOptions;
                break;
        }

        return result;
    };

    return (
        <div className={cx(theme.attributeFilterContainer, { [theme.hidden]: isHidden })}>
            {searchRules.length ? (
                <div className={theme.conditionsContainer}>
                    <div className={theme.header}>
                        <div>{intl.formatMessage(messages.attribute)} </div>
                        <div className={theme.conditionTitle}>{intl.formatMessage(messages.condition)}</div>
                        <div className={theme.valueTitle}>{intl.formatMessage(messages.value)}</div>
                    </div>
                    {searchRules.map((rule) => {
                        const isSystemAttribute = rule.attributeType === SearchRuleAttributeType.SYSTEM;
                        const attributeType: AttributeType | undefined = isSystemAttribute
                            ? systemAttributeTypes.find((attrType) => attrType.id === rule.attributeTypeId)
                            : attributeTypes.find((attrType) => attrType.id === rule.attributeTypeId);
                        const isSystemEntityAttributeType =
                            isSystemAttribute && SYSTEM_ENTITY_ATTRIBUTE_TYPE_IDS.includes(attributeType?.id || '');
                        const queryRules = getQueryRulesByAttributeType(
                            attributeType?.valueType,
                            isSystemEntityAttributeType,
                        );

                        const systemAttributeEntityOptions = isSystemEntityAttributeType
                            ? getSystemAttributeEntityOptions(attributeType?.id || '')
                            : undefined;

                        return (
                            <div className={theme.condition} key={rule.id}>
                                <Select
                                    value={rule.attributeTypeId}
                                    onChange={(attrTypeId) => {
                                        onAttributeTypeIdChange(rule.id, attrTypeId);
                                    }}
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    virtual={false}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return ((option?.children ?? '') as string)
                                            .toLowerCase()
                                            .includes(input.toLocaleLowerCase().trim());
                                    }}
                                >
                                    <Select.OptGroup label={intl.formatMessage(messages.methodologyAttributes)}>
                                        {attributeTypes.map((attr) => (
                                            <Select.Option value={attr.id} key={attr.id}>
                                                {attr.multilingualName
                                                    ? attr.multilingualName[currentLocale]
                                                    : attr.name}
                                            </Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                    <Select.OptGroup label={intl.formatMessage(messages.systemAttributes)}>
                                        {systemAttributeTypes.map((attr) => (
                                            <Select.Option value={attr.id} key={attr.id}>
                                                {attr.name}
                                            </Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                </Select>
                                <Select
                                    value={rule.queryRule}
                                    onChange={(queryRule) => {
                                        onQueryRuleChange(rule.id, queryRule, attributeType?.valueType);
                                    }}
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                >
                                    {queryRules.map((queryRule) => (
                                        <Select.Option value={queryRule} key={queryRule}>
                                            {intl.formatMessage(messages[queryRule])}
                                        </Select.Option>
                                    ))}
                                </Select>
                                <div className={theme.conditionValueContainer}>
                                    <AttributeValueInput
                                        key={rule.attributeTypeId}
                                        ruleId={rule.id}
                                        attributeType={attributeType}
                                        queryRule={rule.queryRule}
                                        values={rule.values}
                                        onAttributeValueChange={onAttributeValueChange}
                                        nodeId={nodeId}
                                        systemAttributeEntityOptions={systemAttributeEntityOptions}
                                    />
                                </div>

                                <button className={theme.deleteConditionBtn} onClick={() => onDeleteCondition(rule.id)}>
                                    <Icon spriteSymbol={icDelete} />
                                </button>
                            </div>
                        );
                    })}
                </div>
            ) : null}
            <NUIButton className={theme.addConditionBtn} onClick={onAddCondition}>
                <Icon spriteSymbol={icAdd} /> {intl.formatMessage(messages.addCondition)}
            </NUIButton>
        </div>
    );
};
