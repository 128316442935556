import { BPMMxGraph } from '../../mxgraph/bpmgraph';
import { Base64 } from 'js-base64';

export class ImageMaker {
    public modelLoaded: boolean = false;

    private graph: BPMMxGraph;
    private canvas: HTMLCanvasElement;

    private static readonly FIRST_RENDER_DELAY = 80;
    private static readonly RENDER_DELAY = 80;
    private static readonly DRAW_IMAGE_TIMEOUT = 10000;

    constructor(graph: BPMMxGraph) {
        this.graph = graph;
        this.canvas = document.createElement('canvas');
    }

    modelEndLoad(): boolean {
        return this.modelLoaded;
    }

    getSvg(): string {
        return this.graph.getSvgStrAllGraph().svgString;
    }

    drawPng() {
        this.drawPngWithCallback();
    }

    async drawPngAsync(): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                const svg = this.graph.getSvgStrAllGraph();
                const ctx = this.canvas.getContext('2d');
                if (!ctx) {
                    return reject('ctx error');
                }
                ctx.canvas.width = svg.width;
                ctx.canvas.height = svg.height;
                ctx.fillStyle = '#ffffff';
                ctx.fillRect(0, 0, svg.width, svg.height);
                const img = new Image();
                img.src = `data:image/svg+xml;base64,${Base64.encode(svg.svgString)}`;
                img.onload = () => {
                    ctx.drawImage(img, 0, 0);
                    const url = ctx.canvas.toDataURL();

                    return resolve(url || 'bad url');
                };
            } catch(e) {
                reject(e);
            }
        });
    }
    drawPngWithCallback(loadCallback?: (result: string) => void) {
        const svg = this.graph.getSvgStrAllGraph();
        const ctx = this.canvas.getContext('2d');
        if (ctx) {
            ctx.canvas.width = svg.width;
            ctx.canvas.height = svg.height;
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, svg.width, svg.height);
            const img = new Image();
            img.addEventListener(
                'load',
                () => {
                    const blankImage = ctx.canvas.toDataURL();
                    ctx.drawImage(img, 0, 0);

                    if (loadCallback) {
                        // ожидание необходимо чтобы прогрузить изображение на канвасе, в том числе картинки на модели
                        this.waitLoadingAndSend(loadCallback, blankImage);
                    }
                },
                false,
            );
            img.src = `data:image/svg+xml;base64,${Base64.encode(svg.svgString)}`;
        }
    }

    // в этом методе ожидаем пока изображение не перестанет изменяться (загружаться) и отправляем в сервис
    waitLoadingAndSend(loadCallback: (result?: string) => void, blankImage: string) {
        let result = blankImage;
        const start = new Date().getTime();

        const timeout = () => new Date().getTime() - start > ImageMaker.DRAW_IMAGE_TIMEOUT;
        const endLoading = () => {
            const current = this.canvas.toDataURL();
            const end = current !== blankImage && current === result;
            result = current;

            return end;
        };

        setTimeout(
            () =>
                this.doWhen(
                    () => loadCallback(result),
                    () => {
                        if (endLoading()) {
                            console.log('loading finished');

                            return true;
                        }
                        if (timeout()) {
                            console.warn('loading timeout');

                            return true;
                        }

                        return false;
                    },
                ),
            ImageMaker.FIRST_RENDER_DELAY,
        );
    }

    doWhen(call: () => void, when: () => boolean) {
        if (when()) {
            call();
        } else {
            setTimeout(() => this.doWhen(call, when), ImageMaker.RENDER_DELAY);
        }
    }

    getPngBaseUrl() {
        return this.canvas.toDataURL();
    }

    sendResult = (imageUrl: string, id: string, result?: string) => {
        fetch(`${imageUrl}/image/result/${id}`, {
            mode: 'no-cors',
            headers: { 'Acces-Control-Allow-Origin': '*' },
            method: 'POST',
            body: result,
        }).then((r) => {
            if (r.status >= 200 && r.status < 300) {
                console.log(`result sent`);
            } else {
                console.error(`sending failed with ${r.status}`);
            }
        });
    };

    drawPngAndSendResult = (url: string, resultId: string) =>
        this.drawPngWithCallback((r) => this.sendResult(url, resultId, r));
}
