export enum ProcessType {
    IMPORT,
    EXPORT,
    DELETE,
    EXPORT_PRESET,
}

export type TExecutingProcess = {
    name?: string;
    type?: ProcessType;
    id: string;
};

export type TStatusBarState = {
    processes: TExecutingProcess[];
};
