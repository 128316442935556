import { all, call, CallEffect, put, select, takeEvery } from 'redux-saga/effects';
import { getCurrentLocale } from '../../selectors/locale.selectors';
import { LocalesService } from '../../services/LocalesService';
import messages from '../../modules/AdminTools/Methodology/messages/MethodologySetting.messages';
import { workspaceAddTab, workspaceRemoveTabByNodeId } from '../../actions/tabs.actions';
import { defaultWorkspaceTabActions } from '../../models/tab';
import { EditorMode } from '../../models/editorMode';
import { WorkSpaceTabTypes } from '../../modules/Workspace/WorkSpaceTabTypesEnum';
import { IWorkspaceTabItemModelEditParams, TWorkspaceTab } from '../../models/tab.types';
import { v4 as uuid } from 'uuid';
import { MatrixType } from '../../serverapi/api';
import { TPresetId } from '../../modules/AdminTools/Methodology/components/Presets/TPresetId.types';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { TreeNode } from '@/models/tree.types';
import { PresetSelectors } from '@/selectors/preset.selectors';
import {
    PRESET_SETTINGS_CREATE_MATRIX_MODEL_TYPE,
    PRESET_SETTINGS_EDIT_MATRIX_MODEL_TYPE,
    PRESET_SETTINGS_SUBMIT_MATRIX_MODEL_TYPE,
} from '@/actionsTypes/presetSettings/presetSettingsMatrixModelTypes.actionTypes';
import { addStoreEditMatrixModelTypeWorkspaceTab } from '@/actions/workspaceTab/editMatrixModelTypeWorkspaceTab.actions';
import {
    TCreateMatrixModelTypeAction,
    TEditMatrixModelTypeAction,
    TSubmitMatrixModelTypeAction,
} from '@/actions/presetSettings/presetSettingsMatrixModelType.actions.types';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { MatrixDaoService } from '@/services/dao/MatrixDaoService';
import { addMatrixModelType } from '@/actions/presetSettings/presetSettingsMatrixModelType.actions';
import { IPresetSettingsMatrixModelTypeData } from '@/reducers/presetSettings/presetSettingsMatrixModelType.reducer.types';
import { PresetSettingsMatrixModelTypeSelectors } from '@/selectors/presetSettings/presetSettingsMatrixModelType.selectors';

export function* saveMatrixModelTypeChanges(serverId: string, presetId: string) {
    const matrixModelTypesData: IPresetSettingsMatrixModelTypeData = yield select(
        PresetSettingsMatrixModelTypeSelectors.byPresetId(presetId),
    );

    const effects: CallEffect<void>[] = [];

    if (matrixModelTypesData.matrixModelTypesForSave.length) {
        matrixModelTypesData.matrixModelTypesForSave.forEach((type) => {
            if (!matrixModelTypesData.matrixModelTypesForDelete.some((typeToDelete) => typeToDelete.id === type.id))
                effects.push(call(() => MatrixDaoService.saveMatrixType(serverId, { body: type })));
        });
    }

    if (matrixModelTypesData.matrixModelTypesForDelete.length) {
        matrixModelTypesData.matrixModelTypesForDelete.forEach((type) => {
            effects.push(call(() => MatrixDaoService.deleteMatrixType(serverId, presetId, type.id)));
        });
    }

    yield all(effects);
}

function* handleCreateMatrixModelType(action: TCreateMatrixModelTypeAction) {
    const { serverNodeId, presetId } = action.payload;

    const serverNode: TreeNode = yield select(TreeSelectors.itemById(serverNodeId));
    const preset = yield select(PresetSelectors.byId({ presetId, serverId: serverNodeId.serverId }));

    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const modelType: MatrixType = {
        id: uuid(),
        attributes: [],
        groupId: uuid(),
        presetId: preset.id,
        enableModel: true,
        discriminator: 'MATRIX',
    };

    const matrixTypeTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newModelTitle),
        nodeId: {
            ...serverNodeId,
            id: `${serverNodeId.serverId}_${presetId}_${modelType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_MATRIX_MODEL_TYPE_TAB,
        mode: EditorMode.Read,
        presetId,
        params: {
            serverNode,
            preset,
            createMode: true,
            modelTypeId: modelType.id,
        } as IWorkspaceTabItemModelEditParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(
        addStoreEditMatrixModelTypeWorkspaceTab({
            presetId,
            modelType,
        }),
    );

    yield put(workspaceAddTab(matrixTypeTab));
}

function* handleSubmitMatrixModelType(action: TSubmitMatrixModelTypeAction) {
    const { presetId, requestBody, needTabClose, tabNodeId, createMode } = action.payload;
    const serverId = yield select(ServerSelectors.serverId);

    if (createMode) {
        yield MatrixDaoService.createMatrixType(serverId, requestBody);
    } else {
        yield MatrixDaoService.saveMatrixType(serverId, requestBody);
    }

    if (needTabClose && tabNodeId) {
        yield put(workspaceRemoveTabByNodeId(tabNodeId));
    }

    yield put(addMatrixModelType({ presetId, matrixModelTypes: [requestBody.body] }));
}

function* handleEditMatrixModelType(action: TEditMatrixModelTypeAction) {
    const { serverNodeId, presetId, matrixModelType } = action.payload;

    const serverNode: TreeNode = yield select(TreeSelectors.itemById(serverNodeId));
    const preset = yield select(PresetSelectors.byId({ presetId, serverId: serverNodeId.serverId }));

    const currentLocale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(currentLocale);
    const multilingualName = matrixModelType.multilingualName!;

    const createModelTypeTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newModelType, { type: multilingualName[currentLocale] }),
        nodeId: {
            ...serverNodeId,
            id: `${serverNodeId.serverId}_${presetId}_${matrixModelType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_MATRIX_MODEL_TYPE_TAB,
        mode: EditorMode.Read,
        presetId,
        params: {
            serverNode,
            preset,
            createMode: false,
            modelTypeId: matrixModelType.id,
        } as IWorkspaceTabItemModelEditParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(
        addStoreEditMatrixModelTypeWorkspaceTab({
            presetId,
            modelType: matrixModelType,
        }),
    );
    yield put(workspaceAddTab(createModelTypeTab));
}

export function* presetSettingsMatrixModelTypesSaga() {
    yield takeEvery(PRESET_SETTINGS_CREATE_MATRIX_MODEL_TYPE, handleCreateMatrixModelType);
    yield takeEvery(PRESET_SETTINGS_SUBMIT_MATRIX_MODEL_TYPE, handleSubmitMatrixModelType);
    yield takeEvery(PRESET_SETTINGS_EDIT_MATRIX_MODEL_TYPE, handleEditMatrixModelType);
}
