// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MatrixEditorToolbar__modeGroup_full__Yakgd{margin-top:15px}.MatrixEditorToolbar__modeButton__tzHam svg{height:35px;width:35px}.MatrixEditorToolbar__matrixSettingsButton__BNPj3 svg{height:34px;width:34px}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/MatrixEditorToolbar/MatrixEditorToolbar.scss"],"names":[],"mappings":"AAAA,4CACI,eAAA,CAIA,4CACI,WAAA,CACA,UAAA,CAKJ,sDACI,WAAA,CACA,UAAA","sourcesContent":[".modeGroup_full {\r\n    margin-top: 15px;\r\n}\r\n\r\n.modeButton {\r\n    svg {\r\n        height: 35px;\r\n        width: 35px;\r\n    }\r\n}\r\n\r\n.matrixSettingsButton {\r\n    svg {\r\n        height: 34px;\r\n        width: 34px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modeGroup_full": "MatrixEditorToolbar__modeGroup_full__Yakgd",
	"modeButton": "MatrixEditorToolbar__modeButton__tzHam",
	"matrixSettingsButton": "MatrixEditorToolbar__matrixSettingsButton__BNPj3"
};
export default ___CSS_LOADER_EXPORT___;
