import { Button } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import theme from '../ApprovalTab/approvalTab.scss';
import messages from '../messages/approval.messages';
import { IconDeleteBin } from '../../UIKit/components/Icons/IconDeleteBin';

type TDeleteButtonProps = {
    handleClick: () => void;
    isDisabled: boolean;
}

export const DeleteButton = ({ handleClick, isDisabled }: TDeleteButtonProps): JSX.Element => {
    const intl = useIntl();

    return (
        <div className={theme.buttonContainer}>
            <Button data-test="admin-approvals_delete_btn" className={theme.iconContainer} onClick={handleClick} disabled={isDisabled}>
                <IconDeleteBin className={isDisabled ? theme.disabledIconContainer : theme.activeIconContainer} />
                <span>{intl.formatMessage(messages.delete)}</span>
            </Button>
        </div>
    );
};
