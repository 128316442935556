import { v4 as uuid } from 'uuid';
import { EdgeInstanceImpl } from '../../models/bpm/bpm-model-impl';
import { PictureSymbolConstants } from '../../models/pictureSymbolConstants';
import { StatisticsSelectors } from '../../selectors/statistics.selectors';
import { EdgeType } from '../../serverapi/api';
import { NotationHelper } from '../../services/utils/NotationHelper';
import { getStore } from '../../store';
import { BPMMxGraph } from '../bpmgraph';
import { MxCell } from '../mxgraph';
import { BPMMxConstants } from '../bpmgraph.constants';

export const getConnectionHandlerFactoryMethod = (graph: BPMMxGraph) => {
    const connect = (source: MxCell, target: MxCell): MxCell => {
        const availableTypes = NotationHelper.getEdgeTypeBySourceAndDestination(
            source.getValue(),
            target.getValue(),
            graph.id.serverId,
            graph.modelType,
        ).filter((type): type is EdgeType => type !== null);

        let selectedEdgeType: EdgeType | undefined;

        if (graph.connectionHandler?.currentEdgeType?.id === BPMMxConstants.AUTO_EDGE_TYPE_ID) {
            selectedEdgeType =
                availableTypes.length > 1
                    ? StatisticsSelectors.getLastUsedEdgeType(availableTypes)(getStore().getState())
                    : availableTypes[0];
        } else {
            selectedEdgeType = availableTypes.find(
                (availableType) => availableType.id === graph.connectionHandler.currentEdgeType?.id,
            );
        }

        if (selectedEdgeType) {
            const { edgeStyle } = selectedEdgeType;
            const sourceId = source.getValue().id;
            const targetId = target.getValue().id;
            const newId = uuid();
            const edgeValue = new EdgeInstanceImpl({
                id: newId,
                style: edgeStyle,
                edgeTypeId: selectedEdgeType.id,
                source: sourceId,
                target: targetId,
                name: '',
            });

            return graph.createCustomEdge(edgeValue, edgeStyle);
        }

        const isLinkWithPictureSymbol: boolean =
            source.value.symbolId === PictureSymbolConstants.PICTURE_SYMBOL_ID ||
            target.value.symbolId === PictureSymbolConstants.PICTURE_SYMBOL_ID;

        if (isLinkWithPictureSymbol) {
            const sourceId = source.getValue().id;
            const targetId = target.getValue().id;
            const edgeValue = new EdgeInstanceImpl({
                id: uuid(),
                style: PictureSymbolConstants.EDGE_TYPE_STYLE,
                edgeTypeId: PictureSymbolConstants.EDGE_TYPE_ID,
                source: sourceId,
                target: targetId,
            });

            return graph.createCustomEdge(edgeValue, PictureSymbolConstants.EDGE_TYPE_STYLE);
        }

        return graph.createCustomEdge('', 'startArrow=none;endArrow=block;');
    };

    return connect;
};
