import { defineMessages } from 'react-intl';

export default defineMessages({
    userEditDisabled: {
        id: 'Settings.userEditDisabled',
        defaultMessage: 'Блокировка на ручные изменения',
    },
    scriptEngineEditDisabled: {
        id: 'Settings.scriptEngineEditDisabled',
        defaultMessage: 'Блокировка на изменения скриптами',
    }
});
