import { AttributeType, SearchRule, SystemAttributeIdIdEnum } from '@/serverapi/api';

export interface ISearchRuleWithId extends SearchRule {
    id: string;
}

export enum SearchRuleAttributeType {
    SYSTEM = 'SYSTEM',
    USER = 'USER',
}

export type TSystemAttributeType = AttributeType & { id: SystemAttributeIdIdEnum };

export type TSystemAttributeEntityOptions = {
    id: string;
    name: string;
};
