import React from 'react';
import theme from './CommentElement.scss';
import { Comment, NodeId, PrincipalDescriptor } from '../../../serverapi/api';
import { Icon } from '../../UIKit';
import pinnedComment from '../../../resources/icons/pinnedComment.svg';
import { useSelector } from 'react-redux';
import { CommentsSelectors } from '../../../selectors/comments.selectors';
import { getUser } from '../../../selectors/authorization.selectors';
import { CommentCircle } from './CommentCircle/CommentCircle.component';

type TCommentNameHeaderProps = {
    modelId: NodeId;
    commentId?: string;
    withDate?: boolean;
    className?: string;
    addMarker?: () => void;
    moveToMarker?: () => void;
};

export const CommentNameHeader = (props: TCommentNameHeaderProps) => {
    const { modelId, commentId, withDate, className, addMarker, moveToMarker } = props;
    const comment: Comment | undefined = useSelector(CommentsSelectors.getCommentById(modelId, commentId));
    const newCommentAutor: string | undefined = useSelector(getUser)?.login;
    const autorLogin: string = comment?.author || newCommentAutor || '';
    const author: PrincipalDescriptor | undefined = useSelector(CommentsSelectors.getCommentAuthor(modelId, commentId));
    const authorName: string = author ? `${author.name || ''} ${author.lastname || ''}` : autorLogin;
    const parsedDate: string =  useSelector(CommentsSelectors.getCreateDate(modelId, commentId));
    
    return (
        <div className={className}>
            <CommentCircle modelId={modelId} commentId={commentId || ''} addMarker={addMarker} moveToMarker={moveToMarker}/>
            <div className={theme.commentHeader}>
                <div className={theme.commentHeaderName} data-test="user-comment_name">
                    {authorName}
                </div>
                {withDate && (
                    <div className={theme.commentHeaderDate} data-test="user-comment_date">
                        {parsedDate}
                        {comment?.pinDate ? (
                            <Icon
                                dataTest="user-comment_pinned-icon"
                                spriteSymbol={pinnedComment}
                                className={theme.pinIcon}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
