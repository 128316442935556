import { defineMessages } from 'react-intl';

export default defineMessages({
    levelUp: {
        id: 'NewMatrixContextMenu.levelUp',
        defaultMessage: 'На уровень выше',
    },
    levelDown: {
        id: 'NewMatrixContextMenu.levelDown',
        defaultMessage: 'На уровень ниже',
    },
    symbolsSettings: {
        id: 'NewMatrixContextMenu.symbolsSettings',
        defaultMessage: 'Настройка символов',
    },
});
