import { connect } from 'react-redux';
import { TRootState } from '../../../reducers/root.reducer.types';
import { TCommentsPanelProps } from '../components/Comments.types';
import { CommentsSelectors } from '../../../selectors/comments.selectors';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { pinComment, unpinComment } from '../../../actions/comments.actions';
import { Comment, NodeId } from '../../../serverapi/api';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TWorkspaceTab } from '../../../models/tab.types';
import CommentPopover from '../components/CommentPopover/CommentPopover.component';

const mapStateToProps = (state: TRootState, own) => {
    const scheme: TWorkspaceTab | undefined = TabsSelectors.getActiveTab(state);

    return {
        scheme,
        getSortedActualComments: (modelId: NodeId) => {
            return CommentsSelectors.getSortedActualComments(modelId)(state);
        },
        getPinnedComments: (modelId: NodeId) => {
            return CommentsSelectors.getPinnedComments(modelId)(state);
        },
        getCommentsByParentId: (modelId: NodeId, parentId: string) => {
            return CommentsSelectors.getCommentsByParentId(modelId, parentId)(state);
        },
    };
};

const mapDispatchToProps = (dispatch, ownProps): Partial<TCommentsPanelProps> => ({
    deleteComment: (modelId: NodeId, commentId: NodeId, isAnswer?: true) =>
        dispatch(openDialog(DialogType.DELETE_COMMENT_DIALOG, { modelId, commentId, isAnswer: isAnswer || false })),
    pinComment: (modelId: NodeId, comment: Comment) => dispatch(pinComment(modelId, comment)),
    unpinComment: (modelId: NodeId, comment: Comment) => {
        dispatch(unpinComment(modelId, comment));
    },
});

export const CommentPopoverContainer = connect(mapStateToProps, mapDispatchToProps)(CommentPopover);
