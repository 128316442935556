import { ScriptEditor } from '../component/ScriptEditor.component';
import { TScriptEditorProps } from '../ScriptEditor.types';
import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { scriptSave } from '../../../../actions/entities/script.actions';
import { IScriptNode } from '../../../../models/bpm/bpm-model-impl.types';
import { workspaceTabSetAction, workspaceTabSetParams } from '../../../../actions/tabs.actions';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { CommentsSelectors } from '../../../../selectors/comments.selectors';
import { relock, unlock } from '../../../../actions/lock.actions';
import { TreeItemType } from '../../../Tree/models/tree';
import { TWorkspaceTab } from '../../../../models/tab.types';

type TScriptEditorContainerOwnProps = {
    tab: TWorkspaceTab;
};

const mapStateToProps = (state: TRootState, ownProps: TScriptEditorContainerOwnProps): Partial<TScriptEditorProps> => {
    const content = ownProps.tab.content as IScriptNode;
    const activeTab: TWorkspaceTab | undefined = TabsSelectors.byId(content.nodeId)(state);
    const commentsEnabledSchemesIds = CommentsSelectors.commentsEnabledSchemesIds()(state);

    return {
        id: content.nodeId.id, // todo: 1647: script has no repositoryId and belongs only to server, NodeId is not required here.CHeck it
        script: content,
        params: activeTab?.params,
        actions: activeTab?.actions,
        commentsEnabledSchemesIds,
        mode: activeTab?.mode,
    };
};

const mapDispatchToProps: (dispatch, ownProps: TScriptEditorContainerOwnProps) => Partial<TScriptEditorProps> = (
    dispatch,
    ownProps,
) => {
    const content = ownProps.tab.content as IScriptNode;

    return {
        onChange: (script: IScriptNode) => {
            dispatch(scriptSave(script));
        },
        // onExecuteAction: (script: ScriptNode, params: ScriptParameter[]) => { dispatch(scriptExecute(script, params)); },
        // onBlur: (script: ScriptNode) => dispatch(scriptChange(script)),
        onParamsChange: (name: string, value: string | boolean | object) => {
            dispatch(
                workspaceTabSetParams(content.nodeId, {
                    [name]: value,
                }),
            );
        },
        onActionChange: (name: string, value: string | boolean) => {
            dispatch(workspaceTabSetAction({ nodeId: content.nodeId, name, value }));
        },
        onLock: () => dispatch(relock(content.nodeId, TreeItemType.Script)),
        onUnlock: () => dispatch(unlock(content.nodeId, TreeItemType.Script)),
    };
};

export const ScriptEditorContainer = connect(mapStateToProps, mapDispatchToProps)(ScriptEditor);
