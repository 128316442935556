import { Button, Input, Popover, Upload } from 'antd';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { useIntl } from 'react-intl';
import { MethodologiesGraph } from '../../../../../../mxgraph/MethodologiesGraph';
import { MxConstants } from '../../../../../../mxgraph/mxgraph';
import {
    EdgeMatrixStyle,
    EdgeMatrixStyleCustom,
    EdgeMatrixStyleIcon,
    EdgeMatrixStyleIconIconEnum,
    EdgeMatrixStyleTypeEnum,
    EdgeMatrixStyleUserIcon,
    EdgeType,
    EdgeTypeDirectionEnum,
} from '../../../../../../serverapi/api';
import { getStyleValueByStyleKey } from '../../../../../../utils/css.utils';
import { FileUploadDialog } from '../../../../../FileUpload/components/FileUploadDialog.component';
import theme from './EdgeType.scss';
import { SymbolToImageConverterGraph } from '../SymbolToImageConverterGraph.component';
import messages from './EdgeType.messages';
import edgeTypeDirectionMessages from '../../../../../../models/edge-type-direction.messages';
import STYLE_ARCSIZE = MxConstants.STYLE_ARCSIZE;
import STYLE_STROKECOLOR = MxConstants.STYLE_STROKECOLOR;
import STYLE_STROKEWIDTH = MxConstants.STYLE_STROKEWIDTH;
import { BPMMxConstants } from '../../../../../../mxgraph/bpmgraph.constants';
import { Select } from '../../../../../UIKit/components/Select/Select.component';
import { InputEdgeSetting } from './InputEdgeSetting.component';
import { Icon } from '@/modules/UIKit';
import UploadIcon from '../../../../../../resources/icons/Upload.svg';
import DeletedIcon from '../../../../../../resources/icons/Deleted.svg';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { showNotification, showNotificationByType } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { useDispatch } from 'react-redux';
import { MAX_SIZE_IN_BYTES } from '@/modules/Comments/utils/commentsUtils';
import { v4 as uuid } from 'uuid';
import { TooltipTitle } from '@/modules/TooltipTitle/TooltipTitle.component';
import { formatBytes } from '@/utils/formatBytes.utils';
import cx from 'classnames';
import { EdgeMatrixStyleIconIcon, EdgeMatrixStyleType, TEdgeTypeMatrixStyleValidation } from './EdgeTypeEditor.types';

type TGeneralTabProps = {
    edgeType: EdgeType;
    exportStyle: () => void;
    onChangeEdgeTypeStyle: (style: string) => void;
    onChangeEdgeTypeDirection: (direction: EdgeTypeDirectionEnum) => void;
    onChangeEdgeTypeEdgeMatrixStyle: (matrixStyle: EdgeMatrixStyle) => void;
    matrixStyleValidation: TEdgeTypeMatrixStyleValidation;
    onChangeMatrixStyleValidation: (value: TEdgeTypeMatrixStyleValidation) => void;
};

type TDefineMessages = {
    id: string;
    defaultMessage: string;
};

type TEdgeMatrixStyleData = {
    CUSTOM: EdgeMatrixStyleCustom;
    ICON: EdgeMatrixStyleIcon;
    USER_ICON: EdgeMatrixStyleUserIcon;
};

type TImageParams = {
    name: string;
    size: number;
};

const DEFAULT_STROKE_COLOR: string = '#0088cc';
const DEFAULT_DASHED: string = '0'; // MxConstants.NONE;
const DEFAULT_START_ARROW: string = MxConstants.NONE;
const DEFAULT_END_ARROW: string = MxConstants.ARROW_BLOCK;
const DEFAULT_STROKE_WIDTH: string = '1';
const DEFAULT_ARC_SIZE: string = '20';

const ARROW_TRANSLATION_MAP: { [key: string]: TDefineMessages } = {
    [MxConstants.NONE]: messages.no,
    [MxConstants.ARROW_DIAMOND]: messages.diamond,
    [MxConstants.ARROW_DIAMOND_THIN]: messages.diamondThin,
    [MxConstants.ARROW_BLOCK]: messages.block,
    [MxConstants.ARROW_BLOCK_THIN]: messages.blockThin,
    [MxConstants.ARROW_CLASSIC]: messages.classic,
    [MxConstants.ARROW_CLASSIC_THIN]: messages.classicThin,
    [MxConstants.ARROW_OPEN]: messages.open,
    [MxConstants.ARROW_OPEN_THIN]: messages.openThin,
    [MxConstants.ARROW_OVAL]: messages.oval,
    [BPMMxConstants.ARROW_CONTAINMENT]: messages.containment,
};

const EDGE_DIRECTION_MAP: { [key: string]: TDefineMessages } = {
    SRC_TO_DST: edgeTypeDirectionMessages.SRC_TO_DST,
    NO_DIRECTION: edgeTypeDirectionMessages.NO_DIRECTION,
};

const matrixStyleTypeOptions = [EdgeMatrixStyleType.ICON, EdgeMatrixStyleType.CUSTOM, EdgeMatrixStyleType.USER_ICON];

const matrixStyleIconTypeOptions = [
    EdgeMatrixStyleIconIcon.PLUS,
    EdgeMatrixStyleIconIcon.MINUS,
    EdgeMatrixStyleIconIcon.CROSS,
    EdgeMatrixStyleIconIcon.CHECK,
];

const matrixEdgeStyleDefaultValue: TEdgeMatrixStyleData = {
    CUSTOM: {
        type: EdgeMatrixStyleType.CUSTOM,
        color: '#000000',
        text: '',
    },
    ICON: {
        type: EdgeMatrixStyleType.ICON,
        color: '#000000',
        icon: EdgeMatrixStyleIconIcon.PLUS,
    },
    USER_ICON: {
        type: EdgeMatrixStyleType.USER_ICON,
        iconData: '',
    },
};

export const StylesTab: FC<TGeneralTabProps> = (props) => {
    const {
        edgeType,
        exportStyle,
        onChangeEdgeTypeStyle,
        onChangeEdgeTypeDirection,
        onChangeEdgeTypeEdgeMatrixStyle,
        matrixStyleValidation,
        onChangeMatrixStyleValidation,
    } = props;
    const { edgeStyle } = edgeType;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [importDialogVisible, setImportDialogVisible] = useState(false);
    const [graph, setGraph] = useState<MethodologiesGraph | undefined>(undefined);
    const [matrixEdgeStyle, setMatrixEdgeStyle] = useState<TEdgeMatrixStyleData>(() => {
        if (edgeType.matrixStyle) {
            return { ...matrixEdgeStyleDefaultValue, [edgeType.matrixStyle.type]: edgeType.matrixStyle };
        }
        return matrixEdgeStyleDefaultValue;
    });
    const [matrixEdgeStyleType, setMatrixEdgeStyleType] = useState<EdgeMatrixStyleTypeEnum | undefined>(
        edgeType.matrixStyle?.type,
    );
    const [imageParams, setImageParams] = useState<TImageParams | undefined>();

    let actualDirection: TDefineMessages | undefined;

    if (edgeType.direction === 'DST_TO_SRC') {
        actualDirection = EDGE_DIRECTION_MAP.SRC_TO_DST;
    } else if (edgeType.direction === 'BIDIRECTIONAL') {
        actualDirection = EDGE_DIRECTION_MAP.NO_DIRECTION;
    }

    const lineDirectionSelectValue = intl.formatMessage(
        actualDirection || EDGE_DIRECTION_MAP[edgeType.direction] || edgeTypeDirectionMessages.NO_DIRECTION,
    );

    const imageSize: string = formatBytes(imageParams?.size || 0);
    const imageSizeForShow: string = imageSize === '0' ? '' : imageSize;

    useEffect(() => {
        return () => {
            // componentWillUnmount
            graph?.destroy();
        };
    }, []);

    const converterInitialized = (newGraph: MethodologiesGraph) => {
        if (!graph) {
            setGraph(newGraph);
        }
    };

    const onChangeStyle = (key: string, value: string) => {
        if (graph) {
            const edge = graph.getModel().getCell(edgeType.id);
            graph.setCellStyles(key, value, [edge]);
            onChangeEdgeTypeStyle(graph.getModel().getStyle(edge));
        }
    };

    const onClose = () => {
        setImportDialogVisible(false);
    };

    const importStyleSubmit = (file: File) => {
        file.text().then((importedEdgeStyle) => onChangeEdgeTypeStyle(importedEdgeStyle));
        onClose();
    };

    const importStyleOpenDialog = () => {
        setImportDialogVisible(true);
    };

    const lineTypeOptions = [
        {
            value: '0',
            dataTest: 'edge-select-lineType-solid',
            label: intl.formatMessage(messages.solid),
        },
        {
            value: '1',
            dataTest: 'edge-select-lineType-intermittent',
            label: intl.formatMessage(messages.intermittent),
        },
    ];

    const getStartOrEndValue = (isStart?: boolean): TDefineMessages => {
        const key: string = isStart ? MxConstants.STYLE_STARTARROW : MxConstants.STYLE_ENDARROW;
        const styleValueByStyleKey: string | undefined = getStyleValueByStyleKey(key, edgeStyle);

        if (!styleValueByStyleKey || !ARROW_TRANSLATION_MAP[styleValueByStyleKey]) {
            return ARROW_TRANSLATION_MAP[isStart ? DEFAULT_START_ARROW : DEFAULT_END_ARROW];
        }

        return ARROW_TRANSLATION_MAP[styleValueByStyleKey];
    };

    const onDropFile = (fileBase64: string) => {
        onChangeEdgeTypeEdgeMatrixStyle({
            ...matrixEdgeStyle.USER_ICON,
            iconData: fileBase64,
        } as EdgeMatrixStyleUserIcon);
        setMatrixEdgeStyle({
            ...matrixEdgeStyle,
            USER_ICON: {
                ...matrixEdgeStyle.USER_ICON,
                iconData: fileBase64,
            },
        });

        if (!matrixStyleValidation.userIcon)
            onChangeMatrixStyleValidation({ custom: matrixStyleValidation.custom, userIcon: true });
    };

    const dropFile = (e: UploadRequestOption) => {
        if (e.file) {
            const reader = new FileReader();
            reader.readAsDataURL(e.file as File);
            reader.onloadend = () => {
                onDropFile(reader.result as string);
            };
            setImageParams({
                name: (e.file as File).name,
                size: (e.file as File).size,
            });
        }
    };

    const beforeUpload = (file: File): boolean => {
        const isImage: boolean = file.type === 'image/svg+xml';
        const isSizeExceed: boolean = file.size > MAX_SIZE_IN_BYTES;

        if (!isImage) {
            dispatch(showNotificationByType(NotificationType.NOT_SVG_IMAGE_ERROR));
        }
        if (isSizeExceed) {
            dispatch(
                showNotification({
                    id: uuid(),
                    type: NotificationType.MAX_SIZE_FOR_FILE,
                    data: { message: `${file.name} ${intl.formatMessage(messages.fileMaxSize)}` },
                }),
            );
        }

        return isImage && !isSizeExceed;
    };

    const onDeleteMatrixIcon = () => {
        onChangeEdgeTypeEdgeMatrixStyle({ ...matrixEdgeStyle.USER_ICON, iconData: '' } as EdgeMatrixStyleUserIcon);
        setImageParams(undefined);
        setMatrixEdgeStyle({
            ...matrixEdgeStyle,
            USER_ICON: {
                ...matrixEdgeStyle.USER_ICON,
                iconData: '',
            },
        });

        if (!matrixStyleValidation.userIcon)
            onChangeMatrixStyleValidation({ custom: matrixStyleValidation.custom, userIcon: true });
    };

    return (
        <div className={theme.form}>
            <FileUploadDialog
                open={importDialogVisible}
                serverId=""
                onSubmit={importStyleSubmit}
                onClose={onClose}
                id=""
            />
            <div className={theme.downLoadButtonsWrapper}>
                <Button className={theme.downLoadButton} onClick={importStyleOpenDialog}>
                    {intl.formatMessage(messages.importStyle)}
                </Button>
                <Button className={theme.downLoadButton} onClick={exportStyle}>
                    {intl.formatMessage(messages.exportStyle)}
                </Button>
            </div>
            <div className={theme.settingsWrapper}>
                <div className={theme.settingsInputsWrapper}>
                    <div className={theme.label}>{intl.formatMessage(messages.fullStyle)}</div>
                    <Input
                        className={theme.edgeSettingControl}
                        value={edgeStyle}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            onChangeEdgeTypeStyle(event.target.value.trim())
                        }
                        data-test="edge-full-style"
                    />
                    <div className={theme.settingsRow}>
                        <Select
                            label={intl.formatMessage(messages.lineType)}
                            value={
                                lineTypeOptions?.find(
                                    (option) =>
                                        option.value === getStyleValueByStyleKey(MxConstants.STYLE_DASHED, edgeStyle),
                                )?.label || lineTypeOptions[DEFAULT_DASHED].label
                            }
                            onChange={(value: string) => onChangeStyle(MxConstants.STYLE_DASHED, value)}
                            data-test="edge-select-lineType"
                            originalTheme
                            dropdownClassName={theme.selectDropdown}
                        >
                            {lineTypeOptions.map((option) => (
                                <Select.Option
                                    key={option.value}
                                    value={option.value}
                                    data-test={option.dataTest}
                                    label={option.label}
                                />
                            ))}
                        </Select>

                        <Select
                            label={intl.formatMessage(messages.lineDirection)}
                            value={lineDirectionSelectValue}
                            onChange={(value: string) => {
                                onChangeEdgeTypeDirection(value as EdgeTypeDirectionEnum);
                            }}
                            data-test="edge-select-direction"
                            originalTheme
                            dropdownClassName={theme.selectDropdown}
                        >
                            {Object.keys(EDGE_DIRECTION_MAP).map((key) => (
                                <Select.Option
                                    key={key}
                                    value={key}
                                    data-test="edge-select-option-direction"
                                    label={intl.formatMessage(EDGE_DIRECTION_MAP[key])}
                                />
                            ))}
                        </Select>
                    </div>

                    <div className={theme.settingsRow}>
                        <Select
                            label={intl.formatMessage(messages.lineStart)}
                            value={intl.formatMessage(getStartOrEndValue(true))}
                            onChange={(value: string) => {
                                onChangeStyle(MxConstants.STYLE_STARTARROW, value);
                            }}
                            data-test="edge-select-lineStart"
                            originalTheme
                            dropdownClassName={theme.selectDropdown}
                        >
                            {Object.keys(ARROW_TRANSLATION_MAP).map((key) => (
                                <Select.Option
                                    key={key}
                                    value={key}
                                    data-test={`edge-select-option-lineStart_${key}`}
                                    label={intl.formatMessage(ARROW_TRANSLATION_MAP[key])}
                                />
                            ))}
                        </Select>

                        <Select
                            label={intl.formatMessage(messages.lineEnd)}
                            value={intl.formatMessage(getStartOrEndValue())}
                            onChange={(value: string) => {
                                onChangeStyle(MxConstants.STYLE_ENDARROW, value);
                            }}
                            data-test="edge-select-lineEnd"
                            originalTheme
                            dropdownClassName={theme.selectDropdown}
                        >
                            {Object.keys(ARROW_TRANSLATION_MAP).map((key) => (
                                <Select.Option
                                    key={key}
                                    value={key}
                                    data-test={`edge-select-option-lineEnd_${key}`}
                                    label={intl.formatMessage(ARROW_TRANSLATION_MAP[key])}
                                />
                            ))}
                        </Select>
                    </div>

                    <div className={theme.settingsRow}>
                        <InputEdgeSetting
                            label={intl.formatMessage(messages.lineWidth)}
                            data-test="edge-style-strokewidth"
                            value={Number(
                                getStyleValueByStyleKey(STYLE_STROKEWIDTH, edgeStyle) || DEFAULT_STROKE_WIDTH,
                            )}
                            onChange={(value) => onChangeStyle(STYLE_STROKEWIDTH, Number(value).toString())}
                            min={1}
                            max={50}
                        />
                        <InputEdgeSetting
                            label={intl.formatMessage(messages.lineAngle)}
                            data-test="edge-style-arcsize"
                            value={Number(
                                getStyleValueByStyleKey(STYLE_ARCSIZE.toString(), edgeStyle) || DEFAULT_ARC_SIZE,
                            )}
                            onChange={(value) => {
                                onChangeStyle(STYLE_ARCSIZE.toString(), Number(value).toString());
                            }}
                            min={0}
                            max={2500}
                        />
                    </div>

                    <div className={theme.colorSettingsWrapper}>
                        <div className={theme.label}>{intl.formatMessage(messages.color)}</div>
                        <Popover
                            content={
                                <SketchPicker
                                    color={
                                        getStyleValueByStyleKey(STYLE_STROKECOLOR, edgeStyle) || DEFAULT_STROKE_COLOR
                                    }
                                    onChange={(color: ColorResult) => onChangeStyle(STYLE_STROKECOLOR, color.hex)}
                                    disableAlpha
                                />
                            }
                            trigger="click"
                        >
                            <div className={theme.colorPickerBtn}>
                                <div
                                    style={{
                                        backgroundColor: `${
                                            getStyleValueByStyleKey(STYLE_STROKECOLOR, edgeStyle) ||
                                            DEFAULT_STROKE_COLOR
                                        }`,
                                    }}
                                    className={theme.edgeBox}
                                />
                                <div>
                                    <span className={theme.colorPickerText} data-test="edge-color-picker">
                                        {getStyleValueByStyleKey(STYLE_STROKECOLOR, edgeStyle)?.slice(0, 7) ||
                                            DEFAULT_STROKE_COLOR}
                                    </span>
                                </div>
                            </div>
                        </Popover>
                    </div>

                    <div className={theme.matrixStyleSettingsWrapper}>
                        <div className={theme.matrixStyleType}>
                            <Select
                                label={intl.formatMessage(messages.matrixStyleTypeLabel)}
                                value={
                                    matrixEdgeStyleType && messages[matrixEdgeStyleType]
                                        ? intl.formatMessage(messages[matrixEdgeStyleType])
                                        : undefined
                                }
                                onChange={(matrixStyleType: EdgeMatrixStyleTypeEnum) => {
                                    onChangeEdgeTypeEdgeMatrixStyle({ ...matrixEdgeStyle[matrixStyleType] });
                                    setMatrixEdgeStyleType(matrixStyleType);
                                }}
                                data-test="matrix-select-edge-style-type"
                                originalTheme
                                dropdownClassName={theme.selectDropdown}
                            >
                                {matrixStyleTypeOptions.map((option) => (
                                    <Select.Option
                                        key={option}
                                        value={option}
                                        label={messages[option] ? intl.formatMessage(messages[option]) : option}
                                    />
                                ))}
                            </Select>
                        </div>

                        {matrixEdgeStyleType === EdgeMatrixStyleType.ICON && (
                            <div className={theme.matrixStyleIcon}>
                                <Select
                                    value={
                                        messages[matrixEdgeStyle[matrixEdgeStyleType].icon]
                                            ? intl.formatMessage(messages[matrixEdgeStyle[matrixEdgeStyleType].icon])
                                            : undefined
                                    }
                                    onChange={(matrixStyleIconType: EdgeMatrixStyleIconIconEnum) => {
                                        setMatrixEdgeStyle({
                                            ...matrixEdgeStyle,
                                            [matrixEdgeStyleType]: {
                                                ...matrixEdgeStyle[matrixEdgeStyleType],
                                                icon: matrixStyleIconType,
                                            },
                                        });
                                        onChangeEdgeTypeEdgeMatrixStyle({
                                            ...matrixEdgeStyle[matrixEdgeStyleType],
                                            icon: matrixStyleIconType,
                                        } as EdgeMatrixStyleIcon);
                                    }}
                                    data-test="matrix-select-edge-style-icon-type"
                                    originalTheme
                                    dropdownClassName={theme.selectDropdown}
                                >
                                    {matrixStyleIconTypeOptions.map((option) => (
                                        <Select.Option
                                            key={option}
                                            value={option}
                                            label={messages[option] ? intl.formatMessage(messages[option]) : option}
                                        />
                                    ))}
                                </Select>

                                <div className={theme.colorSelector}>
                                    <div className={theme.colorSelectorLabel}>{intl.formatMessage(messages.color)}</div>
                                    <Popover
                                        trigger="click"
                                        content={
                                            <SketchPicker
                                                color={matrixEdgeStyle[matrixEdgeStyleType].color}
                                                onChange={(color: ColorResult) => {
                                                    onChangeEdgeTypeEdgeMatrixStyle({
                                                        ...matrixEdgeStyle[matrixEdgeStyleType],
                                                        color: color.hex,
                                                    } as EdgeMatrixStyleIcon);
                                                    setMatrixEdgeStyle({
                                                        ...matrixEdgeStyle,
                                                        [matrixEdgeStyleType]: {
                                                            ...matrixEdgeStyle[matrixEdgeStyleType],
                                                            color: color.hex,
                                                        },
                                                    });
                                                }}
                                                disableAlpha
                                            />
                                        }
                                    >
                                        <button>
                                            <div
                                                className={theme.colorIndicator}
                                                style={{
                                                    backgroundColor: matrixEdgeStyle.ICON.color,
                                                }}
                                            />
                                        </button>
                                    </Popover>
                                </div>
                            </div>
                        )}
                        {matrixEdgeStyleType === EdgeMatrixStyleType.CUSTOM && (
                            <div className={theme.matrixCustomIcon}>
                                <div className={theme.matrixCustomIconRequired}>
                                    {intl.formatMessage(messages.matrixStyleSymbol)}
                                </div>
                                <Input
                                    className={cx({
                                        [theme.warning]:
                                            !matrixEdgeStyle[matrixEdgeStyleType].text.length &&
                                            matrixStyleValidation.custom,
                                    })}
                                    value={matrixEdgeStyle[matrixEdgeStyleType].text}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        onChangeEdgeTypeEdgeMatrixStyle({
                                            ...matrixEdgeStyle[matrixEdgeStyleType],
                                            text: event.target.value.trim(),
                                        } as EdgeMatrixStyleCustom);
                                        setMatrixEdgeStyle({
                                            ...matrixEdgeStyle,
                                            [matrixEdgeStyleType]: {
                                                ...matrixEdgeStyle[matrixEdgeStyleType],
                                                text: event.target.value.trim(),
                                            },
                                        });
                                        if (!matrixStyleValidation.custom)
                                            onChangeMatrixStyleValidation({
                                                userIcon: matrixStyleValidation.userIcon,
                                                custom: true,
                                            });
                                    }}
                                    maxLength={1}
                                />

                                <div className={theme.colorSelector}>
                                    <div className={theme.colorSelectorLabel}>{intl.formatMessage(messages.color)}</div>
                                    <Popover
                                        trigger="click"
                                        content={
                                            <SketchPicker
                                                color={matrixEdgeStyle.CUSTOM.color}
                                                onChange={(color: ColorResult) => {
                                                    onChangeEdgeTypeEdgeMatrixStyle({
                                                        ...matrixEdgeStyle[matrixEdgeStyleType],
                                                        color: color.hex,
                                                    } as EdgeMatrixStyleCustom);
                                                    setMatrixEdgeStyle({
                                                        ...matrixEdgeStyle,
                                                        [matrixEdgeStyleType]: {
                                                            ...matrixEdgeStyle.CUSTOM,
                                                            color: color.hex,
                                                        },
                                                    });
                                                }}
                                                disableAlpha
                                            />
                                        }
                                    >
                                        <button>
                                            <div
                                                className={theme.colorIndicator}
                                                style={{
                                                    backgroundColor: matrixEdgeStyle.CUSTOM.color,
                                                }}
                                            />
                                        </button>
                                    </Popover>
                                </div>
                                {!matrixEdgeStyle[matrixEdgeStyleType].text.length && matrixStyleValidation.custom && (
                                    <div className={theme.customIconWarningText}>
                                        {intl.formatMessage(messages.requiredField)}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {matrixEdgeStyleType === EdgeMatrixStyleType.USER_ICON && !matrixEdgeStyle.USER_ICON.iconData && (
                        <div className={theme.uploadArea}>
                            <Upload.Dragger
                                name="file"
                                multiple={false}
                                showUploadList={false}
                                customRequest={dropFile}
                                accept=".svg"
                                className={
                                    !matrixEdgeStyle.USER_ICON.iconData.length && matrixStyleValidation.userIcon
                                        ? theme.draggerWithWarning
                                        : theme.dragger
                                }
                                beforeUpload={beforeUpload}
                            >
                                <div className={theme.uploadAreaTextWrapper}>
                                    <div className={theme.uploadAreaTitle}>
                                        <Icon className={theme.uploadSvg} spriteSymbol={UploadIcon} />
                                        <div>{intl.formatMessage(messages.uploadText)}</div>
                                    </div>
                                    <div>{intl.formatMessage(messages.uploadDescription)}</div>
                                    <div>{intl.formatMessage(messages.uploadDescriptionSize)}</div>
                                </div>
                            </Upload.Dragger>
                            {matrixStyleValidation.userIcon && (
                                <div className={theme.userIconWarningText}>
                                    {intl.formatMessage(messages.requiredField)}
                                </div>
                            )}
                        </div>
                    )}
                    {matrixEdgeStyleType === EdgeMatrixStyleType.USER_ICON && !!matrixEdgeStyle.USER_ICON.iconData && (
                        <div className={theme.imageContainer}>
                            <img className={theme.image} src={matrixEdgeStyle.USER_ICON.iconData} />
                            <div className={theme.imageAnnotationContainer}>
                                <div>
                                    <div className={theme.imageName} title={imageParams?.name || ''}>
                                        <TooltipTitle>{imageParams?.name || ''}</TooltipTitle>
                                    </div>
                                    <div className={theme.imageSize}>{imageSizeForShow}</div>
                                </div>
                                <Icon
                                    className={theme.deleteButton}
                                    spriteSymbol={DeletedIcon}
                                    onClick={onDeleteMatrixIcon}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className={theme.edgePreview}>
                    <div className={theme.label}>{intl.formatMessage(messages.preview)}</div>
                    <div data-test="edge-graph-container" className={theme.edgeGraphContainer}>
                        <SymbolToImageConverterGraph initialized={converterInitialized} />
                        {SymbolToImageConverterGraph.convertEdge(edgeType, intl, graph, 100, 200, false)}
                    </div>
                </div>
            </div>
        </div>
    );
};
