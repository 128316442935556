import { call, put, select, takeEvery, all } from 'redux-saga/effects';
import { toggleErrorOnCreateDatabase, fetchedDBAccessInfoSuccess } from '../actions/databaseDialog.actions';
import {
    TCreateDatabaseAction,
    TFetchDBAccessInfoAction,
    TOpenDBAccessOwnersEditingTabAction,
    TUpdateRepositoryAction,
} from '../actions/databaseDialog.actions.types';
import {
    CREATE_DATABASE_ACTION,
    FETCH_DB_ACCESS_INFO,
    OPEN_DBACCESS_OWNERS_EDITING_TAB,
    UPDATE_REPOSITORY_ACTION,
} from '../actionsTypes/databaseDialog.actionTypes';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { treeItemAdd, treeItemUpdate } from '../actions/tree.actions';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import messages from '../modules/Models/components/Database/DatabaseDialog.messages';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { RepositoryNode, GroupDTO, UserDTO, PresetDTO } from '../serverapi/api';
import { v4 as uuid } from 'uuid';
import { TServerEntity } from '../models/entities.types';
import { TreeNode } from '../models/tree.types';
import { ServerErrorType } from '../models/serverErrorType';
import { EditorMode } from '../models/editorMode';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { defaultWorkspaceTabActions } from '../models/tab';
import { IWorkspaceDBAccessOwnersEditingTabItemParams, TWorkspaceTab } from '../models/tab.types';
import { workspaceAddTab } from '../actions/tabs.actions';
import { TreeSelectors } from '../selectors/tree.selectors';
import { AdminToolsUtils } from '../utils/adminToolsUtils';
import { TDBAccessOwnerStateMap } from '../reducers/databaseAccess.reducer.types';
import { presetMetaDataRequest } from '../actions/notation.actions';
import { setServerIdToNodeInterface } from '../utils/nodeId.utils';
import { LocalesService } from '../services/LocalesService';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { RepositoryDAOService } from '../services/dao/RepositoryDAOService';

function buildRepositoryNode(dbName: string, serverId: string, presetId: string): RepositoryNode {
    const id = uuid();

    return {
        nodeId: {
            id,
            repositoryId: id,
            serverId,
        },
        name: dbName,
        type: TreeItemType.Repository,
        presetId,
    };
}

function* handleCreateDatabase({ payload: { dbName, parentId, presetId } }: TCreateDatabaseAction) {
    try {
        const intl = LocalesService.useIntl(yield select(getCurrentLocale));
        const server: TServerEntity = yield select(ServerSelectors.server(parentId.id));
        const newRepo: RepositoryNode = buildRepositoryNode(dbName, server.id, presetId);
        const response: RepositoryNode = yield call(() => server.api.repository.save({ body: newRepo }));
        if (!response.nodeId || !response.nodeId.id) {
            yield put(
                toggleErrorOnCreateDatabase({
                    id: `DatabaseDialog.${response.nodeId}`,
                    defaultMessage: intl.formatMessage(messages.creatingRepositoryError) + response.name,
                }),
            );
        } else {
            setServerIdToNodeInterface(response, server.id);
            yield put(treeItemAdd({ ...response } as TreeNode));
            yield put(closeDialog(DialogType.DATABASE_CREATE));
        }
    } catch (e) {
        // todo зачем тут обработчик? возможно лучше использовать стандартный обработчик ошибок?
        console.error(e); // tslint:disable-line:no-console
        if (e.status === ServerErrorType.FORBIDDEN) {
            yield put(toggleErrorOnCreateDatabase(messages.accessDeniedError));
        } else {
            yield put(toggleErrorOnCreateDatabase(messages.internalError));
        }
    }
}

function* handleOpenDialog({ payload: { nodeId, type, action } }: TTreeItemContextMenuAction) {
    if (type === TreeItemType.Server && action === TreeItemContextMenuAction.ADD_DB) {
        const server: TServerEntity = yield select(ServerSelectors.server(nodeId.id));
        const presets: Array<PresetDTO> = yield call(() => server.api.preset.list());

        yield put(toggleErrorOnCreateDatabase());
        yield put(openDialog(DialogType.DATABASE_CREATE, { parentId: nodeId, presets }));
    }
}

function* handleFetchDBAccessInfo({ payload: { serverId, repoIds } }: TFetchDBAccessInfoAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    if (repoIds.length) {
        const ownersMap: TDBAccessOwnerStateMap = new Map();
        // todo ['user', 'group'] - надо переделать на типы,
        // но пока доступ к БД через админку не управляется пусть так остается
        const [userApiResponse, groupApiResponse]: [[UserDTO[]], [GroupDTO[]]] = yield all(
            ['user', 'group'].map((apiItem) =>
                repoIds.map((repoId) => call(() => server.api[apiItem].getByRepository({ repository: repoId }))),
            ),
        );

        repoIds.map((id, index) =>
            ownersMap.set(id, { users: userApiResponse[index], groups: groupApiResponse[index] }),
        );
        if (ownersMap.size) {
            yield put(fetchedDBAccessInfoSuccess({ serverId, owners: ownersMap }));
        }
    }
}

function* handleOpenDBAccessOwnersEditingTab({ nodeId }: TOpenDBAccessOwnersEditingTabAction) {
    const { serverId } = nodeId;
    const repository: TreeNode = yield select(TreeSelectors.itemById(nodeId));
    const contentLoadingPageTab: TWorkspaceTab = <TWorkspaceTab>{
        title: repository.name,
        nodeId: AdminToolsUtils.createNodeId(serverId, WorkSpaceTabTypes.DBACCESS_OWNERS_EDITING_TAB),
        type: WorkSpaceTabTypes.ADMINTOOLS_TAB,
        mode: EditorMode.Read,
        params: <IWorkspaceDBAccessOwnersEditingTabItemParams>{
            closable: false,
            serverId,
            nodeId,
        },
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(workspaceAddTab(contentLoadingPageTab));
}

function* saveRepository({ payload: { repositoryNode } }: TUpdateRepositoryAction) {
    const { nodeId, presetId } = repositoryNode;
    const savedNode: RepositoryNode = yield call(() => RepositoryDAOService.save(nodeId.serverId, repositoryNode));

    yield put(treeItemUpdate({ nodeId, data: savedNode }));
    yield put(presetMetaDataRequest([presetId]));
}

export function* databaseSagaInit() {
    yield takeEvery(CREATE_DATABASE_ACTION, handleCreateDatabase);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleOpenDialog);
    yield takeEvery(FETCH_DB_ACCESS_INFO, handleFetchDBAccessInfo);
    yield takeEvery(OPEN_DBACCESS_OWNERS_EDITING_TAB, handleOpenDBAccessOwnersEditingTab);
    yield takeEvery(UPDATE_REPOSITORY_ACTION, saveRepository);
}
