import { TReducer } from '../../utils/types';
import {
    MATRIX_REQUEST_SUCCESS,
    MATRIX_SAVE_REQUEST_SUCCESS,
    CHANGE_MATRIX_PROPERTIES,
} from '../../actionsTypes/entities/matrix.actionTypes';
import { TNodeState } from './TNodeState';
import { MatrixNode } from '../../serverapi/api';
import { TMatrixState } from './matrix.reducer.types';
import { IMatrixNode } from '@/models/bpm/bpm-model-impl.types';
import { isNewMatrix } from '@/modules/Matrix/NewMatrix.utils';

const INITIAL_MATRIX_STATE: TMatrixState = new TNodeState();

export const matrixReducer: TReducer<TMatrixState> = (state = INITIAL_MATRIX_STATE, action) => {
    switch (action.type) {
        case MATRIX_SAVE_REQUEST_SUCCESS:
        case MATRIX_REQUEST_SUCCESS: {
            const {
                payload: { matrix },
            } = action;

            if (isNewMatrix(matrix as IMatrixNode)) {
                const existMatrix: MatrixNode | undefined = state.byNodeId.get(matrix.nodeId);
                if (existMatrix) {
                    return state.set(matrix.nodeId, { ...existMatrix, version: matrix.version });
                }
            }

            return state.set(matrix.nodeId, matrix);
        }

        case CHANGE_MATRIX_PROPERTIES: {
            const {
                payload: { matrix, nodeId },
            } = action;
            const existMatrix: MatrixNode | undefined = state.byNodeId.get(nodeId);
            if (existMatrix) {
                if (isNewMatrix(matrix as IMatrixNode) && existMatrix.version && matrix.version) {
                    const version = Math.max(existMatrix.version, matrix.version);

                    return state.set(nodeId, { ...existMatrix, ...matrix, version });
                }

                return state.set(nodeId, { ...existMatrix, ...matrix });
            }

            return state.set(nodeId, { ...matrix, nodeId });
        }

        default:
            return state;
    }
};
