import React, { useRef, useEffect, Dispatch } from 'react';
import { GroupDTO } from '../../../../serverapi/api';
import { Button, FormInstance } from 'antd';
import { IntlShape, useIntl } from 'react-intl';
import formMessages from '../../../../models/formDefault.messages';
import GroupMainDataComponent from '../../GroupMainData/components/GroupMainData.component';
import GroupUsersContainer from '../../GroupUsers/containers/GroupUsers.container';
import theme from '../../UserManagement/components/UserManagment.scss';
import admTheme from '../../style/AdminTools.scss';
import { convertStringToArray } from '../../../../utils/convertStringToArray';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { GroupsSelectors } from '@/selectors/groups.selectors';
import { TWorkspaceTab } from '@/models/tab.types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGroupById, submitingGroupData } from '@/actions/groups.actions';
import { workspaceRemoveTabRequest } from '@/actions/tabs.actions';

export const GroupDataEditingTab: React.FC = () => {
    const intl: IntlShape = useIntl();
    const dispatch: Dispatch<any>= useDispatch();
    const formRef: React.RefObject<FormInstance<any>> = useRef<FormInstance>(null);
    const tab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);
    const groupData: GroupDTO = useSelector(GroupsSelectors.getEditing(tab?.nodeId.id || ''));
    
    const onSubmit = (groupData: GroupDTO, isNewGroup: boolean): void => {
        dispatch(submitingGroupData({ groupData, isNewGroup }));
    };

    const onClose = (tab: TWorkspaceTab): void => {
        dispatch(workspaceRemoveTabRequest(tab));
    };

    const fetchGroupFullData = (groupId: number): void => {
        dispatch(fetchGroupById(groupId));
    };

    useEffect(() => {
        if (groupData.id) {
            fetchGroupFullData(groupData.id);
        }
    }, [groupData.id]);

    const validateFields = async (): Promise<void | GroupDTO | undefined> => {
        const form = formRef.current;
    
        if (!form) {
            return Promise.resolve(undefined);
        }
    
        return form.validateFields().then((formValues) => {
            return {
                ...groupData,
                groupName: formValues.groupName.trim(),
                description: formValues.description?.trim(),
                synonyms: convertStringToArray(formValues.synonyms || ''),
            };
        }).catch((error) => console.error('error', error));
    };
    
    const handleSubmit = async () => {
        const updatedGroupData = await validateFields();
        const isNewGroup = !groupData || !groupData.id;

        if (updatedGroupData) {
            onSubmit(updatedGroupData, isNewGroup);
        }
    };

    return (
        <div className={theme.container}>
            <div className={admTheme.containerInner} style={{ height: 'calc(100% - 45px' }}>
                <div className={theme.rightBorder}>
                    <div className={theme.groupDataContainer}>
                        <GroupMainDataComponent
                            groupData={groupData}
                            formRef={formRef}
                        />
                    </div>
                </div>
                <div className={admTheme.m10}>
                    <GroupUsersContainer groupId={`${groupData.id}`} />
                </div>
            </div>
            <div className={admTheme.admEditingTabFooter}>
                <Button key="cancel" size="large" onClick={() => {
                    if (tab) {
                        onClose(tab);
                    }
                }}>
                    {intl.formatMessage(formMessages.cancelButton)}
                </Button>
                <Button
                    data-test="user-managment-group_save-adding-user-to-group"
                    key="ok"
                    size="large"
                    type="primary"
                    onClick={handleSubmit}
                >
                    {intl.formatMessage(formMessages.saveButtonLabel)}
                </Button>
            </div>
        </div>
    );
};
