import React, { FC } from 'react';
import { NavigatorPanel } from '../NavigatorPanel/NavigatorPanel.component';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../messages/Navigator.messages';
import theme from './NavigatorStructure.scss';
import navigatorPanelTheme from '../NavigatorPanel/NavigatorPanel.scss';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import iconTreeAddServer from '../../../../resources/icons/ic-tree-add-server.svg';
import iconAdd from '../../../../resources/icons/ic-add.svg';
import { Button } from 'antd';
import { NAVIGATOR_STRUCTURE } from '../../../../utils/consts';
import { Tree } from '../../../Tree/components/Tree/Tree.component';
import { TreeFiltersDropdown } from './TreeFiltersDropdown/TreeFiltersDropdown.component';
import { TNavigatorTab } from '../../../../reducers/navigator.reducer.types';
import { TreeSearch } from './TreeSearch/treeSearch.component';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { TreeNode } from '@/models/tree.types';
import { isEqual } from 'lodash-es';

export const NavigatorStructure: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const isEmptyTree: boolean = useSelector(TreeSelectors.isEmptyTree);
    const data: TreeNode[] = useSelector(TreeSelectors.treeNodesWithoutHidden, (l, r) => isEqual(l, r));

    const onServerAdd = () => dispatch(openDialog(DialogType.SERVER_EDIT));

    const renderAsideControls = () => {
        return <Icon onClick={onServerAdd} className={theme.serverAdd} spriteSymbol={iconTreeAddServer} />;
    };

    return (
        <NavigatorPanel
            type={TNavigatorTab.Tree}
            className={navigatorPanelTheme.container_tree}
            aside={!isEmptyTree ? undefined : renderAsideControls()}
            titleProps={{
                title: intl.formatMessage(messages.structure),
            }}
            customButtons={
                <>
                    <TreeSearch />
                    <TreeFiltersDropdown />
                </>
            }
        >
            <Tree data={data} isDndEnabled treeName={NAVIGATOR_STRUCTURE} />
            {isEmptyTree && (
                <Button className={theme.serverAddButton} type="primary" size="large" onClick={onServerAdd}>
                    <div className={theme.serverAddButtonInner}>
                        <Icon className={theme.serverAddButtonIcon} spriteSymbol={iconAdd} />
                        <FormattedMessage {...messages.serverAdd} />
                    </div>
                </Button>
            )}
        </NavigatorPanel>
    );
};
