import { isToday, isYesterday, format } from 'date-fns';
import DateTimeFormatOptions = Intl.DateTimeFormatOptions;
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(isBetween);

const options: DateTimeFormatOptions = { day: 'numeric', month: 'long', hour: '2-digit', minute: '2-digit' };

export const dateFormat = 'DD.MM.YYYY';

export const timeFormat = 'HH:mm:ss';

export const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export const dateTimeNoSecondsFormat = `${dateFormat} HH:mm`;

export function formatDate(todayPref: string, yesterdayPref: string, locale?: string, date?: Date): string {
    if (!date) {
        return '';
    }

    if (isToday(date)) {
        return `${todayPref} ${format(date, timeFormat)}`;
    }
    if (isYesterday(date)) {
        return `${yesterdayPref} ${format(date, 'HH:mm')}`;
    }

    return date.toLocaleString(locale, options);
}

export const timestampToMomentDate = (timestamp: number, isUtc?: boolean): dayjs.Dayjs | undefined => {
    if (typeof timestamp !== 'number') return undefined;
    const date = isUtc ? dayjs(timestamp, { utc: true }) : dayjs(timestamp);

    return date.isValid() ? date : undefined; // datePicker в antd требует на входе dayjs.Dayjs | undefined
};

export const timestampToStringDate = (timestamp: number, formatType: string, isUtc?: boolean): string => {
    if (typeof timestamp !== 'number') return '';
    const date = dayjs(timestamp, { utc: isUtc });

    return date.isValid() ? date.format(formatType) : '';
};

export const momentDateToTimestamp = (date: dayjs.Dayjs, isUtc?: boolean): number | null => {
    if (!date) return null;
    const utcOffset = +!!isUtc * dayjs(date).utcOffset() * 60 * 1000;
    return date.isValid() ? +dayjs(date).format('x') + utcOffset : null;
};

export const getDateUnits = (timestamp: string, units: string): dayjs.Dayjs => {
    const time = dayjs(Number(timestamp) / 1000);

    return dayjs(time, units);
};

/**
 * Сравнение двух значений времени, заданных через timestamp.
 * `True` если t1 > t2, иначе `False`
 */
export const isDateAfter = (timestamp1: string, timestamp2: string): boolean => {
    const time1 = getDateUnits(timestamp1, dateFormat);
    const time2 = getDateUnits(timestamp2, dateFormat);

    return time1.isAfter(time2);
};

/**
 * Сравнение двух значений времени, заданных через timestamp.
 * `True` если t1 < t2, иначе `False`
 */
export const isDateBefore = (timestamp1: string, timestamp2: string): boolean => {
    const time1 = getDateUnits(timestamp1, dateFormat);
    const time2 = getDateUnits(timestamp2, dateFormat);

    return time1.isBefore(time2);
};

/**
 * Сравнение двух значений времени, заданных через timestamp.
 * `True` если t1 > t2, иначе `False`
 */
export const isTimeAfter = (timestamp1: string, timestamp2: string): boolean => {
    const time1 = getDateUnits(timestamp1, timeFormat);
    const time2 = getDateUnits(timestamp2, timeFormat);

    return time1.isAfter(time2);
};

/**
 * Сравнение двух значений времени, заданных через timestamp.
 * `True` если t1 < t2, иначе `False`
 */
export const isTimeBefore = (timestamp1: string, timestamp2: string): boolean => {
    const time1 = getDateUnits(timestamp1, timeFormat);
    const time2 = getDateUnits(timestamp2, timeFormat);

    return time1.isBefore(time2);
};

export const isDateBetween = (timestamp: string, timestamp1: string, timestamp2: string): boolean => {
    const date = getDateUnits(timestamp, dateFormat);
    const startDate = getDateUnits(timestamp1, dateFormat);
    const endDate = getDateUnits(timestamp2, dateFormat);

    return date.isBetween(startDate, endDate, undefined, '[]');
};

export const isDatetimeBetween = (timestamp: string, timestamp1: string, timestamp2: string): boolean => {
    const datetime = getDateUnits(timestamp, dateTimeFormat);
    const startDatetime = getDateUnits(timestamp1, dateTimeFormat);
    const endDatetime = getDateUnits(timestamp2, dateTimeFormat);

    return datetime.isBetween(startDatetime, endDatetime, undefined, '[]');
};

export const dateToStringFormat = (date: Date, format: string): string => {
    return dayjs(new Date(date)).format(format);
};
